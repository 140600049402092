import React, { useState } from "react";



function SurveyInput({ state,setter,isActive,numOfInputs = 5 }) {
    function handleChange(e) {
        e.preventDefault();
        setter({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    //remove input
    function removeInput(e) {
        e.preventDefault();
        const newSurvey = { ...state };
        delete newSurvey[e.target.name];
        setter(newSurvey);
    
    }

    return (
        <div className="dynamic-input">
          
            {!isActive && <div className="disabled"></div>}
            
            {Object.keys(state).map((key, index) => {
                return (
                    <div key={index} style={{display: 'flex',position: 'relative'}}>
                        <input type="text" name={key} value={state[key]} onChange={handleChange} placeholder={`Question ${index + 1}`} style={inputStyle} />
                        {(index === Object.keys(state).length - 1 && index !== 0) && ( <button name={key} className="remove-survey" onClick={removeInput}>X</button> )}
                    </div>
                );
            })}

              {Object.keys(state).length < numOfInputs && (
                <div
                    className="survey-create-btn"
                    onClick={() => {
                        setter({ ...state, [`survey_input${Object.keys(state).length + 1}`]: "" });
                    }}
                >
                    Create Question
                </div>
            )}
        </div>
    );
}


const inputStyle = {
    position: 'relative',
    flexGrow: '1',
    alignSelf: 'center',
    marginLeft: '25px',
    marginBottom: '25px'
};

export default SurveyInput;
