import React from "react";
import { storage, db } from "../../firebase";

const DeleteUsers = props => {

  const deleteUsers = () =>{
    const imageIds = [];
    const usersToDelete = props.users[1].filter((user) => user.isChecked);
    const batch = db.batch();
    usersToDelete.map(user => {
      batch.delete(props.api.doc(user.id))
      if(user.entity_pic){
        imageIds.push(user.entity_pic.split('%2F')[1].split('?')[0]);
      }
    });
    batch.commit().then(() => {
        Promise.all(imageIds.map((image) => {
          storage.ref().child(props.company + '/' + image).delete()
        })).then(() => props.changeIsDeleteUserPopup(false)).catch(e => console.log(e));
    }).catch((error) => console.log(error))
  }

    return(
      <div className="group-popup-env">
          <div className="group-popup" onClick={() => props.changeIsDeleteUserPopup(false)}></div>
          <div className="popup delete">
            <div className="header-large">Delete Entities</div>
            <div className="text">Are you sure you want to delete these entities?</div>
            <div className="buttons-row">
            <button className="submit-new-doc" onClick={() => {deleteUsers()}}>Save</button> 
              <button className="abort" onClick={() => {props.changeIsDeleteUserPopup(props.user , false)}}>Discard</button> 
            
            </div>
          </div>
      </div>
    )
  }
export default DeleteUsers;