import React from "react";
import UserButtons from "./UserButtons";

const UserList = props =>{

    const singleUser = (user) => {
      return (
        <div className="user" key={user.id + 'userList'}>
          <label className="user-checkbox" style={user.isChecked ? {display:'block'}: {}}>
            <input type="checkbox" 
              id={user.htmlId} 
              name={user.htmlId}
              checked={user.isChecked}
              onChange={props.handleChecked}
            ></input>   
            <span className="checkmark"></span>                   
          </label>
          <div className="user-image" style={user.isChecked ? {display:'none'}: {}}>
            {
              user.entity_pic ? <img src={user.entity_pic} alt="" style={{width:'38px', height:'38px', display:"block", borderRadius:'50%'}}/> : ''
            }
          </div>  
          <div className="user-name" onClick={() => props.changeIsEditUser(user, true)}>{user.entity_firstname + ' ' + user.entity_lastname}</div>
        <UserButtons user={user} 
            changeIsEditUser={props.changeIsEditUser}
            changeIsDeleteUser={props.changeIsDeleteUser}
            changeIsRemoveUser={props.changeIsRemoveUser}
            groupInView={props.groupInView}
            />
      </div>
      )
    }

    return props.users ? props.users[1] !== undefined ? (
        <div className="users-list">
          <div className="users-list-top">
            <div>
              <div className="search-user">
                <input key="searchUser" 
                      value={props.searchKey} 
                      placeholder={'Search by name'}
                      onChange={(e) => props.changeSearchKey(e.target.value)}
                      ></input>
              </div>
              <div className="check-user"></div>
            </div>
            <div className="select-all"> 
                <label className="user-checkbox">
                  <input type="checkbox" 
                      name={'checkAll'}
                      checked={props.users[0].allChecked}
                      onChange={props.handleChecked}
                      disabled={props.users[1].length < 1}
                  ></input> 
                  <div className={props.users[1].length < 1 ? 'disabled' : ''}>Select all</div>
                  <span className="checkmark"></span>
                </label>
                {/* <div>Select all</div> */}
            </div>  
          </div>
          <div className="users-list-content">
            {props.users[1].map(user => (
              user.entity_firstname ? singleUser(user) : ''
            ))}
            {
              props.groupInView.entity_name !== 'Entities' && props.users[1].length < 1 && <div className="no-entity">No entity has been assigned to this group</div>
            ||
              props.groupInView.entity_name === 'Entities' && props.users[1].length < 1 && <div className="no-entity">No entities has been created</div>
            }
          </div>
    </div>
  ) : (
    <div className="users-list">Loading...</div>
  ) : (
    <div className="users-list">Loading...</div>
  );
}
export default UserList;