import React, { useState , useEffect , useCallback} from 'react';
import { storage, db } from "../../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';
import UploadFile from './UploadFile';
import AudioText from './AudioText';
import Select from 'react-select'
import MultiSelect from 'react-multi-select-component';
import Input from '../utilities/Input';
import InputToggle from './InputToggle';
import SurveyInput from './SurveyInput';




const RestSettings = ({company, changeGroupInView, locations, robotsApi, robots, groups, users, entitiesApi}) => {
    // console.log(users,'users')
    // console.log(groups,'groups')
    const robot = useStoreState(store => store.robot);
    const setIsSettings = useStoreActions(actions => actions.setIsSettings);
    const [Saved, setSaved] = useState('');

    const [language, setLanguage] = useState({});
    const [langId, setLangId] = useState('')
    const [isSaveSettings, setIsSaveSettings] = useState(false); 
    const [mediaObj, setMediaObj] = useState(undefined);
    const [settingsObj, setSettingsObj] = useState(undefined);
    const [viewMenuCategory, setViewMenuCategory] = useState('general');
    //state for each field
    const [openingAudioText, setOpeningAudioText] = useState('')
    const [meetingArrivedAudioText, setMeetingArrivedAudioText] = useState('')
    const [deliveryOpeningHeadline, setDeliveryOpeningHeadline] = useState(''); //maybe remove

    const uploadingFiles = useStoreState(store => store.uploadingFiles)
    const languages = useStoreState(store => store.languages)
 
    const [toggleOpeningVisual, setToggleOpeningVisual] = useState(false);
    const [toggleOpeningAudio, setToggleOpeningAudio] = useState(false);
    const [toggleConstraint, setToggleConstraint] = useState(false);
    const [toggleCustomBackground, setToggleCustomBackground] = useState(false);

    const [toggleMeeting, setToggleMeeting] = useState(false);
    const [toggleSmsBool, setToggleSmsBool] = useState(false); //maybe remove

    const [toggleDelivery, setToggleDelivery] = useState(false); 
    
    // state for each link
    const [newLinkOpeningVisual, setNewLinkOpeningVisual] = useState(undefined); 
    const [openingVisualFileType, setOpeningVisualFileType] = useState('') 
    const [openingVisualFileName, setOpeningVisualFileName] = useState('') 

    
    const [newLinkBackgroundImage, setNewLinkBackgroundImage] = useState(undefined);
    const [backgroundFileType, setBackgroundFileType] = useState('')
    const [backgroundFileName, setBackgroundFileName] = useState('')

    const [newLinkLogoImage, setNewLinkLogoImage] = useState(undefined);
    const [logoImageFileType, setLogoImageFileType] = useState('')
    const [logoImageFileName, setLogoImageFileName] = useState('')
    
    const [newDeliveryManagerPhone, setNewDeliveryManagerPhone] = useState(undefined);

    const [deliveryOneHeirarchyUsersFromDb] = useState(users.filter(user => { //mabye remove
        if(user.delivery_hierarchy){
            return user.delivery_hierarchy.includes(robot)
        }        
    }))
    const [deliverySelectedHierarchies, setDeliverySelectedHierarchies] = useState('') //maybe remove

    const [robotGroups, setRobotGroups] = useState([])

    const [deliverySelectedRobotGroups, setDeliverySelectedRobotGroups] = useState([]) //maybe remove
    const [deliveryFirstHierarchyTitle, setDeliveryFirstHierarchyTitle] = useState('') //remove
    const [deliverySecondHierarchyTitle, setDeliverySecondHierarchyTitle] = useState('') //remove
    
    const [meetingOneHeirarchyUsersFromDb] = useState(users.filter(user => {
        if(user.meeting_hierarchy){
            return user.meeting_hierarchy.includes(robot)
        }        
    }))
    const [meetingSelectedRobotGroups, setMeetingSelectedRobotGroups] = useState([]) //maybe remove
    const [meetingSelectedHierarchies, setMeetingSelectedHierarchies] = useState('') //maybe remove
    const [meetingFirstHierarchyTitle, setMeetingFirstHierarchyTitle] = useState('') // maybe remove
    const [meetingSecondHierarchyTitle, setMeetingSecondHierarchyTitle] = useState('') // maybe remove

    const [themeMode, setThemeMode] = useState('dark')
    const [meetingDirectionMode, setMeetingDirectionMode] = useState('straight')

    //locations
    const [entrance, setEntrance] = useState('');



    //schedule
    const [toggleSchedule, setToggleSchedule] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');


    //texts
    const [toggleTexts, setToggleTexts] = useState(false)
    const [approvalButton, setApprovalButton] = useState('')
    const [denyButton, setDenyButton] = useState('')
    const [goingBack, setGoingBack] = useState('')
    const [searchText, setSearchText] = useState('') // maybe remove
    const [searchPageHeadline, setSearchPageHeadline] = useState('') //maybe remove
    //robots
    

    // const mediaApi = robotsApi.doc(robot).collection('Media');
    const restApi = robotsApi.doc(robot).collection('Rest');
    // const settingsApi = robotsApi.doc(robot).collection('Settings');
    const randomNum = () => {
        return Math.floor(Math.random() * (1000000 - 1 + 1) ) + 1
    }


    const [typeTtsText, setTypeTtsText] = useState('')
    //Rest settings type tab state
    const timeOptions =[
        {id:'30 seconds', value:'30 seconds'},
        {id:'60 seconds', value:'60 seconds'},
        {id:'90 seconds', value:'90 seconds'},
    ]
    const [typeWaitingTime, setTypeWaitingTime] = useState(timeOptions[0].value);   

    const [typeEscScreenRadioValue, setTypeEscScreenRadioValue] = useState('Table');
    const [typeAssignedSaleValue, setTypeAssignedSaleValue] = useState('');
    const [typeAssingedSpecialsValue, setTypeAssingedSpecialsValue] = useState('');

    const smileFaceOptions = [
        {id:'Smile1', value:':)'},
        {id:'Smile2', value:':-)'},
        {id:'Smile3', value:':D'},
    ]
    const [typeBackScreenRadioValue, setTypeBackScreenRadioValue] = useState('backText');
    const [typeBackScreenText, setTypeBackScreenText] = useState('');
    const [typeBackScreenFace, setTypeBackScreenFace] = useState(smileFaceOptions[0].value);


    const [typeNewLinkLogoImage, setTypeNewLinkLogoImage] = useState(undefined);
    const [typeLogoImageFileType, setTypeLogoImageFileType] = useState('')
    const [typeLogoImageFileName, setTypeLogoImageFileName] = useState('')

 

    const [typesDirectionMode, setTypesDirectionMode] = useState('straight');
 

    const [typeShowSongToggle, setTypeShowSongToggle] = useState(false);

    const [typeArrivalScMainToggle, setTypeArrivalScMainToggle] = useState(false);
    const [typeArrivalScMainTitle, setTypeArrivalScMainTitle] = useState('');
    const [typeArrivalScMainSubtitle, setTypeArrivalScMainSubtitle] = useState('');

    const [typeArrivalScOnSaleToggle, setTypeArrivalScOnSaleToggle] = useState(false);
    const [typeArrivalScOnSaleMenuTitle, setTypeArrivalScOnSaleMenuTitle] = useState('');
    const [typeArrivalOnSalePageTitle, setTypeArrivalOnSalePageTitle] = useState('');


    const [typeArrivalScSpecialsToggle, setTypeArrivalScSpecialsToggle] = useState(false);
    const [typeArrivalSpecialsMenuTitle, setTypeArrivalSpecialsMenuTitle] = useState('');
    const [typeArrivalSpecialsPageTitle, setTypeArrivalSpecialsPageTitle] = useState('');

    const [typeSurveyToggle, setTypeSurveyToggle] = useState(false);
    const [typeSurveyMenuTitle, setTypeSurveyMenuTitle] = useState('');
    const [typeSurveyQuestions, setTypeSurveyQuestions] =useState({survey_input1:""});


    const [typeArrivalScJoinGroupToggle, setTypeArrivalScJoinGroupToggle] = useState(false);
    const [typeArrivalScJoinGroupMenuTitle, setTypeArrivalScJoinGroupMenuTitle] = useState('');
    const [typeArrivalScJoinGroupPageTitle, setTypeArrivalScJoinGroupPageTitle] = useState('');
    const [typeArrivalScJoinGroupSubtitle, setTypeArrivalScJoinGroupSubtitle] = useState('');
    const [typeArrialScJoinGroupSmsTxt, setTypeArrialScJoinGroupSmsTxt] = useState('');


    


    function getManagementUsers(groupFilter) {
        var managementUsers = [];
        for (var i = 0; i < users.length; i++) {
            if (users[i].entity_group.includes(groupFilter)) {
                managementUsers.push(users[i]);
            }
        }
        return managementUsers;
    }











    function getDefaultLanguage(){
        for(let i=0; i<languages.length;i++){
            if(langId === languages[i].id){
                return languages[i]
            }                                                
        }
    }

    useEffect(() => {
        restApi.get().then((collection => {
            // console.log(collection,'media collection')
            const temp = {};
            collection.docs.map(doc => {
                temp[doc.id] = doc.data();
            })
            // console.log(temp,'temp')
            setMediaObj(temp);
            setToggleOpeningVisual(temp.isOpeningVisual.bool || false); //set toggle
            setOpeningVisualFileName(temp.isOpeningVisual.fileName || '')
            setToggleOpeningAudio(temp.isOpeningAudio.bool || false);
            setOpeningAudioText(temp.isOpeningAudio.text || '')

            // setToggleMeeting(temp.isMeeting.bool || false);
            setMeetingArrivedAudioText(temp.arrivedSettings.arrivedText || '')
            setMeetingDirectionMode(temp.arrivedSettings.directionMode || 'straight')

            setNewLinkBackgroundImage(temp.bg.link || '');
            setBackgroundFileType(temp.bg.fileType || '')
            setBackgroundFileName(temp.bg.fileName || '')
            setNewLinkLogoImage(temp.logo.link || '');
            setLogoImageFileName(temp.logo.fileName || '');
            setThemeMode(temp.mode.type || '');
            setToggleCustomBackground(temp.bg.boolCustom || false);

            // setDeliveryOpeningHeadline(temp.isDelivery.openingHeadline || '');
            // setToggleDelivery(temp.isDelivery.bool || false);
        }));

        function validateField(obj, propName, innerPropName){
            if(obj){
                if(obj[propName]){
                    if(obj[propName][innerPropName]){
                        return obj[propName][innerPropName]
                    }else{
                        //todo create doc
                        if(innerPropName.includes('bool')){
                            return false
                        }
                        return ''
                    }
                    
                }else{
                    //todo create doc
                    if(innerPropName.includes('bool')){
                        return false
                    }
                    return ''
                }
            }else{
                //todo create doc
                if(innerPropName.includes('bool')){
                    return false
                }
                return ''
            }
            
            return
        }
        restApi.get().then((collection => {   
            const tempObj = {};
            collection.docs.map(doc => {
                tempObj[doc.id] = doc.data();
            })
            // console.log(tempObj,'tempObj')         

            setSettingsObj(tempObj);       
            setToggleConstraint(tempObj.constraint.bool || false);
            setEntrance(tempObj.entrance.location  || '');
            setToggleSchedule(tempObj.isAppSchedule.bool || false);
            setStartTime(tempObj.isAppSchedule.startTime || '');
            setEndTime(tempObj.isAppSchedule.endTime || '');
            setToggleTexts(validateField(tempObj,'isTests', 'bool'))
            setApprovalButton(tempObj.isTexts.approvalButton || '')
            setDenyButton(tempObj.isTexts.denyButton || '')
            setGoingBack(tempObj.isTexts.goingBackMessage || '')
            
            const groupIdsArr = groups.map(g => g.id)
            setRobotGroups(groups.filter(group => group.robots.includes(robot)))


            //type section
            setTypeTtsText(tempObj.types_tts.text || '');
            setTypeWaitingTime(tempObj.types_waitTime.time || timeOptions[0].value);
            setTypesDirectionMode(tempObj.types_direction.type || 'straight');
            setTypeShowSongToggle(tempObj.types_showSongs.bool || false);

            setTypeArrivalScMainToggle(tempObj.types_splitScreenMain.bool || false);
            setTypeArrivalScMainTitle(tempObj.types_splitScreenMain.title || '');
            setTypeArrivalScMainSubtitle(tempObj.types_splitScreenMain.subtitle || '');

            setTypeArrivalScOnSaleToggle(tempObj.types_splitScreenSale.bool || false);
            setTypeArrivalScOnSaleMenuTitle(tempObj.types_splitScreenSale.menu || '');
            setTypeArrivalOnSalePageTitle(tempObj.types_splitScreenSale.page || '');

            setTypeArrivalScSpecialsToggle(tempObj.types_splitScreenSpecials.bool || false);
            setTypeArrivalSpecialsMenuTitle(tempObj.types_splitScreenSpecials.menu || '');
            setTypeArrivalSpecialsPageTitle(tempObj.types_splitScreenSpecials.page || '');

            setTypeSurveyToggle(tempObj.types_splitScreenSurvey.bool || false);
            setTypeSurveyMenuTitle(tempObj.types_splitScreenSurvey.menu || '');
            setTypeSurveyQuestions(tempObj.types_splitScreenSurvey.questions || {survey_input1:""}); //need to check


            setTypeArrivalScJoinGroupToggle(tempObj.types_splitScreenClub.bool || false);
            setTypeArrivalScJoinGroupMenuTitle(tempObj.types_splitScreenClub.menu || '');
            setTypeArrivalScJoinGroupPageTitle(tempObj.types_splitScreenClub.page || '');
            setTypeArrivalScJoinGroupSubtitle(tempObj.types_splitScreenClub.pageSubtitle || '');
            setTypeArrialScJoinGroupSmsTxt(tempObj.types_splitScreenClub.sms || '');

            // types_splitScreenClub





      
        }));
    }, [robot])
















    const handleSubmit = (e) => {
        e.preventDefault();
        // if((!toggleDelivery && !toggleMeeting )){ // || uploadingFiles
        //     console.log(toggleMeeting, toggleDelivery)
        //     return;
        // }
        const oldFiles = []; // iterate over this to delete old files
        const batch = db.batch();
///////////////////////////////////////General Settings///////////////////////////////////////
        const tempOpeningVisual = {bool:toggleOpeningVisual};
        if(newLinkOpeningVisual){
            tempOpeningVisual.link = newLinkOpeningVisual;
            tempOpeningVisual.fileType = openingVisualFileType
            tempOpeningVisual.fileName = openingVisualFileName
            if(mediaObj.isOpeningVisual.link){
                oldFiles.push(mediaObj.isOpeningVisual.link.split('Media%2F')[1].split('?')[0]);
            }
        }
        batch.update(restApi.doc('isOpeningVisual'), tempOpeningVisual);
        const tempOpeningAudio = {
            bool:toggleOpeningAudio,
            text:openingAudioText
        }

        batch.update(restApi.doc('isOpeningAudio'), tempOpeningAudio);
        batch.update(restApi.doc('bg'), {link: newLinkBackgroundImage, boolCustom: toggleCustomBackground, fileType:backgroundFileType, fileName:backgroundFileName});
        // batch.update(mediaApi.doc('bg'), {boolCustom: toggleCustomBackground});
        batch.update(restApi.doc('logo'), {link: newLinkLogoImage, fileType:logoImageFileType, fileName:logoImageFileName});
        batch.update(restApi.doc('mode'), {type: themeMode});


        batch.update(restApi.doc('constraint'), {bool:toggleConstraint});
        const tempLanguageObj = {}
        for(let i=0; i<languages.length; i++){
            // debugger
            if(language.value === languages[i].id){
                tempLanguageObj.description = languages[i].description
                tempLanguageObj.orientation = languages[i].orientation
                tempLanguageObj.type = languages[i].id             
                break
            }
        }
        batch.update(restApi.doc('language'), tempLanguageObj);
        batch.update(restApi.doc('entrance'), {location:entrance});
        batch.update(restApi.doc('isTexts'),{bool:toggleTexts,approvalButton:approvalButton,denyButton:denyButton,goingBackMessage:goingBack})
        batch.update(restApi.doc('isAppSchedule'), {bool:toggleSchedule,startTime:startTime,endTime:endTime});

  //////////////////////////////////Main Flow//////////////////////////////////////////  
         batch.update(restApi.doc('arrivedSettings'), {
        //     bool:toggleMeeting, 
             arrivedText:meetingArrivedAudioText,
             directionMode: meetingDirectionMode,
         });
   


        if(!toggleCustomBackground){
            console.log(themeMode)
            if(themeMode === 'light'){
                setNewLinkBackgroundImage("https://firebasestorage.googleapis.com/v0/b/toyota-cd2b6.appspot.com/o/Oval%20%2B%20Oval%20%2B%20Oval%20Mask%20%2B%20Rectangle%20Mask.png?alt=media&token=d54ca999-70fa-40f1-a4b0-0b6463684291")
            }
            else{
                setNewLinkBackgroundImage("https://firebasestorage.googleapis.com/v0/b/toyota-cd2b6.appspot.com/o/dark%202%20m.jpg?alt=media&token=215c68e9-7676-4bb4-bddf-8f7f4e60935e")
            }
        }

        if(!toggleTexts){
            batch.update(restApi.doc('isTexts'),{bool:toggleTexts,approvalButton:"OK",denyButton:"Cancel",goingBackMessage:"In my way back to entrance",searchPageHeadline:"Search Results",searchText:"Search by name"})
            setApprovalButton("OK")
            setDenyButton("Cancel")
            setGoingBack("In my way back to entrance")
            // setSearchPageHeadline("Search Results")
            // setSearchText("Search by name")
        }


        //type section 
        batch.update(restApi.doc('types_tts'), {text:typeTtsText});
        batch.update(restApi.doc('types_waitTime'), {time:typeWaitingTime});
        batch.update(restApi.doc('types_direction'), {type:typesDirectionMode});
        batch.update(restApi.doc('types_showSongs'), {bool:typeShowSongToggle});
        batch.update(restApi.doc('types_splitScreenMain'), {bool:typeArrivalScMainToggle});
        if(typeArrivalScMainToggle){
            batch.update(restApi.doc('types_splitScreenMain'), {title:typeArrivalScMainTitle,subtitle:typeArrivalScMainSubtitle})

            
            batch.update(restApi.doc('types_splitScreenSale'), {bool:typeArrivalScOnSaleToggle,menu:typeArrivalScOnSaleMenuTitle,page:typeArrivalOnSalePageTitle})
            
            batch.update(restApi.doc('types_splitScreenSpecials'), {bool:typeArrivalScSpecialsToggle,menu:typeArrivalSpecialsMenuTitle,page:typeArrivalSpecialsPageTitle})
           
            batch.update(restApi.doc('types_splitScreenSurvey'), {bool:typeSurveyToggle,menu:typeSurveyMenuTitle,questions:typeSurveyQuestions})

            batch.update(restApi.doc('types_splitScreenClub'), {bool:typeArrivalScJoinGroupToggle,menu:typeArrivalScJoinGroupMenuTitle,page:typeArrivalScJoinGroupPageTitle,pageSubtitle:typeArrivalScJoinGroupSubtitle,sms:typeArrialScJoinGroupSmsTxt})
            

           
        }




        batch.commit().then(() => {    
            // delete old files  
            console.log(oldFiles)  
            Promise.all(oldFiles.map((file) =>{
                storage.ref().child(company + '/Media/' + file).delete();
            })).then(() => {
                {
                    setSaved("Send to robot successfully");
                    setTimeout(function(){
                      setSaved('');
                    }, 1500)
                  }
                setIsSaveSettings(true);
            }).catch((error) => console.log(error));           
        }).catch((error) => console.log(error))
        
    }
















 

    const handleChangeMeetingSelectedHierarchies = (event) => {
        console.log(event.target.value, event.target.checked)
        setMeetingSelectedHierarchies(event.target.value)
      }

      const handleChangeThemeMode = (event) => {
        console.log(event.target.value, event.target.checked)
        setThemeMode(event.target.value)
      }

    const handleAbort = () => {
        const files = [newLinkOpeningVisual];
        const filesToDelete = [];
        files.filter(file => {
            if(file){
                filesToDelete.push(file.split('Media%2F')[1].split('?')[0]);
            }
        })
        // delete all temp files
        console.log(filesToDelete)
        Promise.all(filesToDelete.map((file) => {
            storage.ref().child(company + '/Media/' + file).delete();
        })).then(() => {
            changeGroupInView({entity_name:'Entities'});
            setIsSettings(false);
        }).catch((error) => console.log(error));
  
    }

    const formatPhone = val => {
        if(val[0] === '0'){
            setNewDeliveryManagerPhone(val.substring(1));
        }else{
            setNewDeliveryManagerPhone(val);
        }
   
    }

    return(
        <div className="dashboard" id="settings">
            <form style={{height:'100%'}} onSubmit={handleSubmit}>
                <div style={{margin:'auto', height:'calc(100% - 92px)', width:'74.4%'}}>
                    <div className="robot">{robot}</div>
                    <div className="settings-header">
                        <div className="header-row">
                            <div className={`header-item ${viewMenuCategory === 'general' && 'active'}`} onClick={() => setViewMenuCategory('general')}>General</div>
                            <div className={`header-item ${viewMenuCategory === 'types' && 'active'}`} onClick={() => setViewMenuCategory('types')}>Types</div>
                        </div>
                    </div>
                    <div className="settings-box">
                        {viewMenuCategory === 'general' &&
                            <div className="settings-box-scroll">
                                <div className="sms-env">
                                </div>
                                <UploadFile
                                    company={company}
                                    setLink={setNewLinkOpeningVisual}
                                    setFileType={setOpeningVisualFileType}
                                    setFileName={setOpeningVisualFileName}
                                    oldFileName={openingVisualFileName}
                                    name="isOpeningVisual"
                                    toggle={toggleOpeningVisual}
                                    setToggle={setToggleOpeningVisual}
                                    isToggle={true}
                                    title="Opening Visual"
                                    text="Choose mp4 / mov for video (will be muted) or png/jpg for pitcher 
                                    (recommended size 1280X800)"
                                />
                                <AudioText                                                                            
                                    name="isOpeningAudio"
                                    toggle={toggleOpeningAudio}
                                    setToggle={setToggleOpeningAudio}
                                    isToggle={true}
                                    title="TTS"
                                    subtitle="Welcome TTS"
                                    textState={openingAudioText}
                                    textSetter={setOpeningAudioText}
                                    placeholder='Welcome to our company'
                                />
                                <div className="settings-row">
                                    <label className="switch" htmlFor="constraint">
                                        <input type="checkbox" 
                                            name="constraint" 
                                            id="constraint"
                                            checked={toggleConstraint}
                                            onChange={e => setToggleConstraint(e.target.checked)}        
                                            ></input>
                                        <span className="slider"></span>
                                    </label>
                                    <div className="upload-row">
                                        <div className="header">User Tracking</div>
                                        <div className="small-text" style={{color:'#888888'}}>Enable/disable use tracking after detection</div>
                                    </div>
                                </div>
                                <div className="indent-row" style={{marginTop:'35px', justifyContent:'flex-start', flexDirection : 'column'}}>  
                                    <div className="header" style={{fontFamily: 'OpenSans-Bold',fontSize: '14px',color: 'rgb(82, 82, 82)',marginBottom: '10px'}}>Display</div>
    
                                </div>
                                
                                <div className="custom-backgrpund-file">
                                    <UploadFile
                                        company={company}
                                        setLink={setNewLinkBackgroundImage}
                                        setFileType={setBackgroundFileType}
                                        setFileName={setBackgroundFileName}
                                        oldFileName={backgroundFileName}
                                        name="backgroundImage"
                                        toggle={toggleCustomBackground}
                                        setToggle={setToggleCustomBackground}
                                        isToggle={true}
                                        title="Custom Background"
                                        text="Choose png / jpeg file (recommended size 1280X800)."
                                    />
                                </div>
                                {/* testing uploadfile */}
                                <div style={{paddingLeft: '62px'}}>
                                    <UploadFile
                                        company={company}
                                        setLink={setNewLinkLogoImage}
                                        setFileType={setLogoImageFileType}
                                        setFileName={setLogoImageFileName}
                                        oldFileName={logoImageFileName}
                                        name="logoImage"
                                        isToggle={false}
                                        title="Custom Logo"
                                        text="Choose png / jpeg file (recommended size 229X36)."
                                    />
                                </div>
                                
                                <div className="indent-row" style={{marginTop: '29px',flexDirection: 'row',justifyContent: 'inherit'}}>  
                                    <div className="header" style={{marginBottom: '8px',width: '120px',fontFamily: 'OpenSans',fontSize: '14px',color: '#4c4c4c',paddingTop: '5px'}}>Mode</div>
                                    <div className="radio" style={{
                                        display: 'flex',
                                        width: '106px',
                                        height: '34px',
                                        border: '1px solid #D0D9ED',
                                        borderRadius: '5px',
                                        position:'relative'
                                    }}>
                                        <div 
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                left:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setThemeMode('light')}
                                        >Light</div>
                                        <div
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                right:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setThemeMode('dark')}
                                        >Dark</div>
                                        <div                                             
                                        style={{
                                            position:'absolute',
                                            left: themeMode === 'light' ? 0 : '50%',
                                            top:0,
                                            width:'50%',
                                            height:'100%',
                                            transition:'left 250ms linear',
                                            willChange:'left',
                                            background:'#D0D9ED',
                                            borderRadius:'5px'                                                                                    
                                        }}></div>
                                    </div>
                                </div>

                                <div className="default-locations">

                                    <div className="header" style={{margin: '53px 0 15px 64px'}}>Default locations</div>
                                    <div className="location-row" style={{margin: '0 0 29px 64px'}}>  
                                        <label style={{marginBottom: '8px',width: '120px',fontFamily: 'OpenSans',fontSize: '14px',color: '#4c4c4c',paddingTop: '5px'}}>Entrance</label>
                                        <div className="select">
                                            <select name="entrance" value={entrance} onChange={e => setEntrance(e.target.value)}>
                                                <option value="" defaultValue disabled hidden></option>
                                                {                            
                                                    locations.map(l => (
                                                        <option 
                                                            key={l.id + 'l'} 
                                                            value={l.id} 
                                                            defaultValue={entrance === l.id}
                                                            >{l.id}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>  
                              
                                  
                                    
                                    <div className="app-schedule-box">
                                        <div className="group-input input-texts">
                                            <div className="title-row">
                                                <label className="switch" htmlFor="app-schedule">
                                                    <input type="checkbox" 
                                                        name="app-schedule" 
                                                        id="app-schedule"
                                                        checked={toggleSchedule}
                                                        onChange={e => setToggleSchedule(e.target.checked)}        
                                                        ></input>
                                                    <span className="slider"></span>
                                                </label>
                                                <div className="main-title" >App Schedule</div>
                                                <div className="main-subtitle">The robot will arrive at the entrance location and return to the home bace according to a schedule:</div>
                                                <div style={{margin: '18px 0'}}>
                                                    <label htmlFor="start-time">Starts at</label>
                                                    <input type="time" 
                                                        id="start-time" name="start-time" 
                                                        value={startTime}
                                                        onChange={e => setStartTime(e.target.value)}></input>
                                                </div> 
                                                <div style={{margin: '18px 0'}}>
                                                    <label htmlFor="end-time" >Ends at</label>
                                                    <input type="time" 
                                                        id="end-time" name="end-time" 
                                                        value={endTime}
                                                        onChange={e => setEndTime(e.target.value)}></input>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                  


                                    <div className="group-input input-texts bottom-page">
                                        <div className="title-row">
                                            <label className="switch" htmlFor="isTexts">
                                                <input type="checkbox" 
                                                    name="isTexts" 
                                                    id="isTexts"
                                                    checked={toggleTexts}
                                                    onChange={e => setToggleTexts(e.target.checked)}        
                                                    ></input>
                                                <span className="slider"></span>
                                            </label>
                                            <div className="main-title">Texts</div>
                                        </div>
                                        <Input
                                            setter={setApprovalButton}
                                            state={approvalButton}
                                            isActive={toggleTexts}
                                            title={'Aprroval button'}
                                            placeholder='OK / Yes / Approve'
                                        />
                                        <Input
                                            setter={setDenyButton}
                                            state={denyButton}
                                            isActive={toggleTexts}
                                            title={'Deny button'}
                                            placeholder='Cancel / No / Deny'
                                        />
                                        <Input
                                            setter={setGoingBack}
                                            state={goingBack}
                                            isActive={toggleTexts}
                                            title={'Going back message'}
                                            placeholder='Going back to entrance'
                                        />
                                       
                                    </div>             
                                </div>
                            </div>
                        }




                        {   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                            viewMenuCategory === 'types' &&
                            <div className="settings-box-scroll rest-set-types">
                                <div className="settings-row" style={{marginBottom:'29px'}}>
                                    <div>
                                        <div className="header">Tables Numbers</div>
                                        <div className="text" style={{color:'#888888'}}>Accompanies a visitor to a meeting</div>
                                    </div>
                                </div>
                                <div className="group-input input-texts">
                                    <Input
                                        title={'Arrived TTS'}
                                        isActive={true}
                                        setter={setTypeTtsText}
                                        state={typeTtsText}
                                        placeholder='Welcome to company name'
                                    />     
                                </div>                        

                                {/* <div className="header" style={{margin: '53px 0 15px 64px'}}>Default locations</div> */}
                                    <div className="wating-time-row" >  
                                        <label>Type Waiting time</label>
                                        <div className="select">
                                            <select name="type-waiting-time" value={typeWaitingTime} onChange={e => setTypeWaitingTime(e.target.value)}>
                                                <option value="" defaultValue disabled hidden></option>
                                                {
                                                    timeOptions.map((t,i) => (
                                                        <option
                                                            key={i + 't'}
                                                            value={t.value}
                                                            defaultValue={typeWaitingTime === t.value}
                                                            >{t.value}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div> 

                                    {/* need update to component */}
                                    <div className="group-btns-fields-flex">
                                        <div className='group-btns-title'>Escorting screen</div>
                                        <div className='esc-screen-group-left' >
                                            <div className="input-row">
                                                <input type="radio" value="Table" name="TableEscScreen" checked={typeEscScreenRadioValue === 'Table'}  onChange={e => setTypeEscScreenRadioValue(e.target.value)}  /> 
                                                <span className="radio"></span>
                                                <label>Table Number</label>
                                            </div>
                                            <div className="input-row">
                                                <input type="radio" value="Sale" name="TableEscScreen" checked={typeEscScreenRadioValue === 'Sale'}  onChange={e => setTypeEscScreenRadioValue(e.target.value)} /> 
                                                <span className="radio"></span>
                                                <label>On Sale</label>
                                            </div>
                                            <div className="input-row">
                                                <input type="radio" value="Specials" name="TableEscScreen" checked={typeEscScreenRadioValue === 'Specials'}  onChange={e => setTypeEscScreenRadioValue(e.target.value)} />
                                                <span className="radio"></span>
                                                <label>Specials</label>
                                            </div>
                                    
                                        </div>
                                        <div className='esc-screen-group-right' >
                                            <div style={{height:"34px",margin:"5px 0"}}></div>
                                            <div className="esc-screen-fields-row" >  
                                                <label style={{paddingRight: '35px'}}>Assigned Sale</label>
                                                <div className="select" disabled={typeEscScreenRadioValue !== 'Sale'}>
                                                    <select name="type-assigned-sale" value={typeAssignedSaleValue} onChange={e => setTypeAssignedSaleValue(e.target.value)}>
                                                        <option value="" defaultValue disabled hidden></option>
                                                        {
                                                        getManagementUsers("Songs").map((u,i) => (
                                                            <option
                                                                key={i + 'Song'}
                                                                value={u.entity_firstname}
                                                                defaultValue={typeAssignedSaleValue === u.entity_firstname}
                                                                >{u.entity_firstname}</option>
                                                        ))
                                                            }
                                                    </select>                   
                                                    <div className="rest-set-disabled"
                                                    style={{ display: typeEscScreenRadioValue !== 'Sale' ? 'block':'none',}}
                                                    ></div>  
                                                </div>
                                            </div> 

                                            <div className="esc-screen-fields-row" >
                                                <label>Assigned Specials</label>
                                                <div className="select" style={{width: '178px'}}>
                                                    <select name="type-assigned-specials" value={typeAssingedSpecialsValue} onChange={e => setTypeAssingedSpecialsValue(e.target.value)}>
                                                        <option value="" defaultValue disabled hidden></option>
                                                        {
                                                            getManagementUsers("Specials").map((u,i) => (
                                                                <option
                                                                    key={i + 'Special'}
                                                                    value={u.entity_firstname}
                                                                    defaultValue={typeAssingedSpecialsValue === u.entity_firstname}
                                                                    >{u.entity_firstname}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="rest-set-disabled"
                                                    style={{ display: typeEscScreenRadioValue !== 'Specials' ? 'block':'none',}}
                                                    ></div>  
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                   

                                    <div className="group-btns-fields-flex">
                                        <div className='group-btns-title'>Going back Screen</div>
                                        <div className='esc-screen-group-left' >
                                            <div className="input-row">
                                                <input type="radio" value="backText" name="backText" checked={typeBackScreenRadioValue === 'backText'}  onChange={e => setTypeBackScreenRadioValue(e.target.value)}  /> 
                                                <span className="radio"></span>            
                                                <label>Text</label>
                                            </div>
                                            <div className="input-row">
                                                <input type="radio" value="backFace" name="backFace" checked={typeBackScreenRadioValue === 'backFace'}  onChange={e => setTypeBackScreenRadioValue(e.target.value)} />
                                                <span className="radio"></span>
                                                <label>Smiley face</label>
                                            </div>
                                            <div className="input-row">
                                                <input type="radio" value="backFileUplad" name="backFileUplad" checked={typeBackScreenRadioValue === 'backFileUplad'}  onChange={e => setTypeBackScreenRadioValue(e.target.value)} />
                                                <span className="radio"></span>
                                                <label>Upload file</label>
                                            </div>
                                    
                                        </div>
                                        <div className='esc-screen-group-right' >
                                            
                                            <div className="esc-screen-fields-row" >
                                                <Input
                                                    setter={setTypeBackScreenText}
                                                    state={typeBackScreenText}
                                                    isActive={typeBackScreenRadioValue === 'backText'}
                                                    title={''}
                                                    placeholder='Tap on the item'
                                                />                                              
                                            </div>

                                            <div className="esc-screen-fields-row" style={{alignSelf: 'end'}} >  
                                                <div className="select" disabled={typeBackScreenRadioValue !== 'backFace'}>
                                                    <select name="type-assigned-sale" value={typeBackScreenFace} onChange={e => setTypeBackScreenFace(e.target.value)}>
                                                        <option value="" defaultValue disabled hidden></option>
                                                        {
                                                         smileFaceOptions.map((t,i) => (
                                                            <option
                                                                key={t.id + 't'}
                                                                value={t.value}
                                                                defaultValue={typeWaitingTime === t.value}
                                                                >{t.value}</option>
                                                        ))
                                                            }
                                                    </select>                   
                                                    <div className="rest-set-disabled"
                                                    style={{ display: typeBackScreenRadioValue !== 'backFace' ? 'block':'none',}}
                                                    ></div>  
                                                </div>
                                            </div> 

                                            <UploadFile
                                                company={company}
                                                 setLink={setTypeNewLinkLogoImage}
                                                setFileType={setTypeLogoImageFileType}
                                                setFileName={setTypeLogoImageFileName}
                                                oldFileName={typeLogoImageFileName}
                                                name="logoImage"
                                                isToggle={false}
                                                text="Choose a PNF or JPG file"
                                            />
                                          

                                        </div>
                                    </div>
                                    

                                <div className="group-input input-texts direction-row" style={{"marginBottom" : "75px"}}>
                                    <div className="direction-title">Direction</div>
                                    <div className="radio" style={{
                                        display: 'flex',
                                        width: '175px',
                                        height: '34px',
                                        border: '1px solid #D0D9ED',
                                        borderRadius: '5px',
                                        position:'relative'
                                    }}>
                                        <div 
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                left:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                color: '#2e3e50',
                                                fontSize: '14px',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setTypesDirectionMode('reverse')}
                                        >Reverse</div>
                                        <div
                                            style={{
                                                width: '50%',
                                                position: 'absolute',
                                                top: '0px',
                                                right: '0px',
                                                zIndex: '2',
                                                height: '100%',
                                                color: '#2e3e50',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            onClick={() => setTypesDirectionMode('straight')}
                                        >Straight</div>
                                        <div                                             
                                        style={{
                                            position:'absolute',
                                            left: typesDirectionMode === 'reverse' ? 0 : '50%',
                                            top:0,
                                            width:'50%',
                                            height:'100%',
                                            transition:'left 250ms linear',
                                            willChange:'left',
                                            background:'#D0D9ED',
                                            borderRadius:'5px'                                                                                    
                                        }}></div>
                                    </div>
                                </div>

                                <div className='toggle-flex' style={{marginBottom: '90px'}}>
                                    <label className="switch" htmlFor="showsongs" style={{margin:"4px 24px 0 0"}}>
                                        <input type="checkbox" 
                                            name="showsongs" 
                                            id="showsongs"
                                            checked={typeShowSongToggle}
                                            onChange={e => setTypeShowSongToggle(e.target.checked)}   
                                                    
                                            ></input>
                                        <span className="slider"></span>
                                    </label>
                                    <div className="upload-row">
                                        <div className="header">Show songs button in main screen</div>
                                    </div>
                                </div>


                                <div className='toggle-flex'  >
                                    <label className="switch" htmlFor="arrivalSc" style={{margin:"4px 24px 0 0"}}>
                                        <input type="checkbox" 
                                            name="arrivalSc" 
                                            id="arrivalSc"
                                            checked={typeArrivalScMainToggle}
                                            onChange={e => setTypeArrivalScMainToggle(e.target.checked)}   
                                                    
                                            ></input>
                                        <span className="slider"></span>
                                    </label>
                                    <div className="upload-row">
                                        <div className="header">Arrival split screen</div>
                                    </div>
                                </div>

                                    {typeArrivalScMainToggle &&
                                    <div className='type-arrival-split-screen-content'> 

                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Main Title</div>
                                            <div className="filde-col">
                                                <Input
                                                    setter={setTypeArrivalScMainTitle}
                                                    state={typeArrivalScMainTitle}
                                                    isActive={true}
                                                    title={''}
                                                    placeholder='Main title'
                                                /> 
                                            </div>
                                        </div>

                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Sub Title</div>
                                            <div className="filde-col">
                                                <Input
                                                    setter={setTypeArrivalScMainSubtitle}
                                                    state={typeArrivalScMainSubtitle}
                                                    isActive={true}
                                                    title={''}
                                                    placeholder='Sub title'
                                                /> 
                                            </div>
                                        </div>

                                        <div className="arrival-split-screen-content-group">
                                            <div className="input-col">
                                                <InputToggle
                                                 setter={setTypeArrivalScOnSaleToggle}
                                                 state={typeArrivalScOnSaleToggle}
                                                 isActive={true}
                                                 title={'On Sale'}
                                                 id={'arrivalScOnSale'}
                                                />
                                            </div>
                                            <div className="title-col">
                                                <div className="input-title">Menu Title</div>
                                            </div>
                                            <div className="filde-col">
                                                 <Input
                                                    setter={setTypeArrivalScOnSaleMenuTitle}
                                                    state={typeArrivalScOnSaleMenuTitle}
                                                    isActive={typeArrivalScOnSaleToggle}
                                                    title={''}
                                                    placeholder='Menu Title'
                                                /> 
                                            </div>
                                        </div>

                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Page Title</div>
                                            <div className="filde-col">
                                                <Input
                                                    setter={setTypeArrivalOnSalePageTitle}
                                                    state={typeArrivalOnSalePageTitle}
                                                    isActive={typeArrivalScOnSaleToggle}
                                                    title={''}
                                                    placeholder='Page Title'
                                                /> 
                                            </div>
                                        </div>

                                        <div className="arrival-split-screen-content-group">
                                            <div className="input-col">
                                                <InputToggle
                                                 setter={setTypeArrivalScSpecialsToggle}
                                                 state={typeArrivalScSpecialsToggle}
                                                 id={'arrivalScOnSpecials'}
                                                 title={'Specials'}
                                                />
                                            </div>
                                            <div className="title-col">
                                                <div className="input-title">Menu Title</div>
                                            </div>
                                            <div className="filde-col">
                                            <Input
                                                    setter={setTypeArrivalSpecialsMenuTitle}
                                                    state={typeArrivalSpecialsMenuTitle}
                                                    isActive={typeArrivalScSpecialsToggle}
                                                    title={''}
                                                    placeholder='Menu Title'
                                                /> 
                                            </div>
                                        </div>

                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Page Title</div>
                                            <div className="filde-col">
                                            <Input
                                                setter={setTypeArrivalSpecialsPageTitle}
                                                state={typeArrivalSpecialsPageTitle}
                                                isActive={typeArrivalScSpecialsToggle}
                                                title={''}
                                                placeholder='Page Title'
                                                /> 
                                            </div>
                                        </div>


                                        <div className="arrival-split-screen-content-group">
                                            <div className="input-col">
                                                <InputToggle
                                                 setter={setTypeSurveyToggle}
                                                 state={typeSurveyToggle}
                                                 id={'surveyToggle'}
                                                 title={'Survey (1-5)'}
                                                />
                                            </div>
                                            <div className="title-col">
                                                <div className="input-title">Menu Title</div>
                                            </div>
                                            <div className="filde-col">
                                            <Input
                                                    setter={setTypeSurveyMenuTitle}
                                                    state={typeSurveyMenuTitle}
                                                    isActive={typeSurveyToggle}
                                                    placeholder='Survey'
                                                /> 
                                            </div>
                                        </div>


                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col" style={{alignSelf: 'baseline'}}>{Object.keys(typeSurveyQuestions).length < 2 ? 'Question' :'Questions'}</div>
                                            <div className="filde-col">
                                                <SurveyInput
                                                    setter={setTypeSurveyQuestions}
                                                    state={typeSurveyQuestions}
                                                    isActive={typeSurveyToggle}
                                                />
                                            </div>
                                        </div>



                                        <div className="arrival-split-screen-content-group">
                                            <div className="input-col">
                                                <label className="base-switch" htmlFor="arrivalScOnJoinGroup" style={{margin:"4px 24px 0 0"}}>
                                                    <input type="checkbox" 
                                                        name="arrivalScOnJoinGroup" 
                                                        id="arrivalScOnJoinGroup"
                                                        checked={typeArrivalScJoinGroupToggle}
                                                        onChange={e => setTypeArrivalScJoinGroupToggle(e.target.checked)}  
                                                                
                                                    ></input>
                                                </label>
                                                <div className="base--switch-title">Join our club</div>
                                            
                                            </div>
                                            <div className="title-col">
                                                <div className="input-title">Menu Title</div>
                                            </div>
                                            <div className="filde-col">
                                                <Input
                                                    setter={setTypeArrivalScJoinGroupMenuTitle}
                                                    state={typeArrivalScJoinGroupMenuTitle}
                                                    isActive={typeArrivalScJoinGroupToggle}
                                                    title={''}
                                                    placeholder=''
                                                /> 
                                            </div>
                                            

                                        </div>
                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Page Title</div>
                                            <div className="filde-col">
                                                <Input
                                                    setter={setTypeArrivalScJoinGroupPageTitle}
                                                    state={typeArrivalScJoinGroupPageTitle}
                                                    isActive={typeArrivalScJoinGroupToggle}
                                                    title={''}
                                                    placeholder=''
                                                /> 
                                            </div>
                                        </div>
                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">Page Sub title</div>
                                            <div className="filde-col">
                                            <Input
                                                setter={setTypeArrivalScJoinGroupSubtitle}
                                                state={typeArrivalScJoinGroupSubtitle}
                                                isActive={typeArrivalScJoinGroupToggle}
                                                title={''}
                                                placeholder=''
                                            /> 
                                            </div>
                                        </div>
                                        <div className="arrival-split-screen-content-group buffer-left">
                                            <div className="title-col">SMS Text</div>
                                            <div className="filde-col">
                                            <Input
                                                setter={setTypeArrialScJoinGroupSmsTxt}
                                                state={typeArrialScJoinGroupSmsTxt}
                                                isActive={typeArrivalScJoinGroupToggle}
                                                title={''}
                                                placeholder='SMS Text'
                                            /> 
                                            </div>
                                        </div>

                                    </div>} 
                                  
                                    


                            </div>                    
                        } 




                        </div>
                    </div>


                    <div style={{padding:'2px 0 0 2px'}}>
                        <div className="buttons-env">
                            <button className={`submit`} type="submit" style={{marginTop:'30px'}}>Send</button> 
                            {/* ${(!toggleDelivery && !toggleMeeting) ? 'disabled' : ''}     */}
                            {/* || uploadingFiles !== 0           */}
                            <div style={{display:'flex', alignItems:'left', width:'74.4%'}}>

                                {isSaveSettings ? (
                                <div className="text" style={{color:'#000000', marginTop:'30px'}}>{Saved}</div>
                                ) : (
                                ' '
                                )}

                                {/* {(!toggleDelivery && !toggleMeeting) ? (
                                <div className="text" style={{color:'#FF2B2B', marginTop:'30px'}}>You must enable at least one function in the main flow</div>
                                
                                ) : (
                                ' '
                                )} */}
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    )
}

export default RestSettings;
