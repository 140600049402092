import React from "react";
import { storage, db } from "../../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';

const DeleteGroup = ({api, groups, users, groupEdit, company, changeGroupEdit, changeIsGroupDelete, changeGroupInView}) =>{
    const robot = useStoreState(store => store.robot);
    // const settingsApi = robotsApi.doc(robot).collection('Settings');
    const image_id = groupEdit.group.entity_pic ? groupEdit.group.entity_pic.split('%2F')[1].split('?')[0] : undefined;
    // console.log(image_id)
    const killPopup = () =>{
        changeGroupInView({entity_name: 'Entities'});
        changeGroupEdit({isEdit: false, group: {}, type: '', id:''});
        changeIsGroupDelete(false);
    }
    const deleteGroup = () => {
        if(image_id){
            storage.ref().child(company + '/' + image_id).delete().catch(e => console.log(e));
        }
        const linkedUsers = users.filter((user) =>{
            return user.entity_group.includes(groupEdit.group.entity_name);
        });
        const batch = db.batch();
        const meetingHierarchiesRobotsSet = new Set()
        const deliveryHierarchiesRobotsSet = new Set()

        const groupsObj = {}
        groups.forEach(group => {
            groupsObj[group.entity_name] = group
        })
        for(var i = 0; i < linkedUsers.length; i++){
            meetingHierarchiesRobotsSet.clear()
            deliveryHierarchiesRobotsSet.clear()
            let remainingGroups = linkedUsers[i].entity_group.filter((group) => {
                return group !== groupEdit.group.entity_name
            })       
            linkedUsers[i].entity_group.forEach(group => {
                if(group !== groupEdit.group.entity_name){
                    groupsObj[group].robots.forEach(robotId => { ///groups
                        meetingHierarchiesRobotsSet.add(robotId)
                        deliveryHierarchiesRobotsSet.add(robotId)
                    })
                }
            })
            const meetingHierarchiesRobotsArr = [...meetingHierarchiesRobotsSet]
            const deliveryHierarchiesRobotsArr =[...deliveryHierarchiesRobotsSet]
            batch.update(api.doc(linkedUsers[i].id), {
                entity_group: remainingGroups,
                meeting_hierarchy:meetingHierarchiesRobotsArr,
                delivery_hierarchy:deliveryHierarchiesRobotsArr
            });
        }
        batch.delete(api.doc(groupEdit.group.id));
        batch.commit()
        .catch((error) => {
            console.log(error);
        })        
        .finally(() =>{
            killPopup();
        });
    }
    
    return (
        <div className="group-popup-env">
            <div className="group-popup" onClick={() => {killPopup()}}></div>
            <div className="popup delete">
                <div className="header-large">Delete group</div>
                <div className="text">Are you sure you want to delete the group?</div>
                <div className="buttons-row">
                    <button className="submit-new-doc" onClick={() => {deleteGroup()}}>Delete</button>
                    <button className="abort" onClick={() => {killPopup()}}>Discard</button>
                </div>
            </div>
        </div>
    )    
}

export default DeleteGroup