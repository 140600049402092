import {action, createStore} from 'easy-peasy';

const store = createStore(
  {  
    isResetPassword: false,
    setIsResetPassword: action((state, val) => {
      state.isResetPassword = val;
    }),

    routeEmail: '',
    setRouteEmail: action((state, val) =>{
      state.routeEmail = val;
    }),

    displayName: '',
    setDisplayName: action((state, val) =>{
      state.displayName = val;
    }),

     //add to store current page number
     pageViewIndex: 0,
     setPageViewIndex: action((state, val) => {
       state.pageViewIndex = val;
     } ),


    isSettings: false,
    setIsSettings: action((state, val) => {
      state.isSettings = val;
    }),

    isRest: false,
    setIsRest: action((state, val) => {
      state.isRest = val;
    }),

    isEntitiesToGroup: false,
    setIsEntitiesToGroup: action((state, val) => {
      state.isEntitiesToGroup = val;
    }),

    isRemoveFromGroup: false,
    setIsRemoveFromGroup: action((state, val) =>{
      state.isRemoveFromGroup = val;
    }),

    robot: '',
    setRobot: action((state, val) =>{
      state.robot = val;
    }),
    phonePrefix: '',
    setPhonePrefix: action((state, val) =>{
      state.phonePrefix = val;
    }),
    smsCount: '',
    setSmsCount: action((state, val) =>{
      state.smsCount = val;
    }),
    smsLimit: '',
    setSmsLimit: action((state, val) =>{
      state.smsLimit = val;
    }),
    languages: [],
    setLanguages: action((state, val) =>{
      state.languages = val;
    }),
    uploadingFiles: 0,
    setUploadingFiles: action((state, val) => {
      state.uploadingFiles = val;
    })

  }
);

export default store;

