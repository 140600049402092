import React, { useCallback, useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { db } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';
import logo from '../../assets/images/madatechlogo.png'; 



const Madatech = () => {


  const [error, setError] = useState('');
  const [Robot1Data, setRobot1Data] = useState('')
  const [Robot2Data, setRobot2Data] = useState('')
  const [Robot3Data, setRobot3Data] = useState('')
  const handleDelivery = (field, serial) => {
    const api = db.collection('Companies').doc("Madatech").collection('Settings').doc(serial);
      
    api.update({[field]:true}).finally(()=> {
        //window.close();
        alert("הרובוט בדרכו ליעד!")
    })
  };
  const handleVolume = (field, serial) => {
    const api = db.collection('Companies').doc("Madatech").collection('Settings').doc(serial);
    console.log(field)
    api.update({[field]:true}).finally(()=> {
      alert("הווליום אופס ל-3!")
      //window.close();
    })
  }

  // const [alerts, setAlerts] = useState([])

 useEffect(() => {
  const RobotsApi = db.collection('Companies').doc("Madatech").collection('Settings');
  RobotsApi.onSnapshot(snapshot => {
    const tempsnapstot = snapshot.docs.filter(doc => doc.id === '00120454753' || doc.id === '00120454772')
    console.log(tempsnapstot)
    const tmp1 = tempsnapstot[0].data()
    setRobot1Data(tmp1)
    const tmp2 = tempsnapstot[1].data()
    setRobot2Data(tmp2)
    // const tmp3 = tempsnapstot[2].data()
    // setRobot3Data(tmp3)
  }
  )
     return () => {
      
     }
 }, [])

  return (
    console.log(Robot1Data),
    <div className="madatech-env">
        <div className="logo-madatech">
          <img src={logo} alt="logo"/>
        </div>
        <div className="txt-ichilov">:רובוט 1</div>
        <div className="buttons-env-top">
          <div className="confirm"
            onClick={() => handleVolume('resetVolume', '00120454753')}>איפוס ווליום</div>
                      <div className="confirm"
                      style={{opacity: !Robot1Data.backHome && !Robot1Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('backHome', '00120454753') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת טעינה</div>
                      <div className="confirm"
                      style={{opacity: !Robot1Data.backHome && !Robot1Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('goStart', '00120454753') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת כניסה</div>
        </div>
        <div className="txt-ichilov">:רובוט 2</div>
        <div className="buttons-env-top">
          <div className="confirm"
            onClick={() => handleVolume('resetVolume', '00120454772')}>איפוס ווליום</div>
                      <div className="confirm"
                      style={{opacity: !Robot2Data.backHome && !Robot2Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('backHome', '00120454772') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת טעינה</div>
                      <div className="confirm"
                      style={{opacity: !Robot2Data.backHome && !Robot2Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('goStart', '00120454772') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת כניסה</div>
        </div>
        {/* <div className="txt-ichilov">:רובוט 3</div>
        <div className="buttons-env-top">
          <div className="confirm"
            onClick={() => handleVolume('resetVolume', '00120454784')}>איפוס ווליום</div>
                      <div className="confirm"
                      style={{opacity: !Robot3Data.backHome && !Robot3Data.goStart ? '1': '0.2'}}
            onClick={() => handleDelivery('backHome', '00120454784')}>עמדת טעינה</div>
                      <div className="confirm"
                      style={{opacity: !Robot3Data.backHome && !Robot3Data.goStart ? '1': '0.2'}}
            onClick={() => handleDelivery('goStart', '00120454784')}>עמדת כניסה</div>
        </div> */}
    </div>
  );
};

export default Madatech