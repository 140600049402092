import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';
import {useStoreActions} from 'easy-peasy';


const PrivateRoute = ({ component: RouteComponent, ...rest}) => {
    const {currentUser} = useContext(AuthContext);
    const setRouteEmail = useStoreActions(store => store.setRouteEmail);
    // const setDisplayName = useStoreActions(store => store.setDisplayName);

    if(currentUser){
        // console.log(currentUser)
        setRouteEmail(currentUser.email);
        // setDisplayName(currentUser.displayName);
    }
    return(
        <Route
            {...rest}
            render={routeProps => 
                currentUser ? (<RouteComponent {...routeProps}/>) : (<Redirect to={"/login"}/>)
            }
        />
    )
};

export default PrivateRoute