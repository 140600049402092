import React from 'react';

const Loading = () => {
    return(
        <div className="loading">
            <div className="text-env">
                <div className="text">
                    <span>Loading ...</span>
                </div>
            </div>
        </div>
    )
}

export default Loading