import React, { useState, useEffect } from "react";
import { storage } from "../../firebase";
import MultiSelect from "react-multi-select-component";
import useValidation from '../hooks/FormValidation';
import defaultUserImg from '../../assets/images/default_entity.svg';
import upload_img from '../../assets/images/upload.svg';
import {useStoreState} from 'easy-peasy';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

const UpdateUser = ({user, changeIsEditUser, api, robotsApi, locationsProp, groups, addToGroup, company}) => {
    const [entity_firstname, setEntityFirstname] = useState(user.entity_firstname);
    const [entity_lastname, setEntityLastname] = useState(user.entity_lastname);
    const [entity_phone, setEntityPhone] = useState(user.entity_phone.length > 9 ? user.entity_phone.substring(3) : user.entity_phone);
    const [entity_email, setEntityEmail] = useState(user.entity_email);
    const [entity_location, setEntityLocation] = useState(user.entity_location);
    const [description, setDescription] = useState(user.entity_description)

    const [userDisplayImg, setUserDisplayImg] = useState(user.entity_pic ? user.entity_pic : defaultUserImg);

    const [photoProgress, setPhotoProgress] = useState(0);

    const [groupValues] = useState(groups.filter(g => g.entity_name !== 'Entities').map((g) => {return {label: g.entity_name, value: g.entity_name}}));
    const [selectedGroups, setSelectedGroups] = useState(user.entity_group.map(g => {return {label: g, value: g}}));
    

    const [isFirstNameValid, setIsFirstNameValid, firstNameError] = useValidation('entity_firstname',user.entity_firstname); 
    const [isLastNameValid, setIsLastNameValid, lastNameError] = useValidation('entity_lastname', user.entity_lastname); 
    const [isPhoneValid, setIsPhoneValid, phoneError] = useValidation('entity_phone', entity_phone); 
    const [isEmailValid, setIsEmailValid, emailError] = useValidation('entity_email', user.entity_email); 

    const image_id = user.entity_pic ? user.entity_pic.split('%2F')[1].split('?')[0] : undefined;
    const [tempPicName, setTempPicName] = useState();
    const [newPicLink, setNewPicLink] = useState();

    const phonePrefix = useStoreState(store => store.phonePrefix);

    //robots
    const [robots, setRobots] = useState([]);
    const [robot, setRobot] = useState(''); //set to user locations robot
    const [locations, setLocations] = useState([]);

    
    const changeRobot = robot =>{
        setRobot(robot);
        const tmpArr = [];
        locationsProp.map((location) => {
            location.robots.map((locationRobot) => {
                if(locationRobot === robot){
                    tmpArr.push(location);
                }
            })
        });
        setLocations(tmpArr);
        setEntityLocation('');
    }

    useEffect(() => {
        if(entity_location){
            const tempLocation = locationsProp.filter((location) => {
                return entity_location === location.id;
            })
            if(!tempLocation[0]) {return}
            setRobot(tempLocation[0].robots[0]);
            const tmpArr = [];
            locationsProp.map((location) => {
                location.robots.map((locationRobot) => {
                    if(locationRobot === tempLocation[0].robots[0]){
                        tmpArr.push(location);
                    }
                })
            });
            setLocations(tmpArr);
        }
    }, [locationsProp])


    useEffect(() => {
        const rand = Math.floor(Math.random() * (1000000 - 1 + 1) ) + 1;
        setTempPicName('/user_pic' + rand)

        // if(entity_location){
        //     const tempLocation = locationsProp.filter((location) => {
        //         return entity_location === location.id;
        //     })
        //     if(!tempLocation[0]) {return}
        //     setRobot(tempLocation[0].robots[0]);
        //     const tmpArr = [];
        //     locationsProp.map((location) => {
        //         location.robots.map((locationRobot) => {
        //             if(locationRobot === tempLocation[0].robots[0]){
        //                 tmpArr.push(location);
        //             }
        //         })
        //     });
        //     setLocations(tmpArr);
        // }

        const unsubscrib = robotsApi.onSnapshot(snapshot =>{
            const tempArr = []
            snapshot.docs.map(doc => {
                tempArr.push(doc.id)
            })
            setRobots(tempArr);   
        })
        return () =>{
            unsubscrib();
        }
    },[])

    const updateTitle = () => {
        console.log(selectedGroups, 'selectedGroups')
        if (selectedGroups.length > 0) {
            // console.log(selectedGroups[0].value);
            if (selectedGroups[0].value === "Tables") {
                return <div className="header-large">Update Tables Numbers</div>;
            } else if (selectedGroups[0].value === "Songs") {
                return <div className="header-large">Update Song</div>;
            } else if (selectedGroups[0].value === "Specials") {
                return <div className="header-large">Update On Specials</div>;
            } else {
                return <div className="header-large">Edit Entity</div>;
            }
        }
        else{
            return <div className="header-large">Edit Entity</div>;
        }
    };


    const uploadPhoto = (e) =>{
        if(!e.target.files[0]){
            return;
        }
        if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){

            return;
        }
        const photo = e.target.files[0];
        
        const fileName = company + tempPicName;
        const uploadTask = storage.ref(fileName).put(photo);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(company)
            .child(tempPicName) 
            .getDownloadURL().then(link => {
                setNewPicLink(link);
                console.log(link)
                setUserDisplayImg(link);
                // api.doc(user.id).update({entity_pic: link}).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    const uploadSong = (e) =>{
        console.log(e.target.files[0])
        if(!e.target.files[0]){
            return;
        }
        if(e.target.files[0].type !== 'audio/mpeg'){

            return;
        }
        const song = e.target.files[0];
        
        const fileName = company + tempPicName;
        const uploadTask = storage.ref(fileName).put(song);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(company)
            .child(tempPicName) 
            .getDownloadURL().then(link => {
                setNewPicLink(link);
                console.log(link)
                //setUserDisplayImg(link);
                // api.doc(user.id).update({entity_pic: link}).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    ///////
    const formatPhone = val => {
        if(val[0] === '0'){
            setEntityPhone(val.substring(1));
        }else{
            setEntityPhone(val);
        }
        setIsPhoneValid(val)
    }

    const resetUserValues = () =>{
        setEntityFirstname(user.entity_firstname);
        setEntityLastname(user.entity_lastname);
        setEntityPhone(user.entity_phone);
        setEntityEmail(user.entity_email);
        setEntityLocation(user.entity_location);
        setSelectedGroups(user.entity_group.map(g => {return {label: g, value: g}}));
        setDescription(user.entity_description)
    }

    const submitUserUpdate = (e) =>{
        e.preventDefault();
        if(!isFirstNameValid || !isLastNameValid || !isEmailValid){
            return;
        }
        let groupNames = selectedGroups.map(g => g.value);
        const fullSelectedGroupsArr = groups.filter(fullGroup => {
            return groupNames.includes(fullGroup.entity_name)
            // selected new group
        })
        debugger
        //meetingHierarchy---------------------------------------------------------
        let meetingHierarchyRobotsSet = new Set()
        for(let i = 0;i < fullSelectedGroupsArr.length; i++){
            user.meeting_hierarchy.forEach(robotId => {
                if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                    meetingHierarchyRobotsSet.add(robotId)
                }
            }) 
            if(user.meeting_hierarchy.length === 0){
                fullSelectedGroupsArr[i].robots.forEach(robotId => {
                    meetingHierarchyRobotsSet.add(robotId)
                })
            }           
        }
        const finalMeetingHierarchyRobotsArr = [...meetingHierarchyRobotsSet]
        //deliveryHierarchy---------------------------------------------------------
        let deliveryHierarchyRobotsSet = new Set()
        for(let i = 0;i < fullSelectedGroupsArr.length; i++){
            user.delivery_hierarchy.forEach(robotId => {
                if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                    deliveryHierarchyRobotsSet.add(robotId)
                }
            }) 
            if(user.delivery_hierarchy.length === 0){
                fullSelectedGroupsArr[i].robots.forEach(robotId => {
                    deliveryHierarchyRobotsSet.add(robotId)
                })
            }           
        }
        const finalDeliveryHierarchyRobotsArr = [...deliveryHierarchyRobotsSet]
        /////-------------------------------------------------------------------------
        api.doc(user.id).update(
            {
                entity_firstname: entity_firstname,
                entity_lastname: entity_lastname,
                entity_phone: entity_phone.length > 1 ? phonePrefix + entity_phone : entity_phone,
                meeting_hierarchy:finalMeetingHierarchyRobotsArr,
                delivery_hierarchy:finalDeliveryHierarchyRobotsArr,
                entity_email: entity_email,
                entity_location: entity_location,
                entity_group: groupNames,
                entity_pic: newPicLink ? newPicLink : user.entity_pic ? user.entity_pic : '',
                entity_description: description ? description : ''
            }
        ).then(() => {
            //delete old pic
            if(newPicLink && user.entity_pic){
                console.log(newPicLink)
                storage.ref().child(company + '/' + image_id).delete().catch(e => console.log(e));
            }
            // storage.bucket(company).file(image_id + '.png').move("temp_2.png").catch(e => console.log(e))
        }).catch(() => {

        }).finally(() => {
            resetUserValues();
            changeIsEditUser(user , false);
        })
    }

    const handleAbort = () =>{
        resetUserValues();
        if(!newPicLink){
            changeIsEditUser(user , false);
            return;
        }
        storage.ref().child(company + '/' + tempPicName).delete().then(() => {
            changeIsEditUser(user , false);
        }).catch(e => console.log(e));        
    }

    return(
    <div className="popup-env">
        <div className="popup" onClick={() => handleAbort()}></div>
        <div className="content-large">            
            <div className="user-form">
            {/* <div className="header-large">Edit Entity</div> */}
            {updateTitle()}
            <form onSubmit={submitUserUpdate} autoComplete="off">
                <div className="form-group first-name">
                    <label>First name</label>
                    <input
                    autoComplete="new-password"
                    maxLength="255"
                    type="text"
                    name="entity_firstname"
                    onChange={e => {setEntityFirstname(e.target.value); setIsFirstNameValid(e.target.value)}}
                    value={entity_firstname}
                    />
                    <div className="validation-env"> 
                        <div className={`invalid-feedback ${isFirstNameValid ? '' : 'on'}`}>{firstNameError}</div>
                    </div>
                </div>
                <div className="form-group last-name">
                    <label>Last name</label>
                    <input
                    autoComplete="new-password"
                    maxLength="255"
                    type="text"
                    disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                    name="entity_lastname"
                    onChange={e => {setEntityLastname(e.target.value); setIsLastNameValid(e.target.value)}}
                    value={entity_lastname}
                    />
                    <div className="validation-env"> 
                        <div className={`invalid-feedback ${isLastNameValid ? '' : 'on'}`}>{lastNameError}</div>
                    </div>
                </div>
                <div className="form-group phone">
                    <label>Phone number</label>
                    <input
                    autoComplete="new-password"
                    maxLength="12"
                    type="text"
                    name="entity_phone"
                    disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                    onChange={e => formatPhone(e.target.value)}
                    value={entity_phone}
                    />
                    <div className="phone-prefix">+{phonePrefix}</div>
                    <div className="validation-env"> 
                        <div className={`invalid-feedback ${isPhoneValid ? '' : 'on'}`}>{phoneError}</div>
                    </div>
                </div>
                {/* <div className="form-group phone">
                    <label>Phone</label>
                    <PhoneInput
                        defaultCountry={phonePrefix}
                        value={entity_phone}
                        onChange={setEntityPhone}
                    />
                    <div className="validation-env"> 
                        <div className={`invalid-feedback ${isPhoneValid ? '' : 'on'}`}>{phoneError}</div>
                    </div>
                </div> */}
                <div className="form-group email">
                    <label>Email</label>
                    <input
                    autoComplete="new-password"
                    maxLength="255"
                    type="text"
                    name="entity_email"
                    disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                    onChange={e => {setEntityEmail(e.target.value); setIsEmailValid(e.target.value)}}
                    value={entity_email}
                    />
                    <div className="validation-env"> 
                        <div className={`invalid-feedback ${isEmailValid ? '' : 'on'}`}>{emailError}</div>
                    </div>
                </div>
                <div className="form-group description">
                    <label>Description</label>
                    <textarea
                    autoComplete="new-password"
                    maxLength="355"
                    type="text"
                    rows={3}
                    name="description"
                    disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" )?true:false}
                    onChange={e => {setDescription(e.target.value)}}
                    value={description}
                    />
                    {/* <div className="validation-env"> 
                        <div className={`invalid-feedback ${isEmailValid ? '' : 'on'}`}>{emailError}</div>
                    </div> */}
                </div>
                <div className="line"></div>
                <div className="header">Loaction</div>
                <div className="form-group location">  
                        <label>From robot</label>
                        <div className="select">
                            <select name="entity_location" 
                            value={robot} onChange={e => changeRobot(e.target.value)}>
                                <option value="" defaultValue disabled hidden></option>
                                {
                                    robots.map(robot => (
                                        <option key={robot + 'l'} value={robot}>{robot}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                <div className="form-group location2">  
                    <label>Location</label>
                    <div className="select">
                        <select name="entity_location" value={entity_location} onChange={e => setEntityLocation(e.target.value)}>
                            <option value="" defaultValue disabled hidden></option>
                            {                            
                                locations.map(l => (
                                    <option 
                                        key={l.id + 'l'} 
                                        value={l.id} 
                                        defaultValue={entity_location === l.id}
                                        >{l.id}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="line"></div>
                <div className="header">Group</div>                
                <div className="form-group group"> 
                    <label className="location-label">Assigned Group</label>  
                    <MultiSelect 
                        options={groupValues}
                        value={selectedGroups}
                        onChange={setSelectedGroups}
                        labelledBy={"select"}
                        disabled={(selectedGroups[0].value === "Songs" ||
                        selectedGroups[0].value === "Tables" ||
                        selectedGroups[0].value === "Specials" )?true:false}
                    />
                </div>
                <div className="line"></div>
                <div className="header">{(selectedGroups[0].value === "Songs")?"Song":"Picture"}</div>
                <div className="photo">
                    <div className="user-image">                        
                        {photoProgress > 0 ? photoProgress < 100 ? 
                            <div className="progress-env">
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width:photoProgress + '%', height:'100%'}}></div>
                                </div>
                            </div> : <img src={userDisplayImg} alt=""/> : <img src={userDisplayImg} alt=""/>
                        }
                    </div>  
                    <input
                    type="file"
                    name="photo"
                    id="createUserPhoto"
                    onChange={
                        (selectedGroups[0].value === "Songs")?e => uploadSong(e):e => uploadPhoto(e)}
                        //e => uploadPhoto(e)}
                    />
                    <label htmlFor='createUserPhoto'>
                        <img src={upload_img} alt="choose file"/>
                    </label> 
                        {/* <div className={isEmailValid ? "invalid-feedback": "invalid-feedback on"}>{emailError}</div> */}
                </div>

                <div className="button-env">                        
                    <button type="submit" className={`submit ${!isFirstNameValid || !isLastNameValid || !isEmailValid ? 'disabled' : ''}`}>Save</button> 
                    <button className="back" onClick={() => handleAbort()}>Discard</button> 
                </div>
            </form>
            </div>
        </div>
    </div>
    )
}
export default UpdateUser;