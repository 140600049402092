import React, { useState } from 'react'
import { storage } from "../../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';

const UploadFile = ({company, setLink, setFileType, setFileName, oldFileName, name, toggle, setToggle, isToggle, title, text}) =>{
    const [fileProgress, setFileProgress] = useState(0)
    const [fileDetails, setFileDetails] = useState({})
    const [fileError, setFileError] = useState('')

    const uploadingFiles = useStoreState(store => store.uploadingFiles)
    const setUploadingFiles = useStoreActions(actions => actions.setUploadingFiles)

    const randomNum = () => {
        return Math.floor(Math.random() * (1000000 - 1 + 1) ) + 1
    }

    const handleUpload = (e) => {        
        if(!e.target.files[0]){return;}
        const file = e.target.files[0]
        switch(name){
            case 'isOpeningVisual': case 'logoImage': case 'backgroundImage':
                if(file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'video/mp4'){  
                    setFileError('The file type is not supported')           
                    return;
                }
                break;
            case 'isOpeningAudio': case 'isMoveAudio' : case 'isArrivedAudio': case 'isDeliveryMoveAudio': case 'isDeliveryArrivedAudio':
                if(file.type !== 'audio/mpeg'){
                    setFileError('The file type is not supported')      
                    return;
                }
                break;
            default: 
        }
        setUploadingFiles(uploadingFiles + 1); // set the general state
        setFileDetails({name:file.name, size:(file.size / 1000).toFixed(2)})
        setFileError('')
        const rand = randomNum();
        const fileName = company + '/Media/' + rand;
        const uploadTask = storage.ref(fileName).put(file);
        uploadTask.on("state_changed", snapshot => {            
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setFileProgress(progress)
            },
            error => {
                console.log(error);
            },
            () => {
            storage
                .ref(company)
                .child('/Media/' + rand) 
                .getDownloadURL().then(link => {
                    setLink(link)
                    setFileType(file.type)
                    setFileName(file.name)
                    setUploadingFiles(uploadingFiles - 1);
                })
                .catch(error => console.log(error))
                .finally(() => {
                    console.log(uploadingFiles)
                })         
            }
        );
    };

    return(
        <div className="upload-file">
            <div className="settings-row">
                    { isToggle &&  
                        <label className="switch" htmlFor={'toggle-' + name}>
                        
                                <div>
                                    <input type="checkbox" 
                                    name={'toggle-' + name} 
                                    id={'toggle-' + name}
                                    checked={toggle}
                                    onChange={e => setToggle(e.target.checked)}        
                                    ></input>
                                    <span className="slider"></span>
                                </div>
                        </label>
                    }
                
                <div className="upload-row" style={{height:'75px', marginBottom: '40px', width:'100%',display:'flex', justifyContent:'space-between'}}>
                    <div className='bar-flex' style={{flexGrow:1, paddingRight:'10%'}}>
                       {title && <div className="header">{title}</div> } 
                        <div style={{color:'#888888'}}>
                            <div className='bar-top-txt' style={{display:'flex'}}>
                                <div>{text}</div>
                                {
                                    fileProgress <=0 && oldFileName && <div style={{marginLeft:'20px'}}>current file: {oldFileName}</div>
                                }
                                {
                                    fileError && <div style={{marginLeft:'20px' , color:'red'}} className="file-error">{fileError}</div>
                                }
                            </div>
                        {
                            fileProgress > 0 && 
                            <div  style={{display:'flex',justifyContent:'space-between', marginBottom:'7px'}}>                                
                                <div style={{display:'flex'}}>
                                    <div className="file-name">{fileDetails.name}</div>
                                    <div className="file-size" style={{paddingLeft:'10px'}}>{fileDetails.size + 'kb'}</div>
                                </div>
                                <div>{fileProgress + '%'}</div>
                            </div>
                        }
                        </div> 
                        <div style={{height:'6px', borderRadius:'3px', background:'#F4F4F5', width:'100%'}}>
                            <div style={{borderRadius:'3px', background:'#00D990', height:'100%', width:fileProgress + '%'}}></div>
                        </div>
                    </div>
                    <div className="file-input">
                        <label htmlFor={name}>Browse File</label>
                        <input type="file" id={name} onChange={e => {handleUpload(e, name)}} />       
                    </div>                 
                </div>
            </div>
        </div>
    )
}
export default UploadFile