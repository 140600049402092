import React from 'react'


const InputToggle = ({setter, state, title,id}) => {
    return (
      <>
        <label className="base-switch" htmlFor={id} style={{margin:"4px 24px 0 0"}}>
            <input type="checkbox" 
                name={id}
                id={id}
                checked={state}
                onChange={e => {setter(e.target.checked)}}
                        
            ></input>
        </label>
        <span className='switch-vey'></span>
        <div className="base--switch-title">{title}</div>
      </>
    )
}

export default InputToggle