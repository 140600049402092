import React from "react";
import ReactDOM from "react-dom";
import {StoreProvider} from 'easy-peasy';
import store from './models/model';
import {BrowserRouter as Router, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-notifications/lib/notifications.css";
import HttpsRedirect from 'react-https-redirect';

import { NotificationContainer } from "react-notifications";
import Home from "./components/Home";
import Login from "./components/authentication/Login"
// import SignUp from "./components/authentication/SignUp"
import { AuthProvider } from "./components/authentication/Auth";
import PrivateRoute from "./components/authentication/PrivateRoute";
import ResetPassword from "../src/components/authentication/ResetPassword";
import './style/index.scss';
import Delivery from "./components/authentication/Delivery";
import Delivery2 from "./components/authentication/Delivery2";
import Amal15 from "./components/authentication/Amal15";
import DeliveryExaptec from "./components/authentication/DeliveryExaptec";
import Ichilov from "./components/authentication/Ichilov";
import Conference from "./components/conferenceComponent/Conference";
import Assimilates from "./components/assimilates/Assimilates";
import PuduConnection from "./components/authentication/PuduConnection";
import Madatech from "./components/authentication/Madatech";


function App() {


  return (
    <>
    <StoreProvider store={store}>
    <NotificationContainer />
    <AuthProvider>
      
      <Router>
        <div className="allView">

        <HttpsRedirect>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" component={Login}/>
          <Route exact path="/reset" component={ResetPassword}/>
          <Route exact path="/delivery" component={Delivery}/>
          <Route exact path="/delivery2" component={Delivery2}/>
          <Route exact path="/amal15" component={Amal15}/>
          <Route exact path="/conference" component={Conference}/>
          <Route exact path="/ichilov" component={Ichilov}/>
          <Route exact path="/delivery-exaptec" component={DeliveryExaptec}/>
          <Route exact path="/assimilates" component={Assimilates}/>
          <Route exact path="/pudu" component={PuduConnection}/>
          <Route exact path="/madatech" component={Madatech}/>
        </HttpsRedirect>
          
        </div>
      </Router>
    </AuthProvider>  
    </StoreProvider>
    </>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);