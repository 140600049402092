import React, { useEffect, useState, useCallback,useRef } from "react";
import {useStoreActions, useStoreState} from 'easy-peasy';
import settings_img from '../../assets/images/settings.svg';
import restsettings_img from '../../assets/images/restsettings.svg';

const Menu = ({groups, changeGroupInView, changeGroupEdit, groupInView, changeIsGroupCreate, settingsPremission, restPremission, robots}) =>{
    const setIsSettings = useStoreActions(actions => actions.setIsSettings);
    const setIsRest = useStoreActions(actions => actions.setIsRest);
    const setRobot = useStoreActions(actions => actions.setRobot);

    const isSettings = useStoreState(store => store.isSettings);
    // const isRest = useStoreState(store => store.isRest)

    const setPageViewIndex = useStoreActions(actions => actions.setPageViewIndex);
    const currentPageIndexRef = useRef(useStoreState(store => store.pageViewIndex));
    // const currentPageIndexRef = useRef(0);

    const [isCollapseMenu, setIsCollapseMenu] = useState(true);
    const [isCollapseSettings, setIsCollapseSettings] = useState(true);
    const changeIsCollapseMenu = useCallback(() => {
      setIsCollapseMenu(v => !v);
    }, []);
    const changeIsCollapseSettings = useCallback(() => {
        setIsCollapseSettings(v => !v)
    }, []);

    const [isCollapseMenuSales, setIsCollapseMenuSales] = useState(true);
    const changeIsCollapseMenuSales = useCallback(() => {
      setIsCollapseMenuSales(v => !v);
    }, []);

    const singleGroupDisplay = (group) =>{
        return(
            <div key={group.entity_name} className={`menuItem ${groupInView.entity_name === group.entity_name ? "active" : ""}`} onClick={() => {filterEntitiesByGroup(group)}}>
                <span className="txt">{group.entity_name}</span>
                {!restPremission &&   <div className="group-buttons">
                    <div className="group-button edit" onClick={() => {changeGroupEdit(group, 'edit', true)}}></div>
                    <div className="group-button delete" onClick={() => {changeGroupEdit(group, 'delete', true)}}></div>
                </div> }
              
            </div>
        )
    }


    // const singleRobotDisplay = (robot,category) =>{
    //     if(category === 'restaurants'){
    //         return(
    //             // <div key={robot} className={`menuItem ${groupInView.entity_name === robot ? "active" : ""}`} onClick={() => {filterByRestaurant(robot)}}></div>
    //             <div key={robot} className={`menuItem ${groupInView.entity_name === robot + 'restaurant' ? "active" : ""}`}  onClick={() => {filterByRestaurant(robot)}} >
    //                 <span className="txt">{robot}</span>
    //             </div>
    //         )
    //     }
    //     if(category === 'settings'){
    //         return(
    //             <div key={robot} className={`menuItem ${groupInView.entity_name === robot ? "active" : ""}`} onClick={() => {filterByRobot(robot)}}>
    //                 <span className="txt">{robot}</span>
    //             </div>
    //         )
    //     }
       
    // }




    const singleRobotDisplay = (robot) =>{
        return(
            <div key={robot} className={`menuItem ${groupInView.entity_name === robot ? "active" : ""}`} onClick={() => {filterByRobot(robot)}}>
                <span className="txt">{robot}</span>
            </div>
        )
    }

     const singleRobotDisplayRest = (robot) =>{
         return(
             <div key={robot} className={`menuItem ${groupInView.entity_name === robot ? "active" : ""}`} onClick={() => {filterByRestaurant(robot)}}>
                 <span className="txt">{robot}</span>
             </div>
         )
     }

    const filterByRobot = (robot) =>{
        setRobot(robot);
        changeGroupInView({entity_name: robot});
        console.log('change view to robot', robot);
        if(currentPageIndexRef.current !== 1){
        setPageViewIndex(1);
        currentPageIndexRef.current = 1;
        }
      
    }

    const filterByRestaurant = (robot) =>{
        setRobot(robot);
        changeGroupInView({entity_name: robot});
        console.log('change view to robot', robot);
        if(currentPageIndexRef.current !== 2){
            setPageViewIndex(2);
            currentPageIndexRef.current = 2;
        }
    }

    const filterEntitiesByGroup = (group) => {   
        console.log('In Entities group'); 
        // changeGroupInView(group);
        changeGroupInView({entity_name: group.entity_name});
        console.log('change view to group', group);
        if(currentPageIndexRef.current !== 0){
           setPageViewIndex(0);
           currentPageIndexRef.current = 0;
        }

    }

    return(
        <>
        <div className="menu">
            <div className="logo"></div>
            <div className={`menuItem entities ${groupInView.entity_name === 'Entities' ? "active" : ""}`} onClick={() => {filterEntitiesByGroup({entity_name: 'Entities'})}}>
                <div className="content">
                    <div className="entities-image"></div>               
                    <div>Entities</div>   
                </div>
            </div>
            <div className="menu-collapse-env">
                <div className="collapse-header" onClick={() => changeIsCollapseMenu()}>
                    <div className="text">Groups</div>
                    <div className={`arrow ${isCollapseMenu ? 'up' : 'down'}`}></div>
                </div>
                <div className={`menu-collapse ${isCollapseMenu ? 'in' : 'out'}`}>
                    {groups.map(group => (
                        group.entity_groupType != 'sale' ?
                        group.entity_name ? singleGroupDisplay(group) : ''
                        : ""
                     ))} 
                </div>
                <div className="collapse-header" onClick={() => changeIsCollapseMenuSales()}>
                    <div className="text">Sales</div>
                    <div className={`arrow ${isCollapseMenuSales ? 'up' : 'down'}`}></div>
                </div>
                <div className={`menu-collapse ${isCollapseMenuSales ? 'in' : 'out'}`}>
                    {groups.map(group => (
                        group.entity_groupType === 'sale' ?
                        group.entity_name ? singleGroupDisplay(group) : ''
                        : ""
                     ))} 
                </div>
            </div>
            <div>
                <div className="create" onClick={() => changeIsGroupCreate(true)}><span>+</span><span>Create Group</span></div>
                {
                    settingsPremission &&                       
                    <div>
                        <div className="settings">
                            <img alt="" src={settings_img}></img>
                            <div>Settings</div>
                        </div>
                        <div className="menu-collapse-env">
                            <div className="collapse-header" onClick={() => changeIsCollapseSettings()}>
                                <div className="text">Robots</div>
                                <div className={`arrow ${isCollapseSettings ? 'up' : 'down'}`}></div>
                            </div>
                            <div className={`menu-collapse ${isCollapseSettings ? 'in' : 'out'}`}>
                                {robots.map(robot => (singleRobotDisplay(robot)))}
                            </div>
                        </div>  
                    </div>
                }
                { 
                    restPremission &&                       
                    <div>
                        <div className="settings">
                            <img alt="" src={restsettings_img}></img>
                            <div>Settings Restaurants</div>
                        </div>
                        <div className="menu-collapse-env">
                            <div className="collapse-header" onClick={() => changeIsCollapseSettings()}>
                                <div className="text">Robots</div>
                                <div className={`arrow ${isCollapseSettings ? 'up' : 'down'}`}></div>
                            </div>
                            <div className={`menu-collapse ${isCollapseSettings ? 'in' : 'out'}`}>
                                {robots.map(robot => (singleRobotDisplayRest(robot)))}
                            </div>
                        </div>  
                    </div>
                } 
            </div>
        </div>
        </>
    );
}
export default Menu;