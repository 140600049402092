import React, {useEffect, useState} from "react";
import { storage } from "../../firebase";
import useValidation from '../hooks/FormValidation';
import upload_img from '../../assets/images/upload.svg';
import default_group_img from '../../assets/images/default_group.svg';

const CreateGroup = (props) =>{
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupType, setNewGroupType] = useState('');
    const [isNewNameValid, setIsNewNameValid, newNameError] = useValidation('entity_name',newGroupName); 
    const [exsistsError, setExsistsError] = useState();
    // photo
    const [photoProgress, setPhotoProgress] = useState(0);
    const [groupDisplayImg, setGroupDisplayImg] = useState(default_group_img);
    const [doc, setDoc] = useState(props.api.doc())
    useEffect(() => {
        doc.set({entity_type:'group', is_group: 'true'}).catch(e => console.log(e));
    }, [])

    const uploadPhoto = (e) =>{
        if(!e.target.files[0]){
            return;
        }
        if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){
            return;
        }
        const photo = e.target.files[0];
        const name = '/group_image' + doc.id
        const fileName = props.company + name;
        const uploadTask = storage.ref(fileName).put(photo);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(props.company)
            .child(name) 
            .getDownloadURL().then(link => {
                setGroupDisplayImg(link)
                props.api.doc(doc.id).update({entity_pic: link}).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    const killPopup = () =>{        
        props.changeIsGroupCreate(false);
    }

    const create = (event) => {
        event.preventDefault();
        if(!isNewNameValid || exsistsError !== undefined){
            return
        }        
        doc.update({entity_name: newGroupName, entity_groupType: newGroupType, robots:[]})
        .catch(error => {
            console.log(error);
        }).finally(()=>{
            setNewGroupName('');
            setNewGroupType('');
            killPopup();
        });        
    }

    const validateExistance = (name) =>{
        if(isNewNameValid){
            // console.log('valid')
            if(!props.groups.every(group => group.entity_name !== name)){
                // console.log('ex')
                setExsistsError('Group name alredy exists')
            }else{
                setExsistsError();
            }
        }        
    }

    const handleAbort = () =>{
        props.api.doc(doc.id).delete().then(() => {
            killPopup();
        }).catch((error) => {
            console.log(error);
        })        
    }

    return (
        <div className="group-popup-env">
            <div className="group-popup" onClick={() => {handleAbort()}}></div>
            <div className="popup create">
                <div className="header-large">Create group</div>
                <div className="text">Group name</div>
                <form onSubmit={create} className="new-doc-form" autoComplete="off">
                    <input 
                        autoComplete="new-password"
                        type="text" name="newGroup"
                        maxLength="255"
                        placeholder={'Untitled'}
                        onChange={e => {setNewGroupName(e.target.value); setIsNewNameValid(e.target.value); validateExistance(e.target.value)}}
                        value={newGroupName}
                        className="new-doc"                        
                    />
                    
                    <div className="error-env">
                        <div className={isNewNameValid ? "invalid-feedback": "invalid-feedback on"}>{newNameError}</div>
                        <div className={!exsistsError ? "invalid-feedback": "invalid-feedback on"}>{exsistsError}</div>
                    </div>
                    <div className="text-type">Group type</div>
                    <select className="react-select" style={
                        {
                            marginRight:'10px',
                            height: '44px',
                            width: '178px',
                            border: '1px solid #D0D9ED',
                            padding:'1px 2px 1px 8px',
                            color: '#525252',
                            borderRadius: '5px'
                        }
                    }
                    value={newGroupType}
                    onChange={e => setNewGroupType(e.target.value)}>
                    <option value="location">Location</option>
                    <option value="sale">Sale</option>    
                    </select>         
                    {/* <input 
                        autoComplete="new-password"
                        type="text" name="newGroup"
                        maxLength="255"
                        placeholder={'Your group type'}
                        onChange={e => {setNewGroupType(e.target.value)}}
                        value={newGroupType}
                        className="new-doc"                        
                    />             */}
                    <div className="header">Group image</div>
                    <div className="photo"> 
                        <div className="group-image">
                            {photoProgress > 0 ? photoProgress < 100 ? 
                                <div className="progress-env">
                                    <div className="progress-outer">
                                        <div className="progress-inner" style={{width:photoProgress + '%', height:'100%'}}></div>
                                    </div>
                                </div> : <img src={groupDisplayImg} alt=""/> : <img src={groupDisplayImg} alt=""/>
                            }
                        </div>                        
                        <input
                        type="file"
                        name="photo"
                        id="createGroupPhoto"
                        onChange={e => uploadPhoto(e)}
                        />
                        <label htmlFor="createGroupPhoto">
                            <img src={upload_img} alt="choose file"/>    
                        </label>        
                    </div>


                    <div className="buttons-row">
                        <button type="submit" className={`submit-new-doc ${!isNewNameValid || exsistsError !== undefined ? 'disabled' : ''}`}>Save</button>
                        <button className="abort" onClick={() => handleAbort()}>Discard</button>
                    </div>
                </form>
            </div>              
        </div>
    )
}

export default CreateGroup