import React, { useCallback, useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { db } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';
import logo from '../../assets/images/ichilovlogo.png'; 



const PuduConnection = () => {


  const [error, setError] = useState('');
  const [Robot1Data, setRobot1Data] = useState('')
  const handleDelivery = (field, serial) => {
    const api = db.collection('Companies').doc("Ichilov").collection('Settings').doc(serial);
      console.log(field)
    // }
    api.update({[field]:true}).finally(()=> {
        //window.close();
    })
  };

  // const [alerts, setAlerts] = useState([])

 useEffect(() => {
  const today = new Date()
  const RobotsApi = db.collection('Companies').doc("One").collection('Online');
  RobotsApi.onSnapshot(snapshot => {
    const tempsnapstot = snapshot.docs.filter(doc => doc.id === 'type')
    console.log(tempsnapstot)
    const tmp1 = tempsnapstot[0].data()
    setRobot1Data(tmp1)
    console.log(today.getMinutes())
    console.log(today.getHours())
  }
  )
     return () => {
      
     }
 }, [])

  return (
    console.log(Robot1Data),
    <div className="ichilov-env">
        <div className="logo-amal">
          <img src={logo} alt="logo"/>
        </div>
        <div className="txt-ichilov">Pudu Robots connection:</div>
        <div className="buttons-env-top">
          <div className="confirm"
            style={{opacity: Robot1Data.connection === "true" ? '1': '0.2'}}>Pudu1</div>
        </div>
    </div>
  );
};

export default PuduConnection



// import React, { useCallback, useContext, useState, useEffect } from "react";
// import { withRouter, Redirect } from "react-router";
// import { db } from "../../firebase";
// import { AuthContext } from "./Auth.js";
// import {useStoreState, useStoreActions} from 'easy-peasy';
// import logo from '../../assets/images/ichilovlogo.png'; 



// const PuduConnection = () => {

//   const [error, setError] = useState('');
// //   const handleDelivery = (field, serial) => {
// //     const api = db.collection('Companies').doc("Ichilov").collection('Settings').doc(serial);
// //       console.log(field)
// //     // if(!bool){
// //     //   window.close();
// //     // }
// //     api.update({[field]:true}).finally(()=> {
// //         window.close();
// //     })
// //   };
// //   const handleVolume = (field, serial) => {
// //     const api = db.collection('Companies').doc("Ichilov").collection('Settings').doc(serial);
// //     console.log(field)
// //     api.update({[field]:true}).finally(()=> {
// //       window.close();
// //     })
// //   }

// const [alerts, getAlerts] = useState([])
// const [batteryPer, setBatteryPer] = useState('')
// const [robotConnection, setRobotConnection] = useState('')
// const [lastSeenHour, setLastSeenHour] = useState('')
// const [lastSeenMinute, setLastSeenMinute] = useState('')


// const api = db.collection('Companies').doc("One").collection('Online').doc('type');
// const colapi = db.collection('Companies').doc("One").collection('Online');

//  useEffect(() => {

//         setRobotConnection('false')
//         colapi.onSnapshot(snapshot => {
//           const today = new Date()
//           const tempsnapstot = snapshot.docs[0].data()

//           console.log(tempsnapstot)
//           console.log(tempsnapstot.lastseenhour)
//           console.log(tempsnapstot.laseseenminute)
//           console.log(today.getHours())

//           setLastSeenHour(lastSeenHour)
//           setLastSeenMinute(lastSeenMinute)
//           if(tempsnapstot.lastseenhour == today.getHours()){
//             console.log('same hour')
//             if(tempsnapstot.laseseenminute == today.getMinutes() || tempsnapstot.laseseenminute == (today.getMinutes() -1 )  || tempsnapstot.laseseenminute == (today.getMinutes() +1 )){
//               console.log('same minute')
//               setRobotConnection('true')
      
//             }
//             else {
//               setRobotConnection('false')
//             }
//           }
//           else {
//             setRobotConnection('false')
//           }
          
//         })
    
//       //   colapi.get().then((collection => {
//       //       const temp = {};
//       //       collection.docs.map(doc => {
//       //           temp[doc.id] = doc.data();
//       //       })
//       //       // setBatteryPer(temp.type.battery);
//       //       // console.log(temp.type.batteryPer)
//       //       // setRobotConnection(temp.type.connection);
//       //       setLastSeenMin(temp.type.laseseenminute);
//       //       setLastSeenHour(temp.type.lastseenhour);
//       //   }));
//       //   console.log(today.getMinutes())
//       //   console.log(today.getHours())
//       //   console.log(lastSeenHour)
//       //   console.log(lastSeenMin)
//       //   // console.log(batteryPer)
//       //   // api.update({'connection':'false'}).finally(()=> {
//       //   //     console.log('field')
//       //   // })
//       //   if(lastSeenHour == today.getHours()){
//       //       if(lastSeenMin == today.getMinutes()){
//       //           setRobotConnection('true')
//       //       }
//       //       setRobotConnection('false')
//       //   } 
//       //   else {
//       //       setRobotConnection('false')
//       //     }
//       //   
    
//    }, [])

//   return (
//     <div className="ichilov-env">
//         {/* <div className="logo-amal">
//           <img src={logo} alt="logo"/>
//         </div> */}
//         <div className="txt-ichilov">Pudu Connection:</div>
//         <div className="buttons-env-top">
//           {/* <div className={smsCount < (smsLimit - 5) ? "sms-green" : "sms-red"}>{batteryPer}</div> */}
//           { //Check if message failed
//         (robotConnection === 'true')
//           ? <div className="sms-green">Connect</div> 
//           : <div className="sms-red">Disconnect</div> 
//       }
//         </div>
//     </div>
//   );
// };

// export default PuduConnection