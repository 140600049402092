import { useState, useEffect }from 'react'
const useFormValidation = function(field, initialValue){
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState('');
    // const [arr, setArr] = useState(entitiesArr);

    useEffect(() => {

        const emailRegex = /@/;
        const nameRegex = /[\[\]{}!@#$%^&*;()<>\/\\,]/;
        const phoneRegex = /[\[\]{}!@#$%^&*\s;().<>\/\\,'"+=A-Za-z-]/;
        

        switch(field){
            case 'entity_firstname':  
                if(value !== undefined){                   
                    if(nameRegex.test(value) || value === ''){
                        setError('Ilegal characters');
                        setIsValid(false);
                    }else{
                        setIsValid(true);
                    }
                }else{
                    setIsValid(false);
                }
                break;
            case 'entity_name':/// for group
                // console.log(arr);
                // console.log(value)
                if(value !== undefined){                   
                    if(nameRegex.test(value) || value === '' || value === 'Entities'){
                        if(value === ''){
                            setIsValid(false);
                        }
                        else {
                            setError('Ilegal characters');
                            setIsValid(false);
                        }
                        // console.log('false')
                    // }else if(!arr.every(g => g.entity_name !== value)){
                    //     console.log('exsists')
                    //     setError('Group name already exsists');
                    //     setIsValid(false);
                    }else{
                        // console.log('true')
                        setIsValid(true);
                    }
                }else{
                    // console.log(value)
                    setIsValid('false');
                }
                break;            
            case 'entity_lastname':                    
                if(nameRegex.test(value)){
                    setError('Ilegal characters');
                    setIsValid(false);
                }else{
                    setIsValid(true);
                }
                break;
            case 'entity_phone':                  
                if(value === undefined || value === ''){
                    setIsValid(true);
                    break;
                }else if(phoneRegex.test(value)){
                    setError('Ilegal charecters');
                    setIsValid(false);
                }else if(value.length === 10 || value.length === 9){
                    setIsValid(true);                    
                }else{
                    setIsValid(false);
                    setError('Ilegal phone');
                }
                break;
            case 'entity_email':                  
                if(value === undefined || value === ''){
                    setIsValid(true);
                    // console.log('email undefined');
                    break;
                }else if(emailRegex.test(value)){
                    // console.log('email good ' + value);
                    setIsValid(true);
                }else{
                    // console.log('Ilegal email');
                    setError('Ilegal email');
                    setIsValid(false);
                }
                break;

            default:
                setError('');
        }
    


    },[value])


    return [isValid, setValue, error];
}
export default useFormValidation;