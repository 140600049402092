import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import { db } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';
import logo from '../../assets/images/amallogo.png'; 



const Amal15 = () => {

  const [error, setError] = useState('');
  const api = db.collection('Companies').doc("Amal").collection('Settings');
  const handleDelivery = (text) => {
    // if(!bool){
    //   window.close();
    // }
    api.doc(text).set({bool:true}).then(() => {
      window.close();
    })
  };

  return (
    <div className="delivery-env">
        <div className="logo-amal">
          <img src={logo} alt="logo"/>
        </div>
        <div className="txt-amal">:הזמן את טמי אל</div>
        <div className="buttons-env-top">
          <div className="confirm" onClick={() => handleDelivery('kitchen')}>מטבח</div>
          <div className="cancel" onClick={() => handleDelivery('nurse')}>חדר אחות</div>
        </div>
        <div className="buttons-env-bottom">
          <div className="confirm" onClick={() => handleDelivery('reception')}>קבלה</div>
          <div className="cancel" onClick={() => handleDelivery('doctor')}>רופא</div>
        </div>
    </div>
  );
};

export default Amal15