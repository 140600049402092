import React, {useEffect, useState} from "react";
import { storage, db } from "../../firebase";
import useValidation from '../hooks/FormValidation';
import upload_img from '../../assets/images/upload.svg';
import default_group_img from '../../assets/images/default_group.svg';

const UpdateGroup = (props) =>{
    const [newGroupName, setNewGroupName] = useState(props.groupEdit.group.entity_name);
    const [newGroupType, setNewGroupType] = useState(props.groupEdit.group.entity_groupType);
    const [isNewNameValid, setIsNewNameValid, newNameError] = useValidation('entity_name',newGroupName); 
    const [exsistsError, setExsistsError] = useState();

    //photo
    const [photoProgress, setPhotoProgress] = useState(0);
    const image_id = props.groupEdit.group.entity_pic ? props.groupEdit.group.entity_pic.split('%2F')[1].split('?')[0] : undefined;
    const [tempPicName, setTempPicName] = useState();
    const [newPicLink, setNewPicLink] = useState();
    const [groupDisplayImg, setGroupDisplayImg] = useState(props.groupEdit.group.entity_pic ? props.groupEdit.group.entity_pic : default_group_img);

    useEffect(() => {
        const rand = Math.floor(Math.random() * (1000000 - 1 + 1) ) + 1;
        setTempPicName('/group_pic' + rand)
    },[])

    const uploadPhoto = (e) =>{
        if(!e.target.files[0]){return;}
        if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){return;}
        
        const photo = e.target.files[0];
        const fileName = props.company + tempPicName;
        const uploadTask = storage.ref(fileName).put(photo);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(props.company)
            .child(tempPicName) 
            .getDownloadURL().then(link => {
                setNewPicLink(link);
                setGroupDisplayImg(link);
                // props.api.doc(user.id).update({entity_pic: link}).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    const killPopup = () =>{        
        props.changeGroupEdit({isEdit: false, group: {}, state: '', id:''});
        props.changeIsGroupUpdate(false);
    }

    const editGroup = (event) => {
        event.preventDefault();

        if(!isNewNameValid || exsistsError !== undefined){
            return
        }
        const linkedUsers = props.users.filter((user) =>{
            return user.entity_group.includes(props.groupEdit.group.entity_name);
        });
        const batch = db.batch();
        for(var i = 0; i < linkedUsers.length; i++){
            for(var j = 0 ; j < linkedUsers[i].entity_group.length; j++){
                if(linkedUsers[i].entity_group[j] === props.groupEdit.group.entity_name){
                    linkedUsers[i].entity_group[j] = newGroupName;
                }
            }
            batch.update(props.api.doc(linkedUsers[i].id), {entity_group: linkedUsers[i].entity_group});
        }
        batch.update(props.api.doc(props.groupEdit.group.id), 
        {
            entity_name: newGroupName,
            entity_groupType: newGroupType ? newGroupType : props.groupEdit.group.entity_groupType ? props.groupEdit.group.entity_groupType : 'location',
            entity_pic: newPicLink ? newPicLink : props.groupEdit.group.entity_pic ? props.groupEdit.group.entity_pic : ''
        });
        batch.commit().then(() => {
            if(newPicLink && props.groupEdit.group.entity_pic){
                console.log(newPicLink)
                storage.ref().child(props.company + '/' + image_id).delete().catch(e => console.log(e));
            }
            
            const tmpGroup = props.groupEdit.group;
            tmpGroup.entity_name = newGroupName;
            tmpGroup.entity_groupType = newGroupType;
            tmpGroup.entity_pic = newPicLink ? newPicLink : props.groupEdit.group.entity_pic;
            console.log(tmpGroup)
            props.changeGroupInView(tmpGroup);
        })
        .catch((error) => {
            console.log(error);
        })        
        .finally(() =>{
            killPopup();
        });  
    }
  
    const validateExistance = (name) =>{
        if(isNewNameValid){
            // console.log('valid')
            if(!props.groups.every(group => group.entity_name !== name)){
                // console.log('ex')
                setExsistsError('Group name alredy exists')
            }else{
                setExsistsError();
            }
        }        
    }

    const handleAbort = () =>{
        if(!newPicLink){
            killPopup();
            return;
        }
        storage.ref().child(props.company + '/' + tempPicName).delete().then(() => {
            killPopup();
        }).catch((error) => {
            console.log(error);
        })        
    }

    return (
        <div className="group-popup-env">
            <div className="group-popup" onClick={() => {handleAbort()}}></div>
            <div className="popup">
                <div className="header-large">Edit group</div>
                <div className="text">Group name</div>
                <form onSubmit={editGroup} className="new-doc-form" autoComplete="off">
                    <input 
                        autoComplete="new-password"
                        type="text" 
                        name="newGroup" 
                        placeholder="New"
                        maxLength="255"
                        onChange={e => {setNewGroupName(e.target.value); setIsNewNameValid(e.target.value); validateExistance(e.target.value)}}
                        value={newGroupName}
                        className="new-doc"
                    />
                    <div className="error-env">
                        <div className={isNewNameValid ? "invalid-feedback": "invalid-feedback on"}>{newNameError}</div>
                        <div className={!exsistsError ? "invalid-feedback": "invalid-feedback on"}>{exsistsError}</div>
                    </div>
                    <div className="text-type">Group type</div>
                    <select className="react-select" style={
                        {
                            marginRight:'10px',
                            height: '44px',
                            width: '178px',
                            border: '1px solid #D0D9ED',
                            padding:'1px 2px 1px 8px',
                            borderRadius: '5px'
                        }
                    }
                    value={newGroupType}
                    onChange={e => setNewGroupType(e.target.value)}>
                    <option value="location">Location</option>
                    <option value="sale">Sale</option>    
                    </select>     
                    {/* <input 
                        autoComplete="new-password"
                        type="text" name="newGroup"
                        maxLength="255"
                        placeholder={'Your group type'}
                        onChange={e => {setNewGroupType(e.target.value)}}
                        value={newGroupType}
                        className="new-doc"                        
                    />             */}
                    <div className="header">Group image</div>
                    <div className="photo">  
                        <div className="group-image">
                            {photoProgress > 0 ? photoProgress < 100 ? 
                                <div className="progress-env">
                                    <div className="progress-outer">
                                        <div className="progress-inner" style={{width:photoProgress + '%', height:'100%'}}></div>
                                    </div>
                                </div> : <img src={groupDisplayImg} alt=""/> : <img src={groupDisplayImg} alt=""/>
                            }
                        </div>                       
                        <input
                        type="file"
                        name="photo"
                        id="updateGroupPhoto"
                        onChange={e => uploadPhoto(e)}
                        />
                        <label htmlFor="updateGroupPhoto">
                            <img src={upload_img} alt="choose file"/>    
                        </label>                         
                    </div>                                    
                    <div className="buttons-row">
                        <button type="submit" className={`submit-new-doc ${!isNewNameValid || exsistsError !== undefined ? 'disabled' : ''}`}>Save</button>
                        <button className="abort" onClick={() => handleAbort()}>Discard</button>
                    </div>
                </form>
            </div>                                     
        </div>
    )    
}
export default UpdateGroup