import React, { useEffect, useState } from "react"
import { firebaseApp } from "../../firebase";
import Loading from "../loading/Loading";

export const AuthContext = React.createContext();

export const AuthProvider =({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    // const [isAuth, setIsAuth] = useState();

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged((user) => {            
            setCurrentUser(user);
            setPending(false);
        });
    },[]);

    if(pending){
        return <Loading/>
    }

    return (
        <AuthContext.Provider value={{currentUser}} >
            {children}
        </AuthContext.Provider>
    )

}