import React from "react";

const UserButtons = props => { 
    let user = props.user;
    return(
      <div className="edit-buttons">
        <div className="edit" onClick={() => props.changeIsEditUser(user , true)}></div>
        {
          props.groupInView.entity_name === 'Entities' 
          ? 
          <div className="delete" onClick={() => props.changeIsDeleteUser(user , true)}></div>
          :
          <div className="remove" onClick={() => props.changeIsRemoveUser(user, true)}></div>
        }
      </div>
    )
}
export default UserButtons;