import * as firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth'


const firebaseApp = firebase.initializeApp({
  
  apiKey: "AIzaSyCGr3ghAqf34XwTeQCICmlWXpa0TKoOmH0",
  authDomain: "toyota-cd2b6.firebaseapp.com",
  databaseURL: "https://toyota-cd2b6.firebaseio.com",
  projectId: "toyota-cd2b6",
  storageBucket: "toyota-cd2b6.appspot.com",
  messagingSenderId: "509395250467",
  appId: "1:509395250467:web:dd599f235003a4cde17096"
  
});
const storage = firebaseApp.storage();
const db = firebaseApp.firestore();

export {storage};
export {db};
export {firebaseApp};

