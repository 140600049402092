import React, {useEffect, useState, useCallback} from 'react';
import { firebaseApp } from "../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';
import Users from "./users/Users";
import Menu from "./groups/Menu";
import DeleteGroup from "./groups/DeleteGroup.jsx";
import UpdateGroup from "./groups/UpdateGroup";
import CreateGroup from "./groups/CreateGroup";
import Settings from "./Settings/Settings";
import RobotAssign from "./robots/RobotAssign";
import entitiesToGroup from "./groups/EntitiesToGroup";
import { db } from "../firebase";
import EntitiesToGroup from './groups/EntitiesToGroup';
import RestSettings from './Settings/RestSettings';
// import axios from 'axios'
import ReactDOM from "react-dom";
import PortalModel from './utilities/PortalModel';

const Home = () => {   
    const routeEmail = useStoreState(store => store.routeEmail);
    // const [entities, setEntities] = useState([])
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [api, setApi] = useState('');
    const [mediaApi, setMediaApi] = useState('');
    const [settingsApi, setSettingsApi] = useState('');
    const [robotsApi, setRobotsApi] = useState('');
    const [company, setCompany] = useState('');
    const [adminName, setAdminName] = useState();
    const [settingsPremission, setSettingsPremission] = useState(false);
    const [restPremission, setRestPremission] = useState(false);

    //groups
    const [groupInView, setGroupInView] = useState({entity_name: 'Entities'});
    const changeGroupInView = val => setGroupInView(val);

    const [groupEdit, setGroupEdit] = useState({isEdit: false, group: {}, state: ''})
    
    const [isGroupUpdate, setIsGroupUpdate] = useState(false);
    const changeIsGroupUpdate = (val) => {setIsGroupUpdate(val)};
    
    const [isGroupDelete, setIsGroupDelete] = useState(false);
    const changeIsGroupDelete = (val) =>{ setIsGroupDelete(val)}
    
    const changeGroupEdit = (group, type, isEdit) => {
        setGroupEdit({group: group, state: type, isEdit});
        // console.log(groupEdit)
        if(type === 'delete'){
            setIsGroupDelete(isEdit);
        }else if(type === 'edit'){
            setIsGroupUpdate(isEdit);
        }
    }

    const isEntitiesToGroup = useStoreState(state => state.isEntitiesToGroup);
    
    const [isGroupCreate, setIsGroupCreate] = useState(false);
    const changeIsGroupCreate = (val) =>{
        setIsGroupCreate(val);
    }

    const isSettings = useStoreState(store => store.isSettings);
    const isRest = useStoreState(store => store.isRest);

    const pageViewIndex = useStoreState(store => store.pageViewIndex);


    const setPhonePrefix = useStoreActions(actions => actions.setPhonePrefix);
    const setSmsCount = useStoreActions(actions => actions.setSmsCount)
    const setSmsLimit = useStoreActions(actions => actions.setSmsLimit)

    const setLanguages = useStoreActions(actions => actions.setLanguages)

    const [isCollapseSignOut, setIsCollapseSignOut] = useState(false);
    const changeIsCollapseSignOut = useCallback(() => {
        setIsCollapseSignOut(v => !v);
    }, []);

    //robots
    const [robots, setRobots] = useState([]);

    useEffect(() => {
        // console.log(db.collection('Companies').where("admin_email", "==", routeEmail).get({"admin_name"}))

        db.collection('Companies').where("admin_email", "==", routeEmail)
        .onSnapshot(function(querySnapshot) {
            // console.log(querySnapshot)
            setAdminName(querySnapshot.docs[0].get('admin_name'));
            setSettingsPremission(querySnapshot.docs[0].get('isSettings'));
            setRestPremission(querySnapshot.docs[0].get('isRest'));
            setPhonePrefix(querySnapshot.docs[0].get('phone_prefix'))
            setSmsCount(querySnapshot.docs[0].get('smscount'))
            setSmsLimit(querySnapshot.docs[0].get('smslimit'))                        
            const comp = [];
            querySnapshot.docs.map(doc => {
                return comp.push(doc.id);
            });
            if(comp[0] !== undefined){
                setCompany(comp[0])
                const api = db.collection('Companies').doc(comp[0]).collection('Entities');
                setMediaApi(db.collection('Companies').doc(comp[0]).collection('Media')); 
                setSettingsApi(db.collection('Companies').doc(comp[0]).collection('Settings')); 
                setRobotsApi(db.collection('Companies').doc(comp[0]).collection('Robots'));  
                db.collection('Companies').doc('GreetoSettings').collection('Languages').onSnapshot(snap => {
                    const langArr = [];
                    snap.docs.map(doc => {             
                        return langArr.push(
                            {
                                id:doc.id,
                                ...doc.data()                                    
                            }
                        );
                    })
                    setLanguages(langArr)
                })               
                setApi(api);
                api.onSnapshot(snapshot =>{
                    const entitiesArr = [];
                    snapshot.docs.map(doc => {             
                        return entitiesArr.push(
                                {
                                    id:doc.id,
                                    ...doc.data()                                    
                                }
                            );
                    });
                    // setEntities(entitiesArr);/
                    // separate groups from entities
                    // const tempGroups = [{entity_name: 'Entities'}];
                    const tempGroups = [];
                    const tempUsers = [];
                    entitiesArr.map((entity) => {
                        if(entity.entity_type === 'group'){
                            return tempGroups.push(entity);
                        }else if(entity.entity_type === 'user'){
                            return tempUsers.push(entity);
                        }
                        return false;
                    })
                    // if(tempUsers[0].entity_name){
                    //     console.log(tempUsers[0].entity_name)
                    //     setIsUserCleanup(true);
                    // }
                    setGroups(tempGroups);
                    // remove broken users
                    // const badUsers = tempUsers.filter(user => user.entity_name === undefined);
                    // const batch = db.batch();
                    // badUsers.map(user => batch.delete(api.doc(user.id)));
                    // batch.commit().catch(e => console.log(e));

                    setUsers(tempUsers);
                });
                db.collection('Companies').doc(comp[0]).collection('Locations').onSnapshot(s => {
                    const locationsArr = [];
                    s.docs.map(doc => {             
                        return locationsArr.push(
                                {
                                    id:doc.id,
                                    ...doc.data()                                    
                                }
                            );
                    });
                    setLocations(locationsArr);
                });
                db.collection('Companies').doc(comp[0]).collection('Robots').onSnapshot(snapshot => {
                    setRobots(snapshot.docs.map(doc => doc.id));
                });
            }
        })
    },[routeEmail]);

    useEffect(() => {
        // delete broken users // do not change!!!!!!!
        db.collection('Companies').where("admin_email", "==", routeEmail).get().then((snap) =>{
            db.collection('Companies').doc(snap.docs[0].id).collection('Entities').get().then((snapshot) => {
                const batch = db.batch();
                snapshot.forEach(doc => {
                    const data = doc.data()
                    if(data.entity_type === 'user' && data.entity_firstname === undefined){
                        console.log(data);
                        batch.delete(db.collection('Companies').doc(snap.docs[0].id).collection('Entities').doc(doc.id));
                    }else if(data.entity_type === 'group' && data.entity_name === undefined){
                        batch.delete(db.collection('Companies').doc(snap.docs[0].id).collection('Entities').doc(doc.id));
                    }                    
                });
                batch.commit().catch(e => console.log(e));
            }).catch(e => console.log(e))

        }).catch(e => {
            console.log(e);
            const portal = document.createElement('div')
            portal.id = 'portal-modal'
            document.body.appendChild(portal)
            ReactDOM.render(<PortalModel/>, document.getElementById('portal-modal'))
        })
    },[])

    function switchHeaderName(){
        switch(pageViewIndex){
            case 0:
                return 'Entities';
            case 1:
                return 'Settings';
            case 2:
                return 'Resturants';
            }
        }

    function swithcViewPage() {
        switch (pageViewIndex) {
          case 0:
            return (
                <Users api={api} 
                users={users} 
                groups={groups} 
                locations={locations} 
                groupInView={groupInView}
                changeGroupInView={changeGroupInView}
                changeGroupEdit={changeGroupEdit}
                mediaApi={mediaApi}
                company={company}
                robotsApi={robotsApi}
                />
            )
            case 1:
                return (
                    <Settings 
                    // isSettings={isSettings} 
                    company={company} 
                    groups={groups}
                    users={users}
                    // mediaApi={mediaApi}
                    changeGroupInView={changeGroupInView}
                    // settingsApi={settingsApi}
                    locations={locations} 
                    robotsApi={robotsApi}
                    robots={robots}
                    entitiesApi={api}
                    // changeIsSettings={changeIsSettings}
                    />
                )
            case 2:
                return (
                    <RestSettings
                    // isSettings={isSettings} 
                    company={company} 
                    groups={groups}
                    users={users}
                    // mediaApi={mediaApi}
                    changeGroupInView={changeGroupInView}
                    // settingsApi={settingsApi}
                    locations={locations} 
                    robotsApi={robotsApi}
                    robots={robots}
                    entitiesApi={api}
                    // changeIsSettings={changeIsSettings}
                    />
                )
        }
    }


    return(
        <>
        {/* {
        groupEdit && <GroupPopup api={api} 
                groups={groups} 
                users={users} 
                groupEdit={groupEdit} 
                changeGroupEdit={changeGroupEdit}
                changeGroupInView={changeGroupInView} 
                // newGroupName = {newGroupName}
                // changeNewGroupName = {changeNewGroupName}
            />
        } */}
        {
            isGroupCreate && <CreateGroup api={api}
                company={company}
                groups={groups} 
                changeIsGroupCreate={changeIsGroupCreate}
                changeGroupInView={changeGroupInView}
                users={users}
            />
        }
        {
            isGroupDelete && <DeleteGroup api={api}
                users={users} 
                groups={groups}
                groupEdit={groupEdit} 
                company={company}
                changeGroupEdit={changeGroupEdit}
                changeIsGroupDelete={changeIsGroupDelete}
                changeGroupInView={changeGroupInView} 
            />
        }
        {
            isGroupUpdate && <UpdateGroup api={api}
                groupEdit={groupEdit} 
                users={users}
                groups={groups}
                company={company}
                changeGroupEdit={changeGroupEdit}
                changeIsGroupUpdate={changeIsGroupUpdate}
                changeGroupInView={changeGroupInView} 
            />
        }

        <Menu groups={groups} 
            changeGroupInView={changeGroupInView} 
            changeGroupEdit={changeGroupEdit} 
            groupInView={groupInView}
            changeIsGroupCreate={changeIsGroupCreate}
            settingsPremission={settingsPremission}
            restPremission={restPremission}
            robots={robots}
            users={users}
            />

        <div className="main-content">
            <div className="main-header">
                <div className="text">{switchHeaderName()}</div>
                <div className={`admin-details ${isCollapseSignOut ? 'in' : 'out'}`} onClick={() => changeIsCollapseSignOut()}>
                    <div className="admin">
                        <div className="admin-image"></div>
                        <div className="admin-name">{adminName}</div> 
                        {/* <div className={`arrow ${isCollapseSignOut ? 'up' : 'down'}`}></div> */}
                    </div>                   
                    <div className="logout" onClick={() => firebaseApp.auth().signOut()}>Log Out</div>
                </div>                
            </div>
        {swithcViewPage()}
    
        {
            groupInView.entity_name != undefined && groupInView.robots !== undefined && 
            <RobotAssign api={api}
            groupInView={groupInView}
            robotsApi={robotsApi}
            />
        } 
        {
            isEntitiesToGroup && <EntitiesToGroup 
                groups={groups}
                api={api}
                groupInView={groupInView}
                users={users}            
            />
        }
        </div>  
        </>
    )
}
export default Home;