import React, { useState, useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DeleteUser from "./DeleteUser";
import UserList from "./UserList";
import UpdateUser from "./UpdateUser";
import CreateUser from "./CreateUser";
import usePrevious from "../hooks/usePrevios";
import DeleteUsers from "./DeleteUsers";
import RemoveFromGroup from "./RemoveFromGroup";
import RemoveUser from "./RemoveUser";
import { db } from "../../firebase";
import default_group_img from "../../assets/images/default_group.svg";


const Users = (props) => {
  const [users, setUsers] = useState();
  const changeUsers = val => setUsers(val);

  const [searchKey, setSearchKey] = useState('');
  const changeSearchKey = val => setSearchKey(val);

  const [deleteUser, setDeleteUser] = useState();
  const [isDeleteUser, setIsDeleteUser] = useState();
  const [editUser, setEditUser] = useState();
  const [isEditUser, setIsEditUser] = useState();
  const [removeUser, setRemoveUser] = useState();
  const [isRemoveUser, setIsRemoveUser] = useState();

  const [isCreateUser, setIsCreateUser] = useState(false);
  const changeIsCreateUser = val => setIsCreateUser(val);

  const [isCollapseAdd, setIsCollapseAdd] = useState(false);
  const changeIsCollapseAdd = useCallback(() => {
    setIsCollapseAdd(v => !v);
  }, []);

  const [isUserChecked, setIsUserChecked] = useState(false);

  const addToGroup = usePrevious(props.groupInView.entity_name); // 

  const [isDeleteUsersPopup, setIsDeleteUsersPopup] = useState(false);
  const changeIsDeleteUserPopup = val => setIsDeleteUsersPopup(val);

  const setIsEntitiesToGroup = useStoreActions(actions => actions.setIsEntitiesToGroup);

  const isRemoveFromGroup = useStoreState(state => state.isRemoveFromGroup);
  const setIsRemoveFromGroup = useStoreActions(actions => actions.setIsRemoveFromGroup);
  
  useEffect(() => {
      let tmpUsers = props.users;

      if(props.groupInView.entity_name !== 'Entities'){
        if(addToGroup !== 'Entities'){
          
        }
        tmpUsers = props.users.filter((user) => {
            if(user.entity_group){
              return user.entity_group.includes(props.groupInView.entity_name);
            }else{
              return false;
            }
        });
      }
      if(searchKey !== ''){
        tmpUsers = tmpUsers.filter((user) => {
          if(user.entity_firstname){
            if(user.entity_firstname.includes(searchKey) || user.entity_lastname.includes(searchKey)){
              return true;
            }
            return false;            
          }else return true;
        })
      }
      tmpUsers = tmpUsers.map((user) => {
        return {...user, 
                htmlId: user.id + 'check', 
                isChecked: false 
              }
      });
      setUsers([{allChecked: false}, tmpUsers]); 
      setIsUserChecked(!tmpUsers.every(item => !item.isChecked))
  },[props, searchKey]);

  useEffect(()=>{  // reset collapse on group change
    setIsCollapseAdd();
  },[props.groupInView])


//   useEffect(() => {
//     console.log(users)
//     const badUsers = users.filter(user => user.entity_name === undefined);
//     const batch = db.batch();
//     badUsers.map(user => batch.delete(props.api.doc(user.id)));
//     batch.commit().catch(e => console.log(e));
// },[])


  const changeIsEditUser = (user, isEdit) => {// set user edit state
    setEditUser(user);
    setIsEditUser(isEdit);
  }

  const changeIsDeleteUser = (user, isDelete) => { // set user delete state
      setDeleteUser(user);
      setIsDeleteUser(isDelete);
  }

  const changeIsRemoveUser = (user, isRemove) => {
    setRemoveUser(user);
    setIsRemoveUser(isRemove);
  }

  const handleChecked = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    // let items = [];
    setUsers(prevState => {
      let allChecked = prevState[0];
      let list = prevState[1];
      if (itemName === "checkAll") {
        allChecked = checked;
        for(let i = 0; i < list.length; i++){
          list[i].isChecked = checked;
        }
      }else {
        for(let i = 0; i < list.length; i++){
          if(list[i].htmlId === itemName){
            list[i].isChecked = checked;
          }
        }     
      }
      setIsUserChecked(!list.every(item => !item.isChecked))
      allChecked = list.every(item => item.isChecked);
      return [ {allChecked: allChecked}, list ];
    });
  };

  // const deleteChecked = () =>{
  //   const usersToDelete = users[1].filter((user) => user.isChecked);
  //   const batch = db.batch();
  //   usersToDelete.map(user => batch.delete(props.api.doc(user.id)));
  //   batch.commit()
  //   .catch((error) => console.log(error))
  //   .finally()
  // }

  const entitiesToGroup = () =>{
    setIsEntitiesToGroup(true);
    // props.changeGroupInView('Entities');
    // console.log(props.groupInView)
    // console.log(addToGroup)
    changeIsCollapseAdd()
  }

  const newEntityToGroup = () =>{
    setIsCreateUser(true)
    changeIsCollapseAdd()
  }

  const handleGroupEdit = (type) => { 
    const group = props.groups.filter(g => g.entity_name === props.groupInView.entity_name);
    props.changeGroupEdit(...group, type, true)
  }

  return (
<div className="dashboard">
  <div className="dash-content">
    <div className="dashboard-top">
        <div className="top-row">
          <div style={{display:'flex'}}>
            {props.groupInView.entity_name !== 'Entities' && <div className="group-image">           
                <img src={props.groupInView.entity_pic ? props.groupInView.entity_pic : default_group_img}/>
              </div>}
            <div className="group">{props.groupInView.entity_name}</div>
          </div>
          {
            !isUserChecked && props.groupInView.entity_name !== 'Entities' &&
            props.groupInView.entity_name !== 'Songs' &&
            props.groupInView.entity_name !== 'Tables' &&
            props.groupInView.entity_name !== 'Specials' &&
             <div className="large-group-edit">
              <div className="edit" onClick={() => handleGroupEdit('edit')}></div>
              <div className="delete"  onClick={() => handleGroupEdit('delete')}></div>
            </div>
          }
        </div>
        <div className="top-buttons">
          {/* {props.groupInView.entity_name === "Entities" && 
            <div className="add-to-group" onClick={() => setIsCreateUser(true)}>+ CREATE NEW ENTITY</div>
          } */}
          {props.groupInView.entity_name !== "Entities" && 
          //   <div className="expand">
          //     <div className="expand-top" onClick={() => newEntityToGroup()}>
          //       <div className="text">+ NEW ENTITY TO GROUP</div>
          //     </div>
          // </div>

              <div className="expand">
                <div className="expand-top" onClick={() => changeIsCollapseAdd()}>
                  <div style={{display:'flex',alignItems:'center',margin:'auto'}}>
                    <div className="text">+ ADD ENTITY TO GROUP</div>
                    <div className={`arrow ${isCollapseAdd ? 'up' : 'down'}`}></div>
                  </div>
                </div>
                <div className={`expander ${isCollapseAdd ? 'in' : 'out'}`}>
                  <div className="inner" onClick={() => entitiesToGroup()}>From Entities</div>
                  <div className="inner" onClick={() => newEntityToGroup()}>New Entity</div>
                </div>
              </div>
          }
          {
            isUserChecked ? props.groupInView.entity_name === 'Entities' ?
            <div className="delete-selected" onClick={() => setIsDeleteUsersPopup(true)}>
              <div className="delete-image"></div>
              Delete selected
            </div> : <div className="delete-selected" onClick={() => setIsRemoveFromGroup(true)}>Remove from group</div> : ''
          }
        </div>
    </div>          
      <UserList searchKey={searchKey} 
      users={users}
      changeSearchKey={changeSearchKey}
      changeIsDeleteUser={changeIsDeleteUser}
      changeIsEditUser={changeIsEditUser}
      changeIsRemoveUser={changeIsRemoveUser}
      handleChecked={handleChecked}
      groupInView={props.groupInView}
      // changeUsers={changeUsers}
      />
      { 
        isDeleteUser && <DeleteUser user={deleteUser}
        changeIsDeleteUser={changeIsDeleteUser}
        api={props.api}
        company={props.company}
      />
      }
      {
        isDeleteUsersPopup && <DeleteUsers users={users}
          changeIsDeleteUserPopup={changeIsDeleteUserPopup}
          api={props.api}
          company={props.company}
            />
      }      
      {
        isEditUser && <UpdateUser user={editUser}
          changeIsEditUser={changeIsEditUser}
          api={props.api}
          robotsApi={props.robotsApi}
          locationsProp={props.locations}
          groups={props.groups}
          company={props.company}
        />
      }
      { isCreateUser && <CreateUser
        changeIsCreateUser={changeIsCreateUser}
        api={props.api}
        robotsApi={props.robotsApi}
        originalLocations={props.locations}
        groups={props.groups}
        addToGroup={addToGroup}
        mediaApi={props.mediaApi}
        company={props.company}
      />}
      {
        isRemoveUser && <RemoveUser 
          groups={props.groups}
          changeIsRemoveUser={changeIsRemoveUser}
          api={props.api}
          user={removeUser}
          groupInView={props.groupInView}
        />
      }
      {
        isRemoveFromGroup && <RemoveFromGroup 
          groups={props.groups}
          users={users}
          api={props.api}  
          groupInView={props.groupInView}        
        />
      }
  </div>
</div>  

  )
}

export default Users;
