import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import { db } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';
import logo from '../../assets/images/title.svg'; 



const Delivery = () => {

  const [error, setError] = useState('');
  const api = db.collection('Companies').doc("Fotona").collection('Robots').doc("00120223191").collection("Settings");
  const handleDelivery = (bool) => {
    if(!bool){
      window.close();
    }
    api.doc('driveDelivery').set({bool:bool}).then(() => {
      window.close();
    })
  };

  return (
    <div className="delivery-env">
        <div className="logo">
          <img src={logo} alt="logo"/>
        </div>
        <div className="txt">I approve the temi delivery</div>
        <div className="buttons-env">
          <div className="confirm" onClick={() => handleDelivery(true)}>Confirm</div>
          <div className="cancel" onClick={() => handleDelivery(false)}>Cancel</div>
        </div>
    </div>
  );
};

export default Delivery