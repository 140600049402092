import React from 'react'
import remove from '../../assets/images/remove.svg'

const InputClear = ({setter}) => {

    function clear(){        
        setter('')
    }

    return(
        <div onClick={() => clear()}
            style={{
                position: 'absolute',
                top: '12px',
                right: '10px',
                fontSize: '9px',
                // border: '1px solid',
                borderRadius: '50%',
                height: '12px',
                width: '12px',
                lineHeight: '9px',
                textAlign: 'center',
                cursor:'pointer'
            }}
        ><img src={remove}
            style={{
                height:'100%'
            }}
        /></div>
    )
}

export default InputClear