import React, {useEffect, useState }from "react";
import MultiSelect from "react-multi-select-component";

const RobotAssign = ({api, groupInView, robotsApi}) => {
    const [robots, setRobots] = useState([]);
    const [robotsValues, setRobotsValues] = useState([]);
    const [selectedtRobots, setSelectedRobots] = useState([]);

    useEffect(() => {
        const unsubscribe = robotsApi.onSnapshot(snapshot => {
            const tempArr = []
            const values = []
            snapshot.docs.map(doc => {
                tempArr.push(doc.id)
            })
            setRobots(tempArr);            
            setRobotsValues(tempArr.map((robot) => {return {label: robot, value: robot}})); // format selections
        });
        return () => {
            unsubscribe();
        }
    }, []);

    useEffect(() => {
        setSelectedRobots(groupInView.robots.map(r => {return {label: r, value: r}}));
    },[groupInView]);

    const submit = (e) => {
        e.preventDefault();
        api.doc(groupInView.id).update({
            robots:selectedtRobots.map(r => r.value)
        }).then(() => {

        }).catch((error) => {
            console.log(error);
        })
    }

    return(
        <div className="robot-assign">            
            <form onSubmit={submit}>
            <div style={{display:'flex', alignItems:'center'}}>
                <div className="header">Assigned robot</div>
                <MultiSelect 
                    options={robotsValues}
                    value={selectedtRobots}
                    onChange={setSelectedRobots}
                    labelledBy={""}
                />
            </div>
                <div className="button-env">                        
                    <button type="submit" className="submit">Send</button> 
                </div>
            </form>
        </div>
    )
}
export default RobotAssign;