import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import { firebaseApp } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';



const Login = ({ history }) => {

  const [error, setError] = useState('');
  const isResetPassword = useStoreState(store => store.isResetPassword);
  const setIsResetPassword = useStoreActions(actions => actions.setIsResetPassword);
  var API019SMS_URL = "https://019sms.co.il/api"
  var API019SMS_PORT = "8090"
  var API019SMS_USERNAME = "onerobotix99"
  var API019SMS_PASSWORD = "UN5g]u7O"
  var API019SMS_FROMNAME = "Greeto"
  var request = require("request");
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await firebaseApp
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then(function(firebaseApp) {
            // Success 
            let call = event.activity;
            var username = API019SMS_USERNAME;
            var password2 = API019SMS_PASSWORD;
            var source = API019SMS_FROMNAME;
            var phone = "0528087699";
            var message = "בוצעה התחברות למערכת ממשתמש " + email.value;
            sendSms(username, password2, source, phone, message);
            })
       .catch(function(error) {
            // Error Handling
            if(email.value!='' || password.value!=''){
              let call = event.activity;
              var username = API019SMS_USERNAME;
              var password2 = API019SMS_PASSWORD;
              var source = API019SMS_FROMNAME;
              var phone = "0528087699";
              var message = "בוצע נסיון לא מוצלח להתחברות למערכת ממשתמש " + email.value;
              sendSms(username, password2, source, phone, message);
            }
            
          });
          
        history.push("/");
      } catch (error) {
        // alert(error);
        setError(error.message);
        setTimeout(function(){
          setError('');
        }, 1500)
      }
    },
    [history]
  );

  function sendSms(username, password, source, phone, message){
    var body = `<sms><user><username>${username}</username><password>${password}</password></user><source>${source}</source><destinations><phone>${phone}</phone></destinations><message>${message}</message><response>0</response></sms>`;    
    request.post({
        url: API019SMS_URL,
        port: API019SMS_PORT,
        method: "POST",
        headers: {
            'Content-Type': 'application/xml',
        },
        body: body
    }, function(error, response, body) {
        console.log(response.statusCode);
        console.log(body);
        console.log(error);
    });
}

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  if(isResetPassword){
    // console.log("in reset");
    return <Redirect to="/reset"/>
  }

  return (
    <div className="login-env">
      <div className="login-box">
        <div className="login-image"></div>
        <div className="form-env">
          <div className="header">Log in.</div>
          <div className="txt">Connect, and customize your company robot-app</div>
          <form onSubmit={handleLogin}>
            <label>
              <div className="label">Email</div>
              <input name="email" type="email" placeholder="name@domain.com" />
            </label>
            <label>
              <div className="label">Password</div>
              <input name="password" type="password" placeholder="given to you by onerobotix" />
              <div className="error">{error}</div>
            </label>            
            <div className="button-env">
              <button className="login" type="submit">Log in</button>
            </div>
            <div className="to-reset-password" onClick={() => setIsResetPassword(true)}>forgot your password?</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);