import React from 'react';

const RemoveUser = ({groups, changeIsRemoveUser, api, user, groupInView}) =>{

    const removeUser = () => {
        const entityGroups = user.entity_group.filter(name => {
            return name !== groupInView.entity_name;
        })
        const fullSelectedGroupsArr = groups.filter(fullGroup => {
            return entityGroups.includes(fullGroup.entity_name)
        })
        //meetingHierarchy---------------------------------------------------------
        let meetingHierarchyRobotsSet = new Set()
        for(let i = 0;i < entityGroups.length; i++){
            user.meeting_hierarchy.forEach(robotId => {
                if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                    meetingHierarchyRobotsSet.add(robotId)
                }
            })            
        }
        const finalMeetingHierarchyRobotsArr = [...meetingHierarchyRobotsSet]
        //deliveryHierarchy---------------------------------------------------------
        let deliveryHierarchyRobotsSet = new Set()
        for(let i = 0;i < fullSelectedGroupsArr.length; i++){
            user.delivery_hierarchy.forEach(robotId => {
                if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                    deliveryHierarchyRobotsSet.add(robotId)
                }
            })            
        }
        const finalDeliveryHierarchyRobotsArr = [...deliveryHierarchyRobotsSet]
        /////-------------------------------------------------------------------------
        api.doc(user.id).update({
            entity_group:entityGroups,
            meeting_hierarchy:finalMeetingHierarchyRobotsArr,
            delivery_hierarchy:finalDeliveryHierarchyRobotsArr
        }).then(() => {
            changeIsRemoveUser(user, false);
        }).catch(error => {
            console.log(error);
        })
    }

    return(
    <div className="group-popup-env">
        <div className="group-popup" onClick={() => {changeIsRemoveUser(user , false)}}></div>
        <div className="popup delete">
            <div className="header-large">Remove Entity</div>
            <div className="text">Are you sure you want to remove the entity?</div>
            <div className="buttons-row">
            <button className="submit-new-doc" onClick={() => {removeUser()}}>Save</button> 
            <button className="abort" onClick={() => {changeIsRemoveUser(user , false)}}>Discard</button> 
            
            </div>
        </div>
    </div>
    )
}
export default RemoveUser;