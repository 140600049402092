import React from 'react'
import InputClear from '../utilities/InputClear'

const AudioText = ({name, toggle, setToggle, isToggle, title, subtitle, textState, textSetter, isActive, placeholder}) =>{

    const inputStyle ={
        opacity: toggle || isActive ? 1 : 0.5,
        width: '100%',
        height: '34px',
        border: '1px solid #D0D9ED',
        borderRadius: '5px',
        fontSize: '14px',
        fontFamily: 'OpenSans',
        color: '#4c4c4c'
    }

    return(        
        <div className="upload-file">
            <div className="settings-row">
                <label className="switch" htmlFor={'toggle-' + name}>
                    {
                    isToggle &&  
                        <div>
                            <input type="checkbox" 
                            name={'toggle-' + name} 
                            id={'toggle-' + name}
                            checked={toggle}
                            onChange={e => setToggle(e.target.checked)}        
                            ></input>
                            <span className="slider"></span>
                        </div>
                    }
                </label>
                <div className="upload-row" style={{height:'75px', marginBottom: '40px', width:'100%',display:'flex', justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexGrow:1, paddingRight:'10%', position:'relative'}}>
                        <div className="title-wrap">
                            <div className="header">{title}</div>
                            <div className='audio-subtitle' style={{

                            }}>{subtitle}</div>
                        </div>
                        <div style={{
                            position:'relative',
                            marginLeft:'10%',
                            flexGrow:'1',
                            alignSelf:'center'
                        }}>
                            <input 
                                style={inputStyle}
                                autoComplete="new-password"
                                type="text" name="newGroup"
                                maxLength="255"
                                placeholder={placeholder}
                                onChange={e => {textSetter(e.target.value)}}
                                value={textState}
                                className="new-doc"                        
                            />
                            {
                                (textState && (toggle || isActive)) && 
                                <InputClear
                                    setter={textSetter}
                                ></InputClear>
                            }
                        </div>
                        <div className="disabled"
                            style={{
                                display: toggle || isActive ? 'none':'block',
                                position:'absolute',
                                top:0,
                                left:0,
                                width:'100%',
                                height:'100%'
                            }}
                        ></div>
                    </div>             
                </div>                
            </div>
        </div>
    )
}

export default AudioText