import React from 'react';
import { useStoreActions } from "easy-peasy";
import { db } from "../../firebase";

const RemoveFromGroup = ({users, api, groupInView, groups}) => {
    const setIsRemoveFromGroup = useStoreActions(actions => actions.setIsRemoveFromGroup);
    
    const remove = () =>{
        const entitiesToRemove = users[1].filter(user => user.isChecked);
        const batch = db.batch();
        entitiesToRemove.map(entity => {
            const entityGroups = entity.entity_group.filter(name => {
                return name !== groupInView.entity_name;
            });
            const fullSelectedGroupsArr = groups.filter(fullGroup => {
                return entityGroups.includes(fullGroup.entity_name)
            })
            //meetingHierarchy---------------------------------------------------------
            let meetingHierarchyRobotsSet = new Set()
            for(let i = 0;i < entityGroups.length; i++){
                entity.meeting_hierarchy.forEach(robotId => {
                    if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                        meetingHierarchyRobotsSet.add(robotId)
                    }
                })            
            }
            const finalMeetingHierarchyRobotsArr = [...meetingHierarchyRobotsSet]
            //deliveryHierarchy---------------------------------------------------------
            let deliveryHierarchyRobotsSet = new Set()
            for(let i = 0;i < fullSelectedGroupsArr.length; i++){
                entity.delivery_hierarchy.forEach(robotId => {
                    if(fullSelectedGroupsArr[i].robots.includes(robotId)){
                        deliveryHierarchyRobotsSet.add(robotId)
                    }
                })            
            }
            const finalDeliveryHierarchyRobotsArr = [...deliveryHierarchyRobotsSet]
            /////-------------------------------------------------------------------------
            batch.update(api.doc(entity.id), {
                entity_group:entityGroups,
                meeting_hierarchy:finalMeetingHierarchyRobotsArr,
                delivery_hierarchy:finalDeliveryHierarchyRobotsArr
            });
        })
        batch.commit().then(() => {
            setIsRemoveFromGroup(false);
        }).catch(error => {
            console.log(error);
        })
    }

    return(
    <div className="group-popup-env">
        <div className="group-popup" onClick={() => {setIsRemoveFromGroup(false)}}></div>
        <div className="popup delete">
            <div className="header-large">Remove from group</div>
            <div className="text">Are you sure you want to remove these entities?</div>
            <div className="buttons-row">
                <button className="submit-new-doc" onClick={() => {remove()}}>Save</button> 
                <button className="abort" onClick={() => {setIsRemoveFromGroup(false)}}>Discard</button>             
            </div>
        </div>
    </div>
    )
}
export default RemoveFromGroup;