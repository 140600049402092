import React, { useState } from "react";
import { withRouter, Redirect } from "react-router";
import { firebaseApp } from "../../firebase";
import {useStoreState, useStoreActions} from 'easy-peasy';



const ResetPassword = ({ history }) => {

  const [error, setError] = useState('');
  const isResetPassword = useStoreState(store => store.isResetPassword);
  const setIsResetPassword = useStoreActions(actions => actions.setIsResetPassword);

  const handleReset = (e) => {
    e.preventDefault()
    const {email} = e.target.elements
    console.log(email.value)

    firebaseApp.auth().sendPasswordResetEmail(email.value).then(() => {
        setIsResetPassword(false);
    }).catch((error) => {
        console.log(error.message);
        setError(error.message);
        setTimeout(function(){
          setError('');
        }, 2500)
    })
  }

  if(!isResetPassword){
      return <Redirect to="/login" />
  }

  return (
    <div className="login-env">
      <div className="login-box">
        <div className="login-image"></div>
        <div className="form-env">
          <div className="header reset">Forgot Password?</div>
          <div className="txt">We will send you instructions to reset your password</div>
          <form onSubmit={handleReset}>
            <label>
              <div className="label">Email</div>
              <input name="email" type="email" placeholder="name@domain.com" />
              <div className="error">{error}</div>
            </label>
            <div className="button-env">
              <button type="submit" className="submit">send</button>
              <button className="back" onClick={() => setIsResetPassword(false)}>Back</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);