import React, {useEffect, useState, useCallback} from 'react';
import { firebaseApp, db } from "../../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';
import MultiSelect from "react-multi-select-component";
import Select from 'react-select'

export default function Assimilates() {
    // const routeEmail = useStoreState(store => store.routeEmail);
    const [users, setUsers] = useState([]);
    const [assimilates, setAssimilates] = useState([]);
    const [searchMatch, setSearchMatch] = useState([])
    const [searchKey, setSearchKey] = useState('')

    //lead form values
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [description, setDescription] = useState('')
    const [leadby, setLeadBy] = useState('')
    const [text, setText] = useState('')

    // selection
    const actionsArr = ['הדרכה','הטמעה','תיקון תקלה','אחר'] // change this  line-----------------
    const sourceInterestList = [...actionsArr]
    const interestList = sourceInterestList.map(str => {return {label: str, value: str}})
    const [selectedInterests, setSelectedInterests] = useState([])

    useEffect(() => {
        const api = db.collection('Companies').doc('GreetoSettings').collection('Customers');     
        // const api = db.collection('Companies').doc('Conference').collection('Entities');            
        api.onSnapshot(snapshot =>{
            const entitiesArr = [];
            snapshot.docs.map(doc => {             
                return entitiesArr.push(
                    {
                        id:doc.id,
                        ...doc.data()                                    
                    }
                );
            })
            const tempUsers = [];
            entitiesArr.map((entity) => {
                if(entity.entity_type === 'user'){
                    return tempUsers.push({...entity, fullName:`${entity.entity_firstname}`});
                }
            })
            setUsers(tempUsers);
        })
    }
    
    
    ,[]);
    useEffect(() => {
    const apiAssimilates = db.collection('Companies').doc('GreetoSettings').collection('Assimilates');     
        // const api = db.collection('Companies').doc('Conference').collection('Entities');            
        apiAssimilates.onSnapshot(snapshot =>{
            const assimilatesArr = [];
            snapshot.docs.map(doc => {             
                return assimilatesArr.push(
                    {
                        id:doc.id,
                        ...doc.data()                                    
                    }
                );
            })
            const tempAssimilates = [];
            assimilatesArr.map((entity) => {
                if(entity.entity_type === 'user'){
                    return tempAssimilates.push({...entity, value:`${entity.entity_firstname}`, label:`${entity.entity_firstname}`});
                }
            })
            setAssimilates(tempAssimilates);
        })
    },[]);

    function search(substring){        
        setSearchKey(substring)
        if(!substring){
            clearForm()
            setSearchMatch([''])
            return
        }
        let temp = []
        users.forEach(user => {
            if(user.fullName.includes(substring)){
                temp.push(user)
            }
        })
        setSearchMatch(temp)
        console.log(temp)
    }

    function searchAssimilates(substring){        
        setSearchKey(substring)
        if(!substring){
            clearForm()
            setSearchMatch([''])
            return
        }
        let temp = []
        assimilates.forEach(user => {
            if(user.fullName.includes(substring)){
                temp.push(user)
            }
        })
        setSearchMatch(temp)
        console.log(temp)
    }

    function fillForm(user){
        setName(user.fullName || '')
        setEmail(user.entity_email || '')
        setPhone(user.entity_phone || '')
        setDescription(user.entity_description || '')
    }

    function saveLead(e){
        e.preventDefault()
        if(!name || !phone || !email){
            alert('חסרים פרטים!')
            return
        }
        const lead = {
            name:name,
            email:email,
            phone:phone,
            interest:selectedInterests.map(interest => interest.value),
            description:description,
            text:text,
            leadby:leadby

        }
        db.collection('Leads').doc().set(lead).catch(e => alert('error'))
        .then(() => {
            alert('הטופס התקבל בהצלחה!')
            clearForm()
            clearSearch()
        })
    }

    function clearForm(){
        setName('')
        setEmail('')
        setPhone('')
        setDescription('')
        setText('')
        setLeadBy('')
        setSelectedInterests([])
    }

    function clearSearch(){
        setSearchKey('')
        setSearchMatch([''])
    }

    return(
        <div className="assimilates-env">
            <div className="search">
                <div className="search-user">
                    <label>חפש לקוח</label>
                    <input key="searchUser" 
                        value={searchKey} 
                        placeholder={'חיפוש ע"י שם'}
                        onChange={(e) => search(e.target.value)}
                        ></input>
                    <div className="clear" onClick={() => clearSearch()}>ניקוי חיפוש</div>
                </div>
            </div>
            <div className="search-results">
                {
                    !searchMatch[0] && <div className="search-result-title">
                        <span>לא נמצאו לקוחות</span>
                    </div>
                }
                {
                    searchMatch[1] && <div className="search-result-title">
                        <span>נמצאו</span>
                        <span>{searchMatch.length}</span>
                        <span>משתמשים</span>
                    </div>
                }
                {
                    searchMatch.map((user,i) => (
                        <div className="single-result" 
                            key={i}
                            onClick={() => fillForm(user)}
                            >{user.fullName}</div>
                    ))
                }
            </div>

            <form onSubmit={saveLead}>
                <div className="form-group">
                    <label style={{marginRight:'17px'}}>שם *</label>
                    <input
                    autoComplete="new-password"
                    type="text"
                    name="name"
                    onChange={e => {setName(e.target.value)}}
                    value={name}
                    />
                </div>
                <div className="form-group">
                    <label style={{marginRight:'14px'}}>חברה</label>
                    <input
                    autoComplete="new-password"
                    type="text"
                    name="description"
                    onChange={e => {setDescription(e.target.value)}}
                    value={description}
                    />
                </div>
                <div className="form-group">
                    <label>אימייל *</label>
                    <input
                    autoComplete="new-password"
                    type="text"
                    name="email"
                    onChange={e => {setEmail(e.target.value)}}
                    value={email}
                    />
                </div>
                <div className="form-group">
                    <label>טלפון *</label>
                    <input
                    autoComplete="new-password"
                    type="text"
                    name="phone"
                    onChange={e => {setPhone(e.target.value)}}
                    value={phone}
                    />
                </div>
                <div className="multi-select" style={{display:'flex'}}>  
                        <label>פעולות שבוצעו</label> 
                        <MultiSelect 
                            options={interestList}
                            value={selectedInterests}
                            onChange={setSelectedInterests}
                            labelledBy={""}
                        />
                        {/* <div className="select" style={{display:'initial'}}>
                            <select name="interest" value={selectedInterest} onChange={e => setSelectedInterest(e.target.value)}>
                                <option value="" defaultValue disabled hidden></option>
                                {
                                    interestList.map((str, i) => (
                                        <option key={i} value={str}>{str}</option>
                                    ))
                                }
                            </select>
                        </div> */}
                    </div>
        
                <div className="form-group" style={{display:'flex'}}>
                    <label>שם המטמיע</label>
                    <Select
                    options={assimilates}
                    name="leadby"
                    onChange={e => {setLeadBy(e)}}
                    value={leadby}
                    style={{marginTop:'15px'}}
                    />
                </div>
                <div className="form-group2">
                    <label>טקסט חופשי</label>
                    <textarea
                    autoComplete="new-password"
                    type="text"
                    name="text"
                    rows="5"
                    onChange={e => {setText(e.target.value)}}
                    value={text}
                    />
                </div>
                <button className="save" type="submit">שליחת טופס</button>
                <div className="clear-bottom" onClick={() => clearForm()}>ניקוי טופס</div>
            </form>            
        </div>
    )
}