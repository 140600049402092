import React from 'react'
import InputClear  from './InputClear'

const Input = ({setter, state, isActive, title, placeholder}) => {
    return (
    <div className="input" style={{display:'flex',marginLeft:'25px'}}>
        <label>{title}</label>
        <div className="text-input" style={{position:'relative',flexGrow:'1',alignSelf:'center'}}>
            <input 
                style={{opacity: isActive ? 1 : 0.5}}
                autoComplete="new-password"
                type="text" name="newGroup"
                maxLength="255"
                placeholder={placeholder}
                onChange={e => {setter(e.target.value)}}
                value={state}
                className="new-doc"                        
            />
            {
                isActive && state &&
                <InputClear
                    setter={setter}
                ></InputClear>
            }
            <div className="disabled"
                style={{
                    display: isActive ? 'none':'block',
                    position:'absolute',
                    top:0,
                    left:0,
                    width:'100%',
                    height:'100%'
                }}
            ></div>
        </div>
    </div>
    )
}

export default Input