import React, { useState , useEffect , useCallback} from 'react';
import { storage, db } from "../../firebase";
import {useStoreActions, useStoreState} from 'easy-peasy';
import UploadFile from './UploadFile';
import AudioText from './AudioText';
import Select from 'react-select'
import MultiSelect from 'react-multi-select-component';
import Input from '../utilities/Input';


const Settings = ({company, changeGroupInView, locations, robotsApi, robots, groups, users, entitiesApi}) => {
    const robot = useStoreState(store => store.robot);
    const setIsSettings = useStoreActions(actions => actions.setIsSettings);
    const [Saved, setSaved] = useState('');
    // const [fileType, setFileType] = useState(true);

    const [language, setLanguage] = useState({});
    const [langId, setLangId] = useState('')
    //const setIsSaveSettings = useStoreActions(actions => actions.setIsSaveSettings);
    const [isSaveSettings, setIsSaveSettings] = useState(false); 
    const [mediaObj, setMediaObj] = useState(undefined);
    const [settingsObj, setSettingsObj] = useState(undefined);
    const [viewMenuCategory, setViewMenuCategory] = useState('general');
    //state for each field
    const [openingAudioText, setOpeningAudioText] = useState('')
    const [meetingOpeningHeadline, setMeetingOpeningHeadline] = useState('');
    const [meetingMoveAudioText, setMeetingMoveAudioText] = useState('')
    const [meetingArrivedAudioText, setMeetingArrivedAudioText] = useState('')
    const [meetingOnTheWay, setMeetingOnTheWay] = useState('')
    const [meetingArrivedTitle, setMeetingArrivedTitle] = useState('')
    const [meetingPageTitle, setMeetingPageTitle] = useState('')
    const [meetingPopupHeadline, setMeetingPopupHeadline] = useState('')
    const [meetingPopupDescription, setMeetingPopupDescription] = useState('')
    const [meetingSmsOnTheWay, setMeetingSmsOnTheWay] = useState('')

    const [managerPhoneText, setManagerPhoneText] = useState('')

    const [isDeliveryManagerPhone, setIsDeliveryManagerPhone] = useState(false);
    const [deliveryOpeningHeadline, setDeliveryOpeningHeadline] = useState('');
    const [deliveryPageTitle, setDeliveryPageTitle] = useState('')
    const [deliveryMoveAudioText, setDeliveryMoveAudioText] = useState('')
    const [deliveryArrivedAudioText, setDeliveryArrivedAudioText] = useState('')
    const [deliveryPopupHeadline, setDeliveryPopupHeadline] = useState('')
    const [deliveryPopupDescription, setDeliveryPopupDescription] = useState('')
    const [deliveryPopupHeadlineAfter, setDeliveryPopupHeadlineAfter] = useState('')
    const [deliveryPopupDescriptionAfter, setDeliveryPopupDescriptionAfter] = useState('')
    const [deliverySmsOnTheWay, setDeliverySmsOnTheWay] = useState('')
    const [deliverySmsArrived, setDeliverySmsArrived] = useState('')
    const [deliverySmsNotSucceed, setDeliverySmsNotSucceed] = useState('')
    const [deliverySmsManager, setDeliverySmsManager] = useState('')
    const [deliveryOnTheWay, setDeliveryOnTheWay] = useState('')
    const [deliveryArrivedTitle, setDeliveryArrivedTitle] = useState('')
    const [deliveryDirectionMode, setDeliveryDirectionMode] = useState('straight')

    const uploadingFiles = useStoreState(store => store.uploadingFiles)
    const languages = useStoreState(store => store.languages)
    const smsCount = useStoreState(state => state.smsCount)
    const smsLimit = useStoreState(state => state.smsLimit)
    // state for each toggle
    const [toggleOpeningVisual, setToggleOpeningVisual] = useState(false);
    const [toggleOpeningAudio, setToggleOpeningAudio] = useState(false);
    const [toggleConstraint, setToggleConstraint] = useState(false);
    const [toggleCustomBackground, setToggleCustomBackground] = useState(false);

    const [toggleMeeting, setToggleMeeting] = useState(false);
    const [toggleSmsBool, setToggleSmsBool] = useState(false);

    const [toggleDelivery, setToggleDelivery] = useState(false);
    
    const [toggleApps, setToggleApps] = useState(false);
    // state for each link
    const [newLinkOpeningVisual, setNewLinkOpeningVisual] = useState(undefined);
    const [openingVisualFileType, setOpeningVisualFileType] = useState('')
    const [openingVisualFileName, setOpeningVisualFileName] = useState('')

    // const [newLinkOpeningAudio, setNewLinkOpeningAudio] = useState(undefined);
    
    const [newLinkBackgroundImage, setNewLinkBackgroundImage] = useState(undefined);
    const [backgroundFileType, setBackgroundFileType] = useState('')
    const [backgroundFileName, setBackgroundFileName] = useState('')

    const [newLinkLogoImage, setNewLinkLogoImage] = useState(undefined);
    const [logoImageFileType, setLogoImageFileType] = useState('')
    const [logoImageFileName, setLogoImageFileName] = useState('')
    // const [newLinkMeetingArrivedAudio, setNewLinkMeetingArrivedAudio] = useState(undefined);
    // const [newLinkMeetingMoveAudio, setNewLinkMeetingMoveAudio] = useState(undefined);
    // const [newLinkDeliveryMoveAudio, setNewLinkDeliveryMoveAudio] = useState(undefined);
    // const [newLinkDeliveryArrivedAudio, setNewLinkDeliveryArrivedAudio] = useState(undefined);
    const [newDeliveryManagerPhone, setNewDeliveryManagerPhone] = useState(undefined);

    const [deliveryOneHeirarchyUsersFromDb] = useState(users.filter(user => {
        if(user.delivery_hierarchy){
            return user.delivery_hierarchy.includes(robot)
        }        
    }))
    const [deliverySelectedHierarchies, setDeliverySelectedHierarchies] = useState('')
    // debugger
    const [robotGroups, setRobotGroups] = useState([])
    // const [robotGroups, setRobotGroups] = useState(() =>{
    //     console.log(groups)
    //     groups.filter(group => group.robots.includes(robot))
    // })
    const [deliverySelectedRobotGroups, setDeliverySelectedRobotGroups] = useState([])
    const [deliveryFirstHierarchyTitle, setDeliveryFirstHierarchyTitle] = useState('')
    const [deliverySecondHierarchyTitle, setDeliverySecondHierarchyTitle] = useState('')
    
    const [meetingOneHeirarchyUsersFromDb] = useState(users.filter(user => {
        if(user.meeting_hierarchy){
            return user.meeting_hierarchy.includes(robot)
        }        
    }))
    const [meetingSelectedRobotGroups, setMeetingSelectedRobotGroups] = useState([])
    const [meetingSelectedHierarchies, setMeetingSelectedHierarchies] = useState('')
    const [meetingFirstHierarchyTitle, setMeetingFirstHierarchyTitle] = useState('')
    const [meetingSecondHierarchyTitle, setMeetingSecondHierarchyTitle] = useState('')

    const [themeMode, setThemeMode] = useState('dark')
    const [meetingDirectionMode, setMeetingDirectionMode] = useState('straight')
    const [smsBool, setSmsBool] = useState(false)

    //locations
    const [entrance, setEntrance] = useState('');

    const [toggleCoffee, setToggleCoffee] = useState(false);
    const [coffeeLocation, setCoffeeLocation] = useState('');
    const [titleCoffee, setTitleCoffe] = useState('')
    const [coffeeDescription, setCoffeDescription] = useState('')

    const [toggleWc, setToggleWc] = useState(false);
    const [wcLocation, setWcLocation] = useState('');
    const [wcTitle, setWcTitle] = useState('')
    const [wcDescription, setWcDescription] = useState('')

    //schedule
    const [toggleSchedule, setToggleSchedule] = useState(false);
    const [startTime, setStartTime] = useState('');
    
    const [endTime, setEndTime] = useState('');

    const [standby, setStandby] = useState('');

    //texts
    const [toggleTexts, setToggleTexts] = useState(false)
    const [approvalButton, setApprovalButton] = useState('')
    const [denyButton, setDenyButton] = useState('')
    const [goingBack, setGoingBack] = useState('')
    const [searchText, setSearchText] = useState('')
    const [searchPageHeadline, setSearchPageHeadline] = useState('')
    //robots
    
    // const setRobot = useStoreActions(action => action.setRobot);

    const mediaApi = robotsApi.doc(robot).collection('Media');
    const settingsApi = robotsApi.doc(robot).collection('Settings');
    const randomNum = () => {
        return Math.floor(Math.random() * (1000000 - 1 + 1) ) + 1
    }

    function getDefaultLanguage(){
        for(let i=0; i<languages.length;i++){
            if(langId === languages[i].id){
                return languages[i]
            }                                                
        }
    }

    useEffect(() => {
        mediaApi.get().then((collection => {
            const temp = {};
            collection.docs.map(doc => {
                temp[doc.id] = doc.data();
            })
            // console.log(robotGroups)
            setMediaObj(temp);
            setToggleOpeningVisual(temp.isOpeningVisual.bool || false); //set toggle
            setOpeningVisualFileName(temp.isOpeningVisual.fileName || '')
            setToggleOpeningAudio(temp.isOpeningAudio.bool || false);
            setOpeningAudioText(temp.isOpeningAudio.text || '')
            setToggleMeeting(temp.isMeeting.bool || false);
            setMeetingMoveAudioText(temp.isMeeting.moveText || '')
            setMeetingArrivedAudioText(temp.isMeeting.arrivedText || '')
            setMeetingOnTheWay(temp.isMeeting.onTheWay || '')
            setMeetingArrivedTitle(temp.isMeeting.arrivedTitle || '')
            setMeetingPageTitle(temp.isMeeting.mainPageTitle || '')
            setMeetingPopupHeadline(temp.isMeeting.popupHeadline || '')
            setMeetingPopupDescription(temp.isMeeting.popupDescription || '')
            setMeetingSmsOnTheWay(temp.isMeeting.smsOnTheWay || '')
            setMeetingDirectionMode(temp.isMeeting.directionMode || 'straight')
            setToggleDelivery(temp.isDelivery.bool || false);
            setMeetingOpeningHeadline(temp.isMeeting.openingHeadline || '');
            setDeliveryOpeningHeadline(temp.isDelivery.openingHeadline || '');
            setNewLinkBackgroundImage(temp.bg.link || '');
            setBackgroundFileType(temp.bg.fileType || '')
            setBackgroundFileName(temp.bg.fileName || '')
            setNewLinkLogoImage(temp.logo.link || '');
            setLogoImageFileName(temp.logo.fileName || '');
            setThemeMode(temp.mode.type || '');
            setToggleCustomBackground(temp.bg.boolCustom || false);
            setDeliveryPageTitle(temp.isDelivery.mainPageTitle || '')
            setDeliveryMoveAudioText(temp.isDelivery.moveText || '')
            setDeliveryArrivedAudioText(temp.isDelivery.arrivedText || '')
            setManagerPhoneText(temp.isDelivery.managerPhone || '')
            setDeliveryPopupHeadline(temp.isDelivery.poupHeadline || '')
            setDeliveryPopupDescription(temp.isDelivery.popupDescription || '')
            setDeliveryPopupHeadlineAfter(temp.isDelivery.poupHeadlineAfter || '')
            setDeliveryPopupDescriptionAfter(temp.isDelivery.popupDescriptionAfter || '')
            setDeliverySmsOnTheWay(temp.isDelivery.smsOnTheWay || '')
            setDeliverySmsArrived(temp.isDelivery.smsArrived || '')
            setDeliverySmsNotSucceed(temp.isDelivery.smsNotSucceed || '')
            setDeliverySmsManager(temp.isDelivery.smsManager || '')
            setDeliveryOnTheWay(temp.isDelivery.onTheWay || '')
            setDeliveryArrivedTitle(temp.isDelivery.arrivedTitle || '')
            setDeliveryDirectionMode(temp.isDelivery.directionMode || 'straight')
        }));

        function validateField(obj, propName, innerPropName){
            if(obj){
                if(obj[propName]){
                    if(obj[propName][innerPropName]){
                        return obj[propName][innerPropName]
                    }else{
                        //todo create doc
                        if(innerPropName.includes('bool')){
                            return false
                        }
                        return ''
                    }
                    
                }else{
                    //todo create doc
                    if(innerPropName.includes('bool')){
                        return false
                    }
                    return ''
                }
            }else{
                //todo create doc
                if(innerPropName.includes('bool')){
                    return false
                }
                return ''
            }
            
            return
        }
        settingsApi.get().then((collection => {            
            const tempObj = {};
            collection.docs.map(doc => {
                tempObj[doc.id] = doc.data();
            })
            setSettingsObj(tempObj);
            setLanguage({
                label:tempObj.language.description,
                value:tempObj.language.id,
                orientation:tempObj.language.orientation
            })
            // setLangId(tempObj.language.type);
            setToggleConstraint(tempObj.constraint.bool || false);
            setEntrance(tempObj.entrance.location  || '');
            setStandby(tempObj.standby.time  || '');
            setToggleCoffee(tempObj.isCoffeeLocation.bool || false);
            setCoffeeLocation(tempObj.isCoffeeLocation.location || '');
            setTitleCoffe(tempObj.isCoffeeLocation.title || '')
            setCoffeDescription(tempObj.isCoffeeLocation.description || '')
            setToggleWc(tempObj.isWcLocation.bool || false);
            setWcLocation(tempObj.isWcLocation.location || '');
            setWcTitle(tempObj.isWcLocation.title || '')
            setWcDescription(tempObj.isWcLocation.description || '')
            setToggleSchedule(tempObj.isAppSchedule.bool || false);
            setStartTime(tempObj.isAppSchedule.startTime || '');
            setEndTime(tempObj.isAppSchedule.endTime || '');
            setToggleTexts(validateField(tempObj,'isTests', 'bool'))
            setApprovalButton(tempObj.isTexts.approvalButton || '')
            setDenyButton(tempObj.isTexts.denyButton || '')
            setGoingBack(tempObj.isTexts.goingBackMessage || '')
            setSearchPageHeadline(tempObj.isTexts.searchPageHeadline || '')
            setSearchText(tempObj.isTexts.searchText || '')
            setToggleApps(tempObj.isPopularApps.bool || false);
            setDeliverySelectedHierarchies(tempObj.deliveryHierarchies.numberString || '');
            setDeliveryFirstHierarchyTitle(tempObj.deliveryHierarchies.firstHierarchyTitle || '')
            setDeliverySecondHierarchyTitle(tempObj.deliveryHierarchies.secondHierarchyTitle || '')
            setDeliverySelectedRobotGroups(tempObj.deliveryHierarchies.groups.map(group => {
                return {
                    label:group.name,
                    value:group.id
                }
            }))
            setMeetingSelectedHierarchies(tempObj.meetingHierarchies.numberString || '');
            setMeetingFirstHierarchyTitle(tempObj.meetingHierarchies.firstHierarchyTitle || '')
            setMeetingSecondHierarchyTitle(tempObj.meetingHierarchies.secondHierarchyTitle || '')
            // debugger
            const groupIdsArr = groups.map(g => g.id)
            setRobotGroups(groups.filter(group => group.robots.includes(robot)))
            setMeetingSelectedRobotGroups(tempObj.meetingHierarchies.groups.filter(g => groupIdsArr.includes(g.id)).map(group => {
                return {
                    label:group.name,
                    value:group.id
                }
            }))
        }));
    }, [robot])

    const handleSubmit = (e) => {
        e.preventDefault();
        if((!toggleDelivery && !toggleMeeting )){ // || uploadingFiles
            console.log(toggleMeeting, toggleDelivery)
            return;
        }
        const oldFiles = []; // iterate over this to delete old files
        const batch = db.batch();
///////////////////////////////////////General Settings///////////////////////////////////////
        const tempOpeningVisual = {bool:toggleOpeningVisual};
        if(newLinkOpeningVisual){
            tempOpeningVisual.link = newLinkOpeningVisual;
            tempOpeningVisual.fileType = openingVisualFileType
            tempOpeningVisual.fileName = openingVisualFileName
            if(mediaObj.isOpeningVisual.link){
                oldFiles.push(mediaObj.isOpeningVisual.link.split('Media%2F')[1].split('?')[0]);
            }
        }
        batch.update(mediaApi.doc('isOpeningVisual'), tempOpeningVisual);
        const tempOpeningAudio = {
            bool:toggleOpeningAudio,
            text:openingAudioText
        }

        batch.update(mediaApi.doc('isOpeningAudio'), tempOpeningAudio);
        batch.update(mediaApi.doc('bg'), {link: newLinkBackgroundImage, boolCustom: toggleCustomBackground, fileType:backgroundFileType, fileName:backgroundFileName});
        // batch.update(mediaApi.doc('bg'), {boolCustom: toggleCustomBackground});
        batch.update(mediaApi.doc('logo'), {link: newLinkLogoImage, fileType:logoImageFileType, fileName:logoImageFileName});
        batch.update(mediaApi.doc('mode'), {type: themeMode});


        batch.update(settingsApi.doc('constraint'), {bool:toggleConstraint});
        const tempLanguageObj = {}
        for(let i=0; i<languages.length; i++){
            // debugger
            if(language.value === languages[i].id){
                tempLanguageObj.description = languages[i].description
                tempLanguageObj.orientation = languages[i].orientation
                tempLanguageObj.type = languages[i].id             
                break
            }
        }
        batch.update(settingsApi.doc('language'), tempLanguageObj);
        batch.update(settingsApi.doc('entrance'), {location:entrance});
        batch.update(settingsApi.doc('isCoffeeLocation'), {bool:toggleCoffee,location:coffeeLocation, title:titleCoffee, description:coffeeDescription});
        batch.update(settingsApi.doc('isWcLocation'), {bool:toggleWc,location:wcLocation,title:wcTitle, description:wcDescription});
        batch.update(settingsApi.doc('isTexts'),{bool:toggleTexts,approvalButton:approvalButton,denyButton:denyButton,goingBackMessage:goingBack,searchPageHeadline:searchPageHeadline,searchText:searchText})
        batch.update(settingsApi.doc('isAppSchedule'), {bool:toggleSchedule,startTime:startTime,endTime:endTime});
        batch.update(settingsApi.doc('meetingHierarchies'), {numberString:meetingSelectedHierarchies});
        batch.update(settingsApi.doc('standby'), {time:standby});
        ///////////////////////////////////////////hierarchies////////////////////////////////
        function handleDeliveryHierarchies(){
            const tempUsersSet = new Set()        
            deliverySelectedRobotGroups.forEach(group => {
                users.forEach(user => {
                    if(user.entity_group.includes(group.label)){
                        tempUsersSet.add(user)
                    }
                })
            });
            const tempDeliveryGroups = deliverySelectedRobotGroups.map(obj => {
                return {id:obj.value, name:obj.label}
            })
            batch.update(settingsApi.doc('deliveryHierarchies'), { // need to return this to 'deliveryHierarchies'
                numberString:deliverySelectedHierarchies, 
                firstHierarchyTitle: deliveryFirstHierarchyTitle,
                secondHierarchyTitle: deliverySecondHierarchyTitle,
                groups:tempDeliveryGroups
            });
            const tempUsersArr = [...tempUsersSet]    
            let tempHeirarchiesArr = []
            tempUsersArr.forEach(user => {
                //adds unique robots to entity_1Heirarchies
                if(user.delivery_hierarchy && !user.delivery_hierarchy.includes(robot)){
                    tempHeirarchiesArr = [...user.delivery_hierarchy, robot]
                }else{
                    tempHeirarchiesArr = [robot]
                }            
                batch.update(entitiesApi.doc(user.id), {delivery_hierarchy:tempHeirarchiesArr})
            })
            let hierarchieTempBool = false
            const userToRemoveRobotArr = []
            deliveryOneHeirarchyUsersFromDb.forEach(dbUser => {
                hierarchieTempBool = false
                for(let i = 0; i < tempUsersArr.length; i++){
                    if(dbUser.id === tempUsersArr[i].id){
                        hierarchieTempBool = true
                        break
                    }
                }
                if(!hierarchieTempBool){
                    userToRemoveRobotArr.push(dbUser)
                }          
            })
            userToRemoveRobotArr.forEach(user => {
                if(user.delivery_hierarchy.length > 1){
                    //remove the specific robot from hierarchies
                    tempHeirarchiesArr = user.delivery_hierarchy.filter(str => str !== robot)
                }else{
                    tempHeirarchiesArr = [] 
                }
                batch.update(entitiesApi.doc(user.id), {delivery_hierarchy:tempHeirarchiesArr})
            })
        }
        handleDeliveryHierarchies()
        function handleMeetingHierarchies(){
            const tempUsersSet = new Set()        
            meetingSelectedRobotGroups.forEach(group => {
                users.forEach(user => {
                    if(user.entity_group.includes(group.label)){
                        tempUsersSet.add(user)
                    }
                })
            });
            const tempMeetingGroups = meetingSelectedRobotGroups.map(obj => {
                return {id:obj.value, name:obj.label}
            })
            batch.update(settingsApi.doc('meetingHierarchies'), {
                numberString:meetingSelectedHierarchies, 
                groups:tempMeetingGroups,
                firstHierarchyTitle:meetingFirstHierarchyTitle,
                secondHierarchyTitle:meetingSecondHierarchyTitle
            });
            const tempUsersArr = [...tempUsersSet]    
            let tempHeirarchiesArr = []
            tempUsersArr.forEach(user => {
                //adds unique robots to entity_1Heirarchies
                if(user.meeting_hierarchy && !user.meeting_hierarchy.includes(robot)){
                    tempHeirarchiesArr = [...user.meeting_hierarchy, robot]
                }else{
                    tempHeirarchiesArr = [robot]
                }            
                batch.update(entitiesApi.doc(user.id), {meeting_hierarchy:tempHeirarchiesArr})
            })
            let hierarchieTempBool = false
            const userToRemoveRobotArr = []
            meetingOneHeirarchyUsersFromDb.forEach(dbUser => {
                hierarchieTempBool = false
                for(let i = 0; i < tempUsersArr.length; i++){
                    if(dbUser.id === tempUsersArr[i].id){
                        hierarchieTempBool = true
                        break
                    }
                }
                if(!hierarchieTempBool){
                    userToRemoveRobotArr.push(dbUser)
                }          
            })
            userToRemoveRobotArr.forEach(user => {
                if(user.meeting_hierarchy.length > 1){
                    //remove the specific robot from hierarchies
                    tempHeirarchiesArr = user.meeting_hierarchy.filter(str => str !== robot)
                }else{
                    tempHeirarchiesArr = [] 
                }
                batch.update(entitiesApi.doc(user.id), {meeting_hierarchy:tempHeirarchiesArr})
            })
        }
        handleMeetingHierarchies()
  //////////////////////////////////Main Flow//////////////////////////////////////////  
        batch.update(mediaApi.doc('isMeeting'), {
            bool:toggleMeeting, 
            openingHeadline:meetingOpeningHeadline,
            moveText:meetingMoveAudioText,
            arrivedText:meetingArrivedAudioText,
            onTheWay:meetingOnTheWay,
            arrivedTitle:meetingArrivedTitle,
            mainPageTitle:meetingPageTitle,
            popupHeadline:meetingPopupHeadline,
            popupDescription:meetingPopupDescription,
            directionMode: meetingDirectionMode,
            smsOnTheWay:meetingSmsOnTheWay
        });
        batch.update(mediaApi.doc('isDelivery'), {
            bool:toggleDelivery, 
            mainPageTitle:deliveryPageTitle,
            moveText:deliveryMoveAudioText,
            arrivedText:deliveryArrivedAudioText,
            managerPhone:managerPhoneText,
            poupHeadline: deliveryPopupHeadline,
            popupDescription: deliveryPopupDescription,
            poupHeadlineAfter: deliveryPopupHeadlineAfter,
            popupDescriptionAfter: deliveryPopupDescriptionAfter,
            smsOnTheWay:deliverySmsOnTheWay,
            smsArrived:deliverySmsArrived,
            smsManager:deliverySmsManager,
            onTheWay: deliveryOnTheWay,
            arrivedTitle: deliveryArrivedTitle,
            directionMode: deliveryDirectionMode,
            smsNotSucceed:deliverySmsNotSucceed
        });

        const tempDeliveryAudio = {bool:toggleDelivery, smsBool:toggleSmsBool, openingHeadline:deliveryOpeningHeadline};
        // if(newLinkDeliveryMoveAudio){
        //     tempDeliveryAudio.moveLink = newLinkDeliveryMoveAudio;
        //     if(mediaObj.isDelivery.moveLink){
        //         oldFiles.push(mediaObj.isDelivery.moveLink.split('Media%2F')[1].split('?')[0]);
        //     }
        // }
        if(newDeliveryManagerPhone){
            tempDeliveryAudio.managerPhone = newDeliveryManagerPhone;
            if(mediaObj.isDelivery.managerPhone){
                oldFiles.push(mediaObj.isDelivery.managerPhone);//.split('Media%2F')[1].split('?')[0]
            }
        }
        // if(newLinkDeliveryArrivedAudio){
        //     tempDeliveryAudio.arrivedLink = newLinkDeliveryArrivedAudio;
        //     if(mediaObj.isDelivery.arrivedLink){
        //         oldFiles.push(mediaObj.isDelivery.arrivedLink.split('Media%2F')[1].split('?')[0]);
        //     }
        // }

        const tempPopularApps = {bool:toggleApps};

        batch.update(mediaApi.doc('isDelivery'), tempDeliveryAudio);
        // batch.update(mediaApi.doc('isDelivery'), {smsBool:toggleSmsBool});

        // batch.update(mediaApi.doc('isDelivery'), {openingHeadline:deliveryOpeningHeadline});

        batch.update(settingsApi.doc('isPopularApps'), {bool:toggleApps});

        if(!toggleCustomBackground){
            console.log(themeMode)
            if(themeMode === 'light'){
                setNewLinkBackgroundImage("https://firebasestorage.googleapis.com/v0/b/toyota-cd2b6.appspot.com/o/Oval%20%2B%20Oval%20%2B%20Oval%20Mask%20%2B%20Rectangle%20Mask.png?alt=media&token=d54ca999-70fa-40f1-a4b0-0b6463684291")
            }
            else{
                setNewLinkBackgroundImage("https://firebasestorage.googleapis.com/v0/b/toyota-cd2b6.appspot.com/o/dark%202%20m.jpg?alt=media&token=215c68e9-7676-4bb4-bddf-8f7f4e60935e")
            }
        }

        if(!toggleTexts){
            batch.update(settingsApi.doc('isTexts'),{bool:toggleTexts,approvalButton:"OK",denyButton:"Cancel",goingBackMessage:"In my way back to entrance",searchPageHeadline:"Search Results",searchText:"Search by name"})
            setApprovalButton("OK")
            setDenyButton("Cancel")
            setGoingBack("In my way back to entrance")
            setSearchPageHeadline("Search Results")
            setSearchText("Search by name")
        }


        batch.commit().then(() => {    
            // delete old files  
            console.log(oldFiles)  
            Promise.all(oldFiles.map((file) =>{
                storage.ref().child(company + '/Media/' + file).delete();
            })).then(() => {
                //changeGroupInView({entity_name:'Entities'});
                //setIsSettings(false);
                {
                    // alert(error);
                    setSaved("Send to robot successfully");
                    setTimeout(function(){
                      setSaved('');
                    }, 1500)
                  }
                setIsSaveSettings(true);
            }).catch((error) => console.log(error));           
        }).catch((error) => console.log(error))
        
    }

    // const handleChangeDeliverySelectedHierarchies = (event) => {
    //     console.log(event.target.value, event.target.checked)
    //     setDeliverySelectedHierarchies(event.target.value)
    //   }

    const handleChangeMeetingSelectedHierarchies = (event) => {
        console.log(event.target.value, event.target.checked)
        setMeetingSelectedHierarchies(event.target.value)
      }

      const handleChangeThemeMode = (event) => {
        console.log(event.target.value, event.target.checked)
        setThemeMode(event.target.value)
      }

    const handleAbort = () => {
        const files = [newLinkOpeningVisual];
        const filesToDelete = [];
        files.filter(file => {
            if(file){
                filesToDelete.push(file.split('Media%2F')[1].split('?')[0]);
            }
        })
        // delete all temp files
        console.log(filesToDelete)
        Promise.all(filesToDelete.map((file) => {
            storage.ref().child(company + '/Media/' + file).delete();
        })).then(() => {
            changeGroupInView({entity_name:'Entities'});
            setIsSettings(false);
        }).catch((error) => console.log(error));
        // console.log(filesToDelete)
        // console.log(mediaObj)
    }

    const formatPhone = val => {
        if(val[0] === '0'){
            setNewDeliveryManagerPhone(val.substring(1));
        }else{
            setNewDeliveryManagerPhone(val);
        }
        // setIsDeliveryManagerPhone(val)
    }

    return(
        <div className="dashboard" id="settings">
            <form style={{height:'100%'}} onSubmit={handleSubmit}>
                <div style={{margin:'auto', height:'calc(100% - 92px)', width:'74.4%'}}>
                    <div className="robot">{robot}</div>
                    <div className="settings-header">
                        <div className="header-row">
                            <div className={`header-item ${viewMenuCategory === 'general' && 'active'}`} onClick={() => setViewMenuCategory('general')}>General Settings</div>
                            <div className={`header-item ${viewMenuCategory === 'meeting' && 'active'}`} onClick={() => setViewMenuCategory('meeting')}>Meeting</div>
                            <div className={`header-item ${viewMenuCategory === 'delivery' && 'active'}`} onClick={() => setViewMenuCategory('delivery')}>Delivery</div>
                        </div>
                    </div>
                    <div className="settings-box">
                        {viewMenuCategory === 'general' &&
                            <div className="settings-box-scroll">
                                <div className="sms-env">
                                    <div className={smsCount < (smsLimit - 5) ? "sms-green" : "sms-red"}>
                                    {/* div className="sms-count" */}
                                        <span>SMS Counter: </span>
                                        <span>{smsCount} / {smsLimit}</span>
                                    </div>
                                    {/* <div className="sms-limit">
                                        <span>SMS Limit</span>
                                        <span>{smsLimit}</span>
                                    </div> */}
                                </div>
                                <UploadFile
                                    company={company}
                                    setLink={setNewLinkOpeningVisual}
                                    setFileType={setOpeningVisualFileType}
                                    setFileName={setOpeningVisualFileName}
                                    oldFileName={openingVisualFileName}
                                    name="isOpeningVisual"
                                    toggle={toggleOpeningVisual}
                                    setToggle={setToggleOpeningVisual}
                                    isToggle={true}
                                    title="Opening Visual"
                                    text="Choose png / jpeg / mp4 file (recommended size 1280X800)."
                                />
                                <AudioText                                                                            
                                    name="isOpeningAudio"
                                    toggle={toggleOpeningAudio}
                                    setToggle={setToggleOpeningAudio}
                                    isToggle={true}
                                    title="Welcome Text To Speech"
                                    subtitle="When temi recognizes a person"
                                    textState={openingAudioText}
                                    textSetter={setOpeningAudioText}
                                    placeholder='Welcome to our company'
                                />
                                <div className="settings-row">
                                    <label className="switch" htmlFor="constraint">
                                        <input type="checkbox" 
                                            name="constraint" 
                                            id="constraint"
                                            checked={toggleConstraint}
                                            onChange={e => setToggleConstraint(e.target.checked)}        
                                            ></input>
                                        <span className="slider"></span>
                                    </label>
                                    <div className="upload-row">
                                        <div className="header">User Tracking</div>
                                        <div className="small-text" style={{color:'#888888'}}>Enable/disable use tracking after detection</div>
                                    </div>
                                </div>
                                <div className="indent-row" style={{marginTop:'35px', justifyContent:'flex-start', marginBottom: '50px', flexDirection : 'column'}}>  
                                    <div className="header" style={{marginBottom:'10px'}}>App Language</div>
                                  
                                    <div className="react-select" style={
                                            {
                                                marginRight:'10px',
                                                // height: '24px',
                                                width: '178px',
                                                border: '1px solid #D0D9ED',
                                                borderRadius: '5px'
                                            }
                                        }
                                    >
                                        <Select
                                            options={languages.map(obj => {
                                                return {
                                                    label:obj.description,
                                                    value:obj.id,
                                                    orientation:obj.orientation
                                                }
                                            })}
                                            defaultValue={language}
                                            value={language}
                                            onChange={obj => setLanguage(obj)}
                                        />
                                    </div>
                                     
                                </div>
                                <UploadFile
                                    company={company}
                                    setLink={setNewLinkBackgroundImage}
                                    setFileType={setBackgroundFileType}
                                    setFileName={setBackgroundFileName}
                                    oldFileName={backgroundFileName}
                                    name="backgroundImage"
                                    toggle={toggleCustomBackground}
                                    setToggle={setToggleCustomBackground}
                                    isToggle={true}
                                    title="Custom Background"
                                    text="Choose png / jpeg file (recommended size 1280X800)."
                                />
                                <UploadFile
                                    company={company}
                                    setLink={setNewLinkLogoImage}
                                    setFileType={setLogoImageFileType}
                                    setFileName={setLogoImageFileName}
                                    oldFileName={logoImageFileName}
                                    name="logoImage"
                                    isToggle={false}
                                    title="Custom Logo"
                                    text="Choose png / jpeg file (recommended size 229X36)."
                                />
                                <div className="indent-row" style={{marginTop:'29px', flexDirection : 'column'}}>  
                                    <div className="header" style={{marginBottom:'10px'}}>Mode</div>
                                    <div className="radio" style={{
                                        display: 'flex',
                                        width: '106px',
                                        height: '34px',
                                        border: '1px solid #D0D9ED',
                                        borderRadius: '5px',
                                        position:'relative'
                                    }}>
                                        <div 
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                left:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setThemeMode('light')}
                                        >Light</div>
                                        <div
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                right:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setThemeMode('dark')}
                                        >Dark</div>
                                        <div                                             
                                        style={{
                                            position:'absolute',
                                            left: themeMode === 'light' ? 0 : '50%',
                                            top:0,
                                            width:'50%',
                                            height:'100%',
                                            transition:'left 250ms linear',
                                            willChange:'left',
                                            background:'#D0D9ED',
                                            borderRadius:'5px'                                                                                    
                                        }}></div>
                                    </div>
                                </div>
                                <div className="default-locations">
                                    <div className="header" style={{margin: '53px 0 15px 64px'}}>Default locations</div>
                                    <div className="location-row" style={{margin: '0 0 29px 64px'}}>  
                                        <label>Entrance</label>
                                        <div className="select">
                                            <select name="entrance" value={entrance} onChange={e => setEntrance(e.target.value)}>
                                                <option value="" defaultValue disabled hidden></option>
                                                {                            
                                                    locations.map(l => (
                                                        <option 
                                                            key={l.id + 'l'} 
                                                            value={l.id} 
                                                            defaultValue={entrance === l.id}
                                                            >{l.id}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>  
                                    <div className="location-row"  style={{margin: '0 0 29px 0'}}> 
                                        <label className="switch" htmlFor="coffee">
                                            <input type="checkbox" 
                                                name="coffee" 
                                                id="coffee"
                                                checked={toggleCoffee}
                                                onChange={e => setToggleCoffee(e.target.checked)}        
                                                ></input>
                                            <span className="slider"></span>
                                        </label>
                                        <label>Coffee lounge</label>
                                        <div className="select">
                                            <select name="coffeeLocation" value={coffeeLocation} onChange={e => setCoffeeLocation(e.target.value)}>
                                                <option value="" defaultValue disabled hidden></option>
                                                {                            
                                                    locations.map(l => (
                                                        <option 
                                                            key={l.id + 'l'} 
                                                            value={l.id} 
                                                            defaultValue={coffeeLocation === l.id}
                                                            >{l.id}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <Input
                                            setter={setTitleCoffe}
                                            state={titleCoffee}
                                            isActive={toggleCoffee}
                                            title={'Title Coffee'}
                                            placeholder='Coffee Lounge'
                                        />
                                    </div> 
                                    <div style={{margin:'0 39px 40px'}}>
                                        <Input
                                            setter={setCoffeDescription}
                                            state={coffeeDescription}
                                            isActive={toggleCoffee}
                                            title={'Description'}
                                            placeholder='I will accompany you to the Coffee Lounge'
                                        />
                                    </div>
                                    <div className="location-row"  style={{margin: '0 0 29px 0'}}> 
                                        <label className="switch" htmlFor="wc">
                                            <input type="checkbox" 
                                                name="wc" 
                                                id="wc"
                                                checked={toggleWc}
                                                onChange={e => setToggleWc(e.target.checked)}        
                                                ></input>
                                            <span className="slider"></span>
                                        </label>
                                        <label>Restroom</label>
                                        <div className="select">
                                            <select name="wcLocation" value={wcLocation} onChange={e => setWcLocation(e.target.value)}>
                                                <option value="" defaultValue disabled hidden></option>
                                                {                            
                                                    locations.map(l => (
                                                        <option 
                                                            key={l.id + 'l'} 
                                                            value={l.id} 
                                                            defaultValue={wcLocation === l.id}
                                                            >{l.id}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <Input
                                            setter={setWcTitle}
                                            state={wcTitle}
                                            isActive={toggleWc}
                                            title={'Title Restroom'}
                                            placeholder='Restroom'
                                        />
                                    </div>  
                                    <div style={{margin:'0 39px 40px'}}>
                                        <Input
                                            setter={setWcDescription}
                                            state={wcDescription}
                                            isActive={toggleWc}
                                            title={'Description'}
                                            placeholder='I will accompany you to the Restroom'
                                        />
                                    </div>
                                    <div className="group-input input-texts">
                                    <div className="title-row">
                                        <label className="switch" htmlFor="app-schedule">
                                            <input type="checkbox" 
                                                name="app-schedule" 
                                                id="app-schedule"
                                                checked={toggleSchedule}
                                                onChange={e => setToggleSchedule(e.target.checked)}        
                                                ></input>
                                            <span className="slider"></span>
                                        </label>
                                        <div className="main-title" style={{margin: '0 29px 0 0'}}>App Schedule</div>
                                        {/* <TimeInput/> */}
                                         <div>
                                            <label htmlFor="start-time">Starts at</label>
                                            <input type="time" 
                                                id="start-time" name="start-time" 
                                                value={startTime}
                                                onChange={e => setStartTime(e.target.value)}></input>
                                        </div> 
                                         <div>
                                            <label htmlFor="end-time" style={{margin: '0 0 0 29px'}}>Ends at</label>
                                            <input type="time" 
                                                id="end-time" name="end-time" 
                                                value={endTime}
                                                onChange={e => setEndTime(e.target.value)}></input>
                                        </div> 
                                    </div>
                                    </div>
                                    <div className="group-input input-texts">
                                        <div className="title-row">
                                            <label className="switch" htmlFor="isTexts">
                                                <input type="checkbox" 
                                                    name="isTexts" 
                                                    id="isTexts"
                                                    checked={toggleTexts}
                                                    onChange={e => setToggleTexts(e.target.checked)}        
                                                    ></input>
                                                <span className="slider"></span>
                                            </label>
                                            <div className="main-title">Texts</div>
                                        </div>
                                        <Input
                                            setter={setApprovalButton}
                                            state={approvalButton}
                                            isActive={toggleTexts}
                                            title={'Aprroval button'}
                                            placeholder='OK / Yes / Approve'
                                        />
                                        <Input
                                            setter={setDenyButton}
                                            state={denyButton}
                                            isActive={toggleTexts}
                                            title={'Deny button'}
                                            placeholder='Cancel / No / Deny'
                                        />
                                        <Input
                                            setter={setGoingBack}
                                            state={goingBack}
                                            isActive={toggleTexts}
                                            title={'Going back message'}
                                            placeholder='Going back to entrance'
                                        />
                                        <Input
                                            setter={setSearchText}
                                            state={searchText}
                                            isActive={toggleTexts}
                                            title={'Search Text'}
                                            placeholder='Search by name'
                                        />
                                        <Input
                                            setter={setSearchPageHeadline}
                                            state={searchPageHeadline}
                                            isActive={toggleTexts}
                                            title={'Search page headline'}
                                            placeholder='Search Result'
                                        />
                                    </div>             
                                </div>
                            </div>
                        }
                        {   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                            viewMenuCategory === 'meeting' &&
                            <div className="settings-box-scroll">
                                <div className="settings-row" style={{marginBottom:'29px'}}>
                                    <label className="switch" htmlFor="meeting-toggle">                                
                                        <input type="checkbox" 
                                            name="meeting-toggle" 
                                            id="meeting-toggle"
                                            checked={toggleMeeting}
                                            onChange={e => setToggleMeeting(e.target.checked)}        
                                            ></input>
                                        <span className="slider"></span>                                
                                    </label>
                                    <div>
                                        <div className="header">Meeting</div>
                                        <div className="text" style={{color:'#888888'}}>Accompanies a visitor to a meeting</div>
                                    </div>
                                </div>
                                <div className="group-input input-texts">
                                    <Input
                                        title={'Main page title'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingPageTitle}
                                        state={meetingPageTitle}
                                        placeholder='Here for a meeting?'
                                    />
                                    <div className="large-title">General</div>
                                    <Input
                                        title={'Move TTS'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingMoveAudioText}
                                        state={meetingMoveAudioText}
                                        placeholder='Welcome to company name'
                                    />
                                    <Input
                                        title={'Arrived TTS'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingArrivedAudioText}
                                        state={meetingArrivedAudioText}
                                        placeholder='Welcome to company name'
                                    /> 
                                    <Input
                                        title={'Opening headline'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingOpeningHeadline}
                                        state={meetingOpeningHeadline}
                                        placeholder='Welcome to company name'
                                    />
                                    <Input
                                        title={'On the way to title'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingOnTheWay}
                                        state={meetingOnTheWay}
                                        placeholder='Going to (entity name)'
                                    />
                                    <Input
                                        title={'Arrive title'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingArrivedTitle}
                                        state={meetingArrivedTitle}
                                        placeholder='Arrived to (entity name)'
                                    />
                                </div>                        
                                <div className="group-input input-texts">
                                    <div className="large-title">Hierarchies</div>
                                    <div style={{display:'flex'}}>
                                        <label style={{marginLeft:'64px',width:'200px'}}>Hierarchies number</label>
                                        <div className="select" style={
                                                {
                                                    margin:'0 34px 10px',
                                                    height: '40px',
                                                    width: '178px',
                                                    border: '1px solid #D0D9ED',
                                                    borderRadius: '5px'
                                                }
                                            }
                                        >       
                                            <select name="meetingSelectedHierarchies"
                                                value={meetingSelectedHierarchies}
                                                onChange={e => setMeetingSelectedHierarchies(e.target.value)}
                                            >
                                                <option value="1">One hierarchy</option>
                                                <option value="2">Two hierarchies</option>    
                                            </select>                                                                       
                                        </div>
                                        <MultiSelect
                                            options={robotGroups.map(group => {
                                                return {
                                                    label:group.entity_name,
                                                    value:group.id,
                                                }
                                            })}
                                            defaultValue={meetingSelectedRobotGroups}
                                            value={meetingSelectedRobotGroups}
                                            onChange={obj => setMeetingSelectedRobotGroups(obj)}
                                        /> 
                                    </div>
                                    <div className="group-input input-texts">
                                        <Input
                                            title={'First Hierarchy title'}
                                            isActive={toggleMeeting}
                                            setter={setMeetingFirstHierarchyTitle}
                                            state={meetingFirstHierarchyTitle}
                                            placeholder='Which group or entity do you choose?'
                                        />
                                        <Input
                                            title={'Second Hierarchy title'}
                                            isActive={toggleMeeting && meetingSelectedHierarchies == '2'}
                                            setter={setMeetingSecondHierarchyTitle}
                                            state={meetingSecondHierarchyTitle}
                                            placeholder='Which group or entity do you choose?'
                                        />
                                    </div>
                                </div>
                                {/* //////////////////////// */}
                                <div className="group-input input-texts">
                                    <div className="large-title">Direction</div>
                                    <div className="radio" style={{
                                        display: 'flex',
                                        width: '175px',
                                        height: '34px',
                                        marginLeft: '60px',
                                        border: '1px solid #D0D9ED',
                                        borderRadius: '5px',
                                        position:'relative'
                                    }}>
                                        <div 
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                left:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setMeetingDirectionMode('reverse')}
                                        >Reverse</div>
                                        <div
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                right:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setMeetingDirectionMode('straight')}
                                        >Straight</div>
                                        <div                                             
                                        style={{
                                            position:'absolute',
                                            left: meetingDirectionMode === 'reverse' ? 0 : '50%',
                                            top:0,
                                            width:'50%',
                                            height:'100%',
                                            transition:'left 250ms linear',
                                            willChange:'left',
                                            background:'#D0D9ED',
                                            borderRadius:'5px'                                                                                    
                                        }}></div>
                                    </div>
                                </div>

                                <div className="group-input input-texts">
                                    <div className="large-title">Pop ups</div>
                                    <Input
                                        title={'Headline'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingPopupHeadline}
                                        state={meetingPopupHeadline}
                                        placeholder='Meeting with xEntity namex'
                                    />
                                    <Input
                                        title={'Description'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingPopupDescription}
                                        state={meetingPopupDescription}
                                        placeholder='I will accompany you to the xEntity namex'
                                    />
                                </div>
                                <div className="group-input input-texts">
                                    <div className="large-title">SMS</div>
                                    <Input
                                        title={'On the way'}
                                        isActive={toggleMeeting}
                                        setter={setMeetingSmsOnTheWay}
                                        state={meetingSmsOnTheWay}
                                        placeholder='A visitor is on the way to you'
                                    />
                                </div>
                            </div>                    
                        } 
                        {
                            viewMenuCategory === 'delivery' && 
                            <div className="settings-box-scroll">
                                <div className="settings-row" style={{marginBottom:'29px'}}>
                                    <label className="switch" htmlFor="delivery-toggle">                                
                                        <input type="checkbox" 
                                            name="delivery-toggle" 
                                            id="delivery-toggle"
                                            checked={toggleDelivery}
                                            onChange={e => setToggleDelivery(e.target.checked)}        
                                            ></input>
                                        <span className="slider"></span>                                
                                    </label>
                                    <div>
                                        <div className="header">Delivery</div>
                                        <div className="text" style={{color:'#888888'}}>Deliver a package to a chosen location by placing it on the robot tray. In case of unsuccessful delivery, the office manager will be notified by text message.</div>
                                    </div>
                                </div>
                                <div className="group-input input-texts">
                                    <Input
                                        title={'Main page title'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryPageTitle}
                                        state={deliveryPageTitle}
                                        placeholder='Transferring delivery?'
                                    />
                                    <div className="large-title">General</div>
                                    <Input 
                                    type="tel"
                                    title={'Manager phone'}
                                    isActive={toggleDelivery}
                                    setter={setManagerPhoneText} 
                                    state={managerPhoneText}
                                    maxLength="12"
                                    placeholder="Office manager phone number"
                                    />            
                                    <Input
                                        title={'Move TTS'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryMoveAudioText}
                                        state={deliveryMoveAudioText}
                                        placeholder='Welcome to company name'
                                    />
                                    <Input
                                        title={'Arrived TTS'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryArrivedAudioText}
                                        state={deliveryArrivedAudioText}
                                        placeholder='Welcome to company name'
                                    /> 
                                    <div style={{display:'flex'}}>
                                        <label style={{marginLeft:'64px',width:'190px'}}>Standby timer</label>
                                        <div className="select" style={
                                                {
                                                    margin:'0 40px 30px 40px',
                                                    height: '40px',
                                                    width: '178px',
                                                    border: '1px solid #D0D9ED',
                                                    borderRadius: '5px'
                                                }
                                            }
                                        >       
                                            <select name="standby"
                                                value={standby}
                                                onChange={e => setStandby(e.target.value)}
                                            >
                                                <option value="30">30 Seconds</option>
                                                <option value="60">60 Seconds</option>
                                                <option value="90">90 Seconds</option>
                                                <option value="120">120 Seconds</option>
                                            </select>                                                                       
                                        </div>
                                    </div>
                                    <Input
                                        title={'Opening headline'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryOpeningHeadline}
                                        state={deliveryOpeningHeadline}
                                        placeholder='Welcome to company name'
                                    />
                                    <Input
                                        title={'On the way to title'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryOnTheWay}
                                        state={deliveryOnTheWay}
                                        placeholder='Going to (entity name)'
                                    />
                                    <Input
                                        title={'Arrive title'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryArrivedTitle}
                                        state={deliveryArrivedTitle}
                                        placeholder='Arrived to (entity name)'
                                    />
                                </div>    
                                {/* <div className="indent-row" style={{justifyContent:'flex-start'}}>
                                    <div className="head" style={{marginTop:'35px', width:'125px'}}>Manager phone</div>
                                    <input type="tel" id="isDeliveryManagerPhone" style={{marginTop:'29px', marginLeft:'1px',
                                    height: '34px',
                                    width: '329px',
                                    border: '1px solid #D0D9ED',
                                    color: '#A1A1A1',
                                    borderRadius: '5px'}}
                                    onChange={e => {formatPhone(e.target.value) ; setIsDeliveryManagerPhone(true)}}
                                    maxLength="12"
                                    placeholder={" Office manager phone number"} />
                                </div>                                 */}
                                {/* <div className="upload-row" style={{marginTop:'29px', flexDirection : 'column',  marginLeft:'63px'}}>  
                                    <div className="head" style={{marginBottom:'10px'}}>Standby timer</div>
                                    <div className="select">
                                        <select name="time" value={standby} style={{marginBottom:'10px'}} onChange={e => setStandby(e.target.value)}>
                                        <option value="30">30 Seconds</option>
                                        <option value="60">60 Seconds</option>
                                        <option value="90">90 Seconds</option>
                                        <option value="120">120 Seconds</option>
                                        </select>
                                    </div>
                                </div> */}
                                    <div className="group-input input-texts">
                                    <div className="large-title">Hierarchies</div>
                                    <div style={{display:'flex'}}>
                                        <label style={{marginLeft:'64px',width:'200px'}}>Hierarchies number</label>
                                        <div className="select" style={
                                                {
                                                    margin:'0 34px 10px',
                                                    height: '40px',
                                                    width: '178px',
                                                    border: '1px solid #D0D9ED',
                                                    borderRadius: '5px'
                                                }
                                            }
                                        >       
                                            <select name="deliverySelectedHierarchies"
                                                value={deliverySelectedHierarchies}
                                                onChange={e => setDeliverySelectedHierarchies(e.target.value)}
                                            >
                                                <option value="1">One hierarchy</option>
                                                <option value="2">Two hierarchies</option>    
                                            </select>                                                                       
                                        </div>
                                        <MultiSelect
                                            options={robotGroups.map(group => {
                                                return {
                                                    label:group.entity_name,
                                                    value:group.id,
                                                }
                                            })}
                                            defaultValue={deliverySelectedRobotGroups}
                                            value={deliverySelectedRobotGroups}
                                            onChange={obj => setDeliverySelectedRobotGroups(obj)}
                                        /> 
                                    </div>
                                    <div className="group-input input-texts">
                                        <Input
                                            title={'First Hierarchy title'}
                                            isActive={toggleDelivery}
                                            setter={setDeliveryFirstHierarchyTitle}
                                            state={deliveryFirstHierarchyTitle}
                                            placeholder='Which group or entity do you choose?'
                                        />
                                        <Input
                                            title={'Second Hierarchy title'}
                                            isActive={toggleDelivery && deliverySelectedHierarchies == '2'}
                                            setter={setDeliverySecondHierarchyTitle}
                                            state={deliverySecondHierarchyTitle}
                                            placeholder='Which group or entity do you choose?'
                                        />
                                    </div>
                                </div>
                                <div className="group-input input-texts">
                                    <div className="large-title">Direction</div>
                                    <div className="radio" style={{
                                        display: 'flex',
                                        width: '175px',
                                        height: '34px',
                                        marginLeft: '60px',
                                        border: '1px solid #D0D9ED',
                                        borderRadius: '5px',
                                        position:'relative'
                                    }}>
                                        <div 
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                left:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setDeliveryDirectionMode('reverse')}
                                        >Reverse</div>
                                        <div
                                            style={{
                                                width:'50%',
                                                position:'absolute',
                                                top:0,
                                                right:0,
                                                zIndex:2,
                                                height:'100%',
                                                cursor:'pointer',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}
                                            onClick={() => setDeliveryDirectionMode('straight')}
                                        >Straight</div>
                                        <div                                             
                                        style={{
                                            position:'absolute',
                                            left: deliveryDirectionMode === 'reverse' ? 0 : '50%',
                                            top:0,
                                            width:'50%',
                                            height:'100%',
                                            transition:'left 250ms linear',
                                            willChange:'left',
                                            background:'#D0D9ED',
                                            borderRadius:'5px'                                                                                    
                                        }}></div>
                                    </div>
                                </div>
                                <div className="group-input input-texts">
                                    <div className="large-title">Pop ups</div>
                                    <Input
                                        title={'Headline before'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryPopupHeadline}
                                        state={deliveryPopupHeadline}
                                        placeholder='Deliver to xEntity namex'
                                    />
                                    <Input
                                        title={'Description before'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryPopupDescription}
                                        state={deliveryPopupDescription}
                                        placeholder='Place the delivery on temi tray and click OK'
                                    />
                                    <Input
                                        title={'Headline after'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryPopupHeadlineAfter}
                                        state={deliveryPopupHeadlineAfter}
                                        placeholder='xEntity namex, Your delivery is arrived!'
                                    />
                                    <Input
                                        title={'Description after'}
                                        isActive={toggleDelivery}
                                        setter={setDeliveryPopupDescriptionAfter}
                                        state={deliveryPopupDescriptionAfter}
                                        placeholder='Did you received it?'
                                    />
                                </div>
                                <div className="group-input input-texts">
                                    <div className="large-title">SMS</div>
                                    <Input
                                        title={'On the way'}
                                        isActive={toggleDelivery}
                                        setter={setDeliverySmsOnTheWay}
                                        state={deliverySmsOnTheWay}
                                        placeholder='xEntity namex, your delivery is on the way'
                                    />
                                    <Input
                                        title={'Delivery arrived'}
                                        isActive={toggleDelivery}
                                        setter={setDeliverySmsArrived}
                                        state={deliverySmsArrived}
                                        placeholder='xEntity namex, your delivery is arrived'
                                    />
                                    <Input
                                        title={'Did not succeed'}
                                        isActive={toggleDelivery}
                                        setter={setDeliverySmsNotSucceed}
                                        state={deliverySmsNotSucceed}
                                        placeholder='Your delivery did not succeed, temi went to the entrance'
                                    />
                                       <Input
                                        title={'SMS to manager'}
                                        isActive={toggleDelivery}
                                        setter={setDeliverySmsManager}
                                        state={deliverySmsManager}
                                        placeholder='xEntity namex delivery did not succeed, temi went to the entrance'
                                    />
                                </div>
                                {/* <div className="header">Hierarchies</div>
                                <div className="select" style={
                                        {
                                            marginRight:'10px',
                                            height: '24px',
                                            width: '178px',
                                            border: '1px solid #D0D9ED',
                                            borderRadius: '5px'
                                        }
                                    }
                                >       
                                    <select name="deliverySelectedHierarchies"
                                        value={deliverySelectedHierarchies}
                                        onChange={e => setDeliverySelectedHierarchies(e.target.value)}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>    
                                    </select>                                 
                                    <MultiSelect
                                        options={robotGroups.map(group => {
                                            return {
                                                label:group.entity_name,
                                                value:group.id,
                                            }
                                        })}
                                        defaultValue={deliverySelectedRobotGroups}
                                        value={deliverySelectedRobotGroups}
                                        onChange={obj => setDeliverySelectedRobotGroups(obj)}
                                    />
                                </div> */}
                            </div>
                        }               
                        </div>
                    </div>
                    <div style={{padding:'2px 0 0 2px'}}>
                        <div className="buttons-env">
                            <button className={`submit ${(!toggleDelivery && !toggleMeeting) ? 'disabled' : ''}`} type="submit" style={{marginTop:'30px'}}>Send</button>       
                            {/* || uploadingFiles !== 0           */}
                            <div style={{display:'flex', alignItems:'left', width:'74.4%'}}>

                                {isSaveSettings ? (
                                <div className="text" style={{color:'#000000', marginTop:'30px'}}>{Saved}</div>
                                ) : (
                                ' '
                                )}

                                {(!toggleDelivery && !toggleMeeting) ? (
                                <div className="text" style={{color:'#FF2B2B', marginTop:'30px'}}>You must enable at least one function in the main flow</div>
                                
                                ) : (
                                ' '
                                )}
                                {/* {(!fileType) ? (
                                <div className="text" style={{color:'#FF2B2B', marginTop:'30px'}}>Wrong file type</div>
                                
                                ) : (
                                ' '
                                )} */}
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    )
}
export default Settings;
