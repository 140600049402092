import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { db } from "../../firebase";

const EntitiesToGroup = ({groups, api, groupInView, users}) => {

    const [checklist, setChecklist] = useState([{}, []]);
    const setIsEntitiesToGroup = useStoreActions(actions => actions.setIsEntitiesToGroup);
    const [searchKey, setSearchKey] = useState('');

    useEffect(() =>{        
        let tempChecklist = users;
        if(searchKey !== ''){
            tempChecklist = tempChecklist.filter((user) => {
                if(user.entity_firstname){
                    if(user.entity_firstname.includes(searchKey) || user.entity_lastname.includes(searchKey)){
                      return true;
                    }
                    return false;            
                  }else return true;
            })
        }
        tempChecklist = tempChecklist.map(user => {
            return {
                ...user,
                htmlId : user.id + 'toGroup',
                isChecked: user.entity_group.includes(groupInView.entity_name)
            }
        });
        setChecklist([{allChecked: false}, tempChecklist]);
    }, [searchKey]);

    const updateGroup = () =>{
        const batch = db.batch();
        const meetingHierarchiesRobotsSet = new Set()
        const deliveryHierarchiesRobotsSet = new Set()
        const groupsObj = {}
        groups.forEach(group => {
            groupsObj[group.entity_name] = group
        })
        checklist[1].map(entity => {
            meetingHierarchiesRobotsSet.clear()
            deliveryHierarchiesRobotsSet.clear()   
            // linkedUsers[i].entity_group.forEach(group => {
            // if(group !== groupEdit.group.entity_name){
                // groupsObj[group].robots.forEach(robotId => { ///groups
                //     meetingHierarchiesRobotsSet.add(robotId)
                //     deliveryHierarchiesRobotsSet.add(robotId)
                // })
            // }
            // })
            const meetingHierarchiesRobotsArr = [...meetingHierarchiesRobotsSet]
            const deliveryHierarchiesRobotsArr =[...deliveryHierarchiesRobotsSet]
            let entityGroups = [];
            if(entity.isChecked === true){
                if(entity.entity_group.includes(groupInView.entity_name) === false){
                    entityGroups = [...entity.entity_group, groupInView.entity_name];
                    entityGroups.forEach(groupName => {
                        groupsObj[groupName].robots.forEach(robotId => {
                            meetingHierarchiesRobotsSet.add(robotId)
                            deliveryHierarchiesRobotsSet.add(robotId)
                        })
                    })
                    batch.update(api.doc(entity.id), {
                        entity_group: entityGroups,
                        meeting_hierarchy:meetingHierarchiesRobotsArr,
                        delivery_hierarchy:deliveryHierarchiesRobotsArr
                    });
                }
            }else{
                if(entity.entity_group.includes(groupInView.entity_name)){
                    entityGroups = entity.entity_group.filter(name => {
                        return name !== groupInView.entity_name;
                    });
                    entityGroups.forEach(groupName => {
                        groupsObj[groupName].robots.forEach(robotId => {
                            meetingHierarchiesRobotsSet.add(robotId)
                            deliveryHierarchiesRobotsSet.add(robotId)
                        })
                    })
                    batch.update(api.doc(entity.id), {
                        entity_group: entityGroups,
                        meeting_hierarchy:meetingHierarchiesRobotsArr,
                        delivery_hierarchy:deliveryHierarchiesRobotsArr
                    });
                    batch.update(api.doc(entity.id), {entity_group: entityGroups});
                }
            }            
        });
        batch.commit().then(() => {
            setIsEntitiesToGroup(false);
        }).catch(error => {
            console.log(error);
        })
    }

    const handleChecked = e => {       
        let itemName = e.target.name;
        let checked = e.target.checked;
        setChecklist(prevState => {
            let allChecked = prevState[0];
            let list = prevState[1];
            if (itemName === "checkAll") {
              allChecked = checked;
              for(let i = 0; i < list.length; i++){
                list[i].isChecked = checked;
              }
            }else {
              for(let i = 0; i < list.length; i++){
                if(list[i].htmlId === itemName){
                  list[i].isChecked = checked;
                }
              }                 
            }
            allChecked = list.every(item => item.isChecked);  
            return [ {allChecked: allChecked}, list ];
        });
    }

    const singleUser = user => {
        return(
        <label className="user" key={user.id + 'entitiesToGroup'}>
            <div className="user-checkbox" style={user.isChecked ? {display:'block'}: {}}>
                <input type="checkbox" 
                id={user.htmlId} 
                name={user.htmlId}
                checked={user.isChecked}
                onChange={handleChecked}
                ></input>   
                <span className="checkmark"></span>                   
            </div>
            <div className="user-image" style={user.isChecked ? {display:'none'}: {}}>
            {
              user.entity_pic ? <img src={user.entity_pic} alt="" style={{width:'38px', height:'38px', display:"block", borderRadius:'50%'}}/> : ''
            }</div>             
            <div className="user-name">{user.entity_firstname + ' ' + user.entity_lastname}</div>
        </label>
        )
    }

    const handleAbort = () =>{
        setIsEntitiesToGroup(false);
    }

    return(
        <div className="popup-env">
            <div className="popup" onClick={() => handleAbort()}></div>
            <div className="content-large entities-to-group">
                <div className="users-list">
                    <div className="users-list-top">
                        <div className="header">Add Entity</div>
                        <div>
                            <div className="search-user">
                                <input key="searchUser" 
                                    value={searchKey} 
                                    placeholder={'Search by name'}
                                    onChange={(e) => setSearchKey(e.target.value)}
                                    ></input>
                            </div>
                            <div className="check-user"></div>
                        </div>
                        <div className="select-all"> 
                            <label className="user-checkbox">
                            <input type="checkbox" 
                                name={'checkAll'}
                                checked={checklist[0].allChecked}
                                onChange={(e) => handleChecked(e)}
                                disabled={checklist[1].length < 1}
                            ></input> 
                            <div className={checklist[1].length < 1 ? 'disabled' : ''}>Select all</div>
                            <span className="checkmark"></span>
                            </label>
                        </div>  
                    </div>
                    <div className="users-list-content">
                        {
                            checklist[1].map(user => (
                                user.entity_firstname ? singleUser(user): ''
                            ))
                        }
                    </div>
                </div>
                <div className="button-env">                    
                    <button type="submit" className="submit" onClick={() => updateGroup()}>Save</button> 
                    <button className="back" onClick={() => handleAbort()}>Discard</button> 
                </div>
            </div>
        </div>
    )
}
export default EntitiesToGroup;