//create portal model component
import React from 'react';

const PortalModel = ({children, ...props}) => {
    return (
        <div className="portal-card">
            <div className="portal-mask"></div>
            <div className="portal-text-content">
                <div className="portal-title">Ohh, Somting went wrong🤔 </div>
                <div className="portal-sub-txt">Please click the button below to try again.</div>
            </div>
            <div className="portal-btn-content">
                <div className="portal-btn" onClick={() => window.location.reload()}>
                    Refrash
                </div>
            </div>
        </div>
    );
}

export default PortalModel;