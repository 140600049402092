import React from "react";
import { storage } from "../../firebase";
const DeleteUser = props => {
    const image_id = props.user.entity_pic ? props.user.entity_pic.split('%2F')[1].split('?')[0] : undefined;
    const deleteUser = () => {
        props.api.doc(props.user.id).delete()
        .then(() => {
          if(image_id){
            storage.ref().child(props.company + '/' + image_id).delete().catch(e => console.log(e));
          }
          props.changeIsDeleteUser(props.user , false);
        })
        .catch(error => {
          console.log(error);
        })
    }


    return(
      <div className="group-popup-env">
          <div className="group-popup" onClick={() => props.changeIsDeleteUser(props.user , false)}></div>
          <div className="popup delete">
            <div className="header-large">Delete Entity</div>
            <div className="text">Are you sure you want to delete the entity?</div>
            <div className="buttons-row">
            <button className="submit-new-doc" onClick={() => {deleteUser(props.user)}}>Save</button> 
              <button className="abort" onClick={() => {props.changeIsDeleteUser(props.user , false)}}>Discard</button> 
              
            </div>
          </div>
      </div>
    )
  }
export default DeleteUser;