import React, { useCallback, useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { db } from "../../firebase";
import { AuthContext } from "./Auth.js";
import {useStoreState, useStoreActions} from 'easy-peasy';
import logo from '../../assets/images/ichilovlogo.png'; 



const Ichilov = () => {


  const [error, setError] = useState('');
  const [Robot1Data, setRobot1Data] = useState('')
  const [Robot2Data, setRobot2Data] = useState('')
  const handleDelivery = (field, serial) => {
    const api = db.collection('Companies').doc("Ichilov").collection('Settings').doc(serial);
      console.log(field)
      // if(serial == '00119200013'){
      //   console.log("Robot1")
      //   setEnableRobot1(false)
      // }
      // else {
      //   console.log("Robot2")
      //   setEnableRobot2(false)
      // }
    // if(!bool){
    //   window.close();
    // }
    api.update({[field]:true}).finally(()=> {
        //window.close();
    })
  };
  const handleVolume = (field, serial) => {
    const api = db.collection('Companies').doc("Ichilov").collection('Settings').doc(serial);
    console.log(field)
    api.update({[field]:true}).finally(()=> {
      //window.close();
    })
  }

  // const [alerts, setAlerts] = useState([])

 useEffect(() => {
  const RobotsApi = db.collection('Companies').doc("Ichilov").collection('Settings');
  RobotsApi.onSnapshot(snapshot => {
    const tempsnapstot = snapshot.docs.filter(doc => doc.id === '00120454784' || doc.id === '00120454788')
    console.log(tempsnapstot)
    const tmp1 = tempsnapstot[0].data()
    setRobot1Data(tmp1)
    const tmp2 = tempsnapstot[1].data()
    setRobot2Data(tmp2)
    
  }
  )
     return () => {
      
     }
 }, [])

  return (
    console.log(Robot1Data),
    <div className="ichilov-env">
        <div className="logo-amal">
          <img src={logo} alt="logo"/>
        </div>
        <div className="txt-ichilov">:איכילוב 1</div>
        <div className="buttons-env-top">
          <div className="confirm"
            style={{opacity: !Robot1Data.backHome && !Robot1Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('backHome', '00120454784') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת טעינה</div>
          <div className="cancel"
            style={{opacity: !Robot1Data.backHome && !Robot1Data.goStart ? '1': '0.2'}}
            onClick={() => !Robot1Data.backHome && !Robot1Data.goStart ? handleDelivery('goStart', '00120454784') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת כניסה</div>
          <div className="cancel" onClick={() => handleVolume('setVolume', '00120454784')}>איפוס ווליום</div>
        </div>
        <div className="txt-ichilov">:איכילוב 2</div>
        <div className="buttons-env-top">
          <div className="confirm" 
          style={{opacity: !Robot2Data.backHome && !Robot2Data.goStart ? '1': '0.2'}}
          onClick={() => !Robot2Data.backHome && !Robot2Data.goStart ? handleDelivery('backHome', '00120454788') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת טעינה</div>
          <div className="cancel" 
          style={{opacity: !Robot2Data.backHome && !Robot2Data.goStart ? '1': '0.2'}}
          onClick={() => !Robot2Data.backHome && !Robot2Data.goStart ? handleDelivery('goStart', '00120454788') : alert("אין אפשרות לבצע את הפעולה! הרובוט מבצע פעולה אחרת ברגע זה.")}>עמדת כניסה</div>
          <div className="cancel" onClick={() => handleVolume('setVolume', '00120454788')}>איפוס ווליום</div> 
        </div>
    </div>
  );
};

export default Ichilov