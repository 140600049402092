import React, { useState, useEffect } from "react";
import { storage} from "../../firebase";
import MultiSelect from "react-multi-select-component";
import useValidation from '../hooks/FormValidation';
import upload_img from '../../assets/images/upload.svg';
import default_entity_img from '../../assets/images/default_entity.svg';
import {useStoreState} from 'easy-peasy';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'


const CreateUser = ({changeIsCreateUser, api, robotsApi, originalLocations, groups, addToGroup, mediaApi, company}) => {
    // const [formatedPhone, setFormatedPhone] = useState('');
    const [entity_firstname, setEntityFirstname] = useState('');
    const [entity_lastname, setEntityLastname] = useState('');
    const [entity_phone, setEntityPhone] = useState('');
    const [entity_email, setEntityEmail] = useState('');
    const [entity_location, setEntityLocation] = useState('');
    //
    const [description, setDescription] = useState('')
    //

    const [groupValues] = useState(groups.filter(g => g.entity_name !== 'Entities').map((g) => {return {label: g.entity_name, value: g.entity_name}}));
    const [selectedGroups, setSelectedGroups] = useState(addToGroup !== 'Entities' ? [{label: addToGroup, value: addToGroup}] : []);

    const [isFirstNameValid, setIsFirstNameValid, firstNameError] = useValidation('entity_firstname'); 
    const [isLastNameValid, setIsLastNameValid, lastNameError] = useValidation('entity_lastname'); 
    const [isPhoneValid, setIsPhoneValid, phoneError] = useValidation('entity_phone'); 
    const [isEmailValid, setIsEmailValid, emailError] = useValidation('entity_email');

    const [userDisplayImg, setUserDisplayImg] = useState(default_entity_img);
    const [photoProgress, setPhotoProgress] = useState(0);
    const [doc, setDoc] = useState(api.doc())
    //robots
    const [robots, setRobots] = useState([]);
    const [robot, setRobot] = useState(''); //set to user locations robot
    const [locations, setLocations] = useState([]);

    const phonePrefix = useStoreState(store => store.phonePrefix);

    const changeRobot = robot =>{
        setRobot(robot);
        const tmpArr = [];
        originalLocations.map((location) => {
            location.robots.map((locationRobot) => {
                if(locationRobot === robot){
                    tmpArr.push(location);
                }
            })
        });
        setLocations(tmpArr);
    }


    useEffect(() => {
        doc.set({entity_type:'user'}).catch(e => console.log(e));
        const unsubscrib = robotsApi.onSnapshot(snapshot =>{
            const tempArr = []
            snapshot.docs.map(doc => {
                tempArr.push(doc.id)
            })
            setRobots(tempArr);   
        })
        return () =>{
            unsubscrib();
        }
    }, [])

    const uploadPhoto = (e) =>{
        if(!e.target.files[0]){
            return;
        }
        if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){
            return;
        }
        const photo = e.target.files[0];
        const name = '/user_image' + doc.id
        const fileName = company + name;
        const uploadTask = storage.ref(fileName).put(photo);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(company)
            .child(name) 
            .getDownloadURL().then(link => {
                console.log(link)
                api.doc(doc.id).update({entity_pic: link}).then(() => {
                    setUserDisplayImg(link);
                }).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    const uploadSong = (e) =>{
        if(!e.target.files[0]){
            return;
        }
        if(e.target.files[0].type !== 'audio/mpeg'){
            return;
        }
        const photo = e.target.files[0];
        const name = '/user_image' + doc.id
        const fileName = company + name;
        const uploadTask = storage.ref(fileName).put(photo);
        uploadTask.on("state_changed", snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setPhotoProgress(progress);
        },
        error => {console.log(error);},() => {
        storage
            .ref(company)
            .child(name) 
            .getDownloadURL().then(link => {
                console.log(link)
                api.doc(doc.id).update({entity_pic: link}).then(() => {
                    setUserDisplayImg(link);
                }).catch(x => console.log(x));
            })
            .catch(error => console.log(error))         
        }
        )
    }

    const formatPhone = val => {
        if(val[0] === '0'){
            // console.log(entity_phone.substring(1))
            setEntityPhone(val.substring(1));
        }else{
            setEntityPhone(val);
        }
        setIsPhoneValid(val)
        console.log(entity_phone)
    }

    const submitUserUpdate = (e) =>{
        e.preventDefault();
        if(!isFirstNameValid || !isLastNameValid || !isPhoneValid || !isEmailValid){
            return;
        }
        console.log(doc)
        // photo validation ********

        //////
        let groupNames = selectedGroups.map(g => g.value);
        const fullSelectedGroupsArr = groups.filter(fullGroup => {
            return groupNames.includes(fullGroup.entity_name)
        })
        //meetingHierarchy---------------------------------------------------------
        let meetingHierarchyRobotsSet = new Set()
        for(let i = 0;i < fullSelectedGroupsArr.length; i++){
            fullSelectedGroupsArr[i].robots.forEach(robotId => {
                meetingHierarchyRobotsSet.add(robotId)
            })            
        }
        const finalMeetingHierarchyRobotsArr = [...meetingHierarchyRobotsSet]
        //deliveryHierarchy---------------------------------------------------------
        let deliveryHierarchyRobotsSet = new Set()
        for(let i = 0;i < fullSelectedGroupsArr.length; i++){
            fullSelectedGroupsArr[i].robots.forEach(robotId => {
                deliveryHierarchyRobotsSet.add(robotId)
            })            
        }
        const finalDeliveryHierarchyRobotsArr = [...deliveryHierarchyRobotsSet]
        // ///-------------------------------------------------------------------------
        
        doc.update(
            {
                entity_firstname: entity_firstname,
                entity_lastname: entity_lastname,
                entity_phone: entity_phone.length > 1 ? phonePrefix + entity_phone : entity_phone,
                meeting_hierarchy:finalMeetingHierarchyRobotsArr,
                delivery_hierarchy:finalDeliveryHierarchyRobotsArr,
                entity_email: entity_email,
                entity_location: entity_location,
                entity_group: groupNames,
                entity_description: description              
            }
        ).then(() => {
            changeIsCreateUser(false);
        }).catch(() => {

        }).finally(() => {
            
        })
    }

    const handleAbort = () =>{
        api.doc(doc.id).delete().then(() => {
            changeIsCreateUser(false);
        }).catch((error) => {
            console.log(error);
        })        
    }

    return(
    <div className="popup-env">
        <div className="popup" onClick={() => handleAbort()}></div>
        <div className="content-large">
            <div className="user-form">
                <div className="header-large">New Entity</div>
                <form onSubmit={submitUserUpdate} autoComplete="off">
                    <div className="form-group first-name">
                        <label>First name</label>
                        <input
                        autoComplete="new-password"
                        type="text"
                        maxLength="255"
                        name="entity_firstname"
                        onChange={e => {setEntityFirstname(e.target.value); setIsFirstNameValid(e.target.value)}}
                        value={entity_firstname}
                        />
                        <div className="validation-env">
                            <div className={`invalid-feedback ${isFirstNameValid ? '': 'on'}`}>{firstNameError}</div>
                        </div>
                    </div>
                    <div className="form-group last-name">
                        <label>Last name</label>
                        <input
                        autoComplete="new-password"
                        type="text"
                        maxLength="255"
                        name="entity_lastname"
                        disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                        onChange={e => {setEntityLastname(e.target.value); setIsLastNameValid(e.target.value)}}
                        value={entity_lastname}
                        />
                        <div className="validation-env">                        
                            <div className={`invalid-feedback ${isLastNameValid ? '' : 'on'}`}>{lastNameError}</div>
                        </div>
                    </div>
                    <div className="form-group phone">
                        <label>Phone number</label>
                        <input
                        autoComplete="new-password"
                        type="text"
                        maxLength="12"
                        name="entity_phone"
                        onChange={e => formatPhone(e.target.value)}
                        disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                        value={entity_phone}
                        />
                        <div className="phone-prefix">+{phonePrefix}</div>
                        <div className="validation-env"> 
                            <div className={`invalid-feedback ${isPhoneValid ? '' : 'on'}`}>{phoneError}</div>
                        </div>
                    </div>
                    {/* <div className="form-group phone">
                        <label>Phone</label>
                        <PhoneInput
                            defaultCountry={phonePrefix}
                            value={entity_phone}
                            onChange={setEntityPhone}
                        />
                        <div className="validation-env"> 
                            <div className={`invalid-feedback ${isPhoneValid ? '' : 'on'}`}>{phoneError}</div>
                        </div>
                    </div> */}
                    <div className="form-group email">
                        <label>Email</label>
                        <input
                        autoComplete="new-password"
                        type="text"
                        maxLength="255"
                        name="entity_email"
                        disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" ||
                    selectedGroups[0].value === "Specials" )?true:false}
                        onChange={e => {setEntityEmail(e.target.value); setIsEmailValid(e.target.value)}}
                        value={entity_email}
                        />
                        <div className="validation-env"> 
                            <div className={`invalid-feedback ${isEmailValid ? '' : 'on'}`}>{emailError}</div>
                        </div>
                    </div>
                    <div className="form-group description">
                        <label>Description</label>
                        <textarea
                        autoComplete="new-password"
                        type="text"
                        maxLength="355"
                        rows={3}
                        name="description"
                        disabled={(selectedGroups[0].value === "Songs" ||
                    selectedGroups[0].value === "Tables" )?true:false}
                        onChange={e => {setDescription(e.target.value)}}
                        value={description}
                        />
                    </div>
                    <div className="line"></div>
                    <div className="header">Location</div>
                    <div className="form-group location">  
                        <label>From robot</label>
                        <div className="select">
                            <select name="entity_location" value={robot} onChange={e => changeRobot(e.target.value)}>
                                <option value="" defaultValue disabled hidden></option>
                                {
                                    robots.map(robot => (
                                        <option key={robot + 'l'} value={robot}>{robot}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group location2">  
                        <label>Location</label> 
                        {/* //style={{marginLeft: '10px'}} */}
                        <div className="select">
                            <select name="entity_location" value={entity_location} onChange={e => setEntityLocation(e.target.value)}>
                                <option value="" defaultValue disabled hidden></option>
                                {
                                    locations.map(l => (
                                        <option key={l.id + 'l'} value={l.id}>{l.id}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="header">Group</div>
                    <div className="form-group group"> 
                        <label className="location-label">Assigned Group</label> 
                        <MultiSelect 
                            options={groupValues}
                            value={selectedGroups}
                            onChange={setSelectedGroups}
                            labelledBy={""}
                            disabled={(selectedGroups[0].value === "Songs" ||
                            selectedGroups[0].value === "Tables" ||
                            selectedGroups[0].value === "Specials" )?true:false}
                        />
                    </div>  
                    <div className="line"></div>
                    <div className="header">{(selectedGroups[0].value === "Songs")?"Song":"Picture"}</div>
                    <div className="photo">  
                        <div className="user-image">
                            {photoProgress > 0 ? photoProgress < 100 ? 
                                <div className="progress-env">
                                    <div className="progress-outer">
                                        <div className="progress-inner" style={{width:photoProgress + '%', height:'100%'}}></div>
                                    </div>
                                </div> : <img src={userDisplayImg} alt=""/> : <img src={userDisplayImg} alt=""/>
                            }
                        </div>                                                                   
                        <input
                        type="file"
                        name="photo"
                        id='createUserPhoto'
                        onChange=
                        {(selectedGroups[0].value === "Songs")?e => uploadSong(e):e => uploadPhoto(e)}
                        //{e => uploadPhoto(e)}
                        />
                        <label htmlFor='createUserPhoto'>
                            <img src={upload_img} alt="choose file"/>
                        </label>   
                        {/* <div className={isEmailValid ? "invalid-feedback": "invalid-feedback on"}>{emailError}</div> */}
                    </div>
                    <div className="button-env">                    
                        <button type="submit" className={`submit ${!isFirstNameValid || !isLastNameValid || !isPhoneValid || !isEmailValid ? 'disabled': ''}`}>Save</button> 
                        <button className="back" onClick={() => handleAbort()}>Discard</button> 
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}
export default CreateUser;